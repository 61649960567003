/** useAuth2.js
 * This is a rewrite of the original useAuth
 * provide authentication functions to the client
 * contains functions like login, logout, register
 **/

import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useFirebase } from 'react-redux-firebase';
import { isEmail } from '../utils/validation';
import { firebase } from '../utils';
import { isDevBuild, env } from '../environment';
import { registerError } from '../errors';
import { filterUndefined } from '@common/utils/filterUndefined';

const enableLogs = false;
const debug = isDevBuild() && enableLogs;

const whitelist = ['remotesocial.io'];

// const for how often we check cross-domain status (ms) - default 2 seconds
const LOGIN_CHECK_FREQUENCY = 2000;
const LOGOUT_CHECK_FREQUENCY = LOGIN_CHECK_FREQUENCY; // same as login for now

// these are the provider ids of both social media providers
export const GOOGLE = 'google';
export const FACEBOOK = 'facebook';

const GOOGLE_PROVIDER_DATA_ID = 'google.com';
const FACEBOOK_PROVIDER_DATA_ID = 'facebook.com';

const providerDataToProviderID = {
	[GOOGLE_PROVIDER_DATA_ID]: GOOGLE,
	[FACEBOOK_PROVIDER_DATA_ID]: FACEBOOK,
};

const getProviders = (firebase) => ({
	[GOOGLE_PROVIDER_DATA_ID]: firebase.auth.GoogleAuthProvider,
	[FACEBOOK_PROVIDER_DATA_ID]: firebase.auth.FacebookAuthProvider,
});

const getProviderStatus = (currentUser) => {
	const providerData = currentUser?.providerData || [];
	const providerStatus = {};
	for (const providerDataID of [
		GOOGLE_PROVIDER_DATA_ID,
		FACEBOOK_PROVIDER_DATA_ID,
	]) {
		providerStatus[providerDataToProviderID[providerDataID]] =
			providerData.some((p) => p.providerId === providerDataID);
	}
	return providerStatus;
};

/** Helper functions
 * Common functions reused by the useAuth export
 **/
// fetch a cookie from the browser session
const getCookie = (cookieName) => {
	// debug && console.log('useAuth2: helper: getCookie');
	// debug && console.log('cookieName', cookieName);
	const cookie = document.cookie
		.split(';')
		.map((row) => row.trim())
		.find((row) => row.startsWith(`${cookieName}=`));
	return cookie ? cookie.substring(`${cookieName}=`.length) : '';
};

// Some things we don't want to do at the same time. E.g. checking the csrf cookie
// while we are in the process of logging in or out is likely to give us out of sync info.
const notConcurrently = () => {
	let inProgress = false;
	return (fn) => {
		return async (...args) => {
			if (inProgress) {
				debug && console.log('in progress');
				return 'in progress';
			}
			try {
				inProgress = true;
				await fn(...args);
			} finally {
				inProgress = false;
				// return 'done';
			}
		};
	};
};
const blockLogin = notConcurrently();
const blockLogout = notConcurrently();
const blockSync = notConcurrently();

// sync the auth user (user) with the firestore users profile (profile)
// you need to pass in the firebase instance as it doesn't exist outside
// the useAuth2 exported function
const syncFromAuth = async (firebase, data) => {
	debug && console.log('useAuth2: helper: syncFromAuth');
	debug && console.log('data', data);
	debug && console.log('user', firebase.auth().currentUser);
	return await firebase.functions().httpsCallable('platform-syncFromAuth')(
		data,
	);
};

/**
 * @param data {import('../../../contracts/src/platform/user').UserMarketing}
 * */
const addUserAcquisitionInfo = async (firebase, data) => {
	const payload = filterUndefined(data);

	if (Object.keys(payload).length) {
		return await firebase
			.functions()
			.httpsCallable('platform-addUserAcquisitionData')(payload);
	}
};

// sign a user in cross-domain (create a __session and rsCSRF cookie)
// requires an existing firebase user to be present (authenticated)
const signUserInCrossDomain = async (user) => {
	console.log('signUserInCrossDomain');
	debug && console.log('user', user);
	try {
		// get the user's firebase ID Token
		const idToken = await user.getIdToken();
		const url = env().dev_authFunctionsBaseUrl;
		const loginBaseUrl = url ? `${url}/platform-auth` : '';
		return await fetch(`${loginBaseUrl}/auth/login`, {
			method: 'POST',
			credentials: 'include',
			headers: {
				Authorization: `Bearer ${idToken}`,
			},
		});
	} catch (error) {
		registerError(error);
	}
};

// sign a user OUT cross-domain (delete any __session and rsCSRF cookies)
const signUserOutCrossDomain = async () => {
	debug && console.log('signUserOutCrossDomain');
	try {
		const url = env().dev_authFunctionsBaseUrl;
		const logoutBaseUrl = url ? `${url}/platform-auth` : '';
		return await fetch(`${logoutBaseUrl}/auth/logout`, {
			method: 'POST',
			credentials: 'include',
		});
	} catch (error) {
		registerError(error);
	}
};

// using an rsCSRF cookie, check for a crossDomain sync
const checkStatusCrossDomain = async (rsCsrfCookie, user) => {
	debug && console.log('checkStatusCrossDomain');
	try {
		const url = env().dev_authFunctionsBaseUrl;
		const statusBaseUrl = url ? `${url}/platform-auth` : '';
		const uid = user && user.uid;
		const response = await fetch(`${statusBaseUrl}/auth/status`, {
			method: 'POST',
			credentials: 'include',
			headers: {
				Authorization: `Bearer ${rsCsrfCookie}`,
				...(uid && {
					'x-user-uid': uid,
				}),
			},
		});
		const text = await response.text();
		if (text === 'not required') {
			return 'not required';
		}

		const customToken = response.ok && text;
		debug && console.log('customToken', customToken);
		if (customToken && customToken !== 'OK') {
			await firebase.auth().signInWithCustomToken(customToken);
		}
		return true;
	} catch (error) {
		registerError(error);
	}
};

const tryReauthentication = async (firebase, currentUser) => {
	debug && console.log('useAuth tryReauthentication');
	const providers = getProviders(firebase);
	const Provider = currentUser.providerData
		.map((data) => providers[data.providerId])
		.find(Boolean);
	debug && console.log(Provider, currentUser.providerData);
	if (Provider) {
		// do it automatically if we can
		try {
			await currentUser.reauthenticateWithPopup(new Provider());
			return true;
		} catch (e) {
			registerError(e);
		}
	}
	return false;
};

const urlFromContinueParams = ({ continueUrl, continuePath }) => {
	if (continueUrl) {
		return {
			url: new URL(continueUrl).toString(),
		};
	} else if (continuePath) {
		const url = new URL(
			[window.location.origin, continuePath]
				.filter(Boolean)
				.join('/')
				.replace(/\/\//g, '/'),
		).toString();

		return {
			url,
		};
	} else {
		return {
			url: new URL(window.location.origin).toString(),
		};
	}
};

const sendEmailVerification = async (
	firebase,
	{ continueUrl, continuePath },
) => {
	await firebase
		.auth()
		.currentUser.sendEmailVerification(
			urlFromContinueParams({ continueUrl, continuePath }),
		);
};

const handleInvitedPeople = async (firebase, { inviteId }) => {
	return await firebase
		.functions()
		.httpsCallable('platform-handleInvitedPeople')({
			inviteId,
		})
		.then(({ data }) => data);
};

// This is the main function exported for useAuth2
export default function useAuth() {
	// privde a connection to firebase
	const firebase = useFirebase();

	// return an object of functions client can call
	return useMemo(
		() => ({
			// return the current user from firebase.auth()
			get currentUser() {
				debug && console.log('currentUser');
				return firebase.auth().currentUser;
			},

			// login requires either email/password or a social media credential
			login: blockLogin(async (credentials) => {
				debug && console.log('login:', credentials);

				try {
					// check if credentials are email/password
					if (credentials.email && credentials.password) {
						// check on client if email is valid otherwise don't make the API call
						if (!isEmail(credentials.email)) {
							throw new Error('Email is not valid');
						}

						// do login
						await firebase
							.auth()
							.signInWithEmailAndPassword(
								credentials.email,
								credentials.password,
							);
					}
					// otherwise, we're signing in with a credential (social, custom)
					else {
						await firebase.auth().signInWithCredential(credentials);
					}

					// get the user details from firebase auth
					const user = firebase.auth().currentUser;
					debug && console.log('user', user);

					// Cross-domain login - create __session and reCSRF cookies
					await signUserInCrossDomain(user);
				} catch (error) {
					console.error('error login:', error.message);
					throw error;
				}
			}),

			// logout the current signed in user
			logout: blockLogout(async () => {
				debug && console.log('logout');
				try {
					// cross-domain logout - kill __session and rsCSRF cookies
					await signUserOutCrossDomain();

					// kill the firebase session
					await firebase.logout();
				} catch (error) {
					console.error('error logout:', error.message);
					throw error;
				}
			}),

			// create a new email/password user and return nextUrl
			createUserWithEmailAndPassword: blockLogin(
				/**
				 * @param {object} obj - function param object
				 * @param {import('../../../contracts/src/platform/user').UserMarketing} obj.userAcquisitionData
				 * */
				async ({
					email,
					password,
					continuePath,
					userAcquisitionData = {},
				}) => {
					debug &&
						console.log(
							'createUserWithEmailAndPassword',
							email,
							password,
							continuePath,
						);
					try {
						// if email is not valid, don't proceed
						if (!isEmail(email)) {
							throw new Error('Email is not valid');
						}

						// register the user via firebase.auth
						if (env().name === 'staging') {
							const [userEmail, domain] = email.split('@');
							console.log('userEmail', userEmail);
							console.log('domain', domain);
							if (
								!whitelist.includes(domain) &&
								!whitelist.includes(userEmail)
							) {
								throw new Error(
									'User not in allowed whitelist',
								);
							}
						}

						await firebase
							.auth()
							.createUserWithEmailAndPassword(email, password);

						// get the user details from firebase auth
						const user = firebase.auth().currentUser;
						debug && console.log('user:', user);

						await sendEmailVerification(firebase, { continuePath });

						// Cross-domain login - create __session and reCSRF cookies
						await signUserInCrossDomain(user);
						debug &&
							console.log('signUserInCrossDomain - complete');

						// create a firestore profile for the user
						await syncFromAuth(firebase);
						debug && console.log('syncFromAuth - complete!');

						await addUserAcquisitionInfo(
							firebase,
							userAcquisitionData,
						);
					} catch (error) {
						console.error(
							'error createUserWithEmailAndPassword:',
							error.message,
						);
						throw error;
					}
				},
			),

			loginOrCreateUserWithPopup: blockLogin(
				/**
				 * social media can both login and register a user
				 * @param {object} obj - function param object
				 * @param {import('../../../contracts/src/platform/user').UserMarketing} obj.userAcquisitionData
				 * */
				async ({ providerKey, nextUrl, userAcquisitionData = {} }) => {
					debug &&
						console.log(
							'loginOrCreateUserWithPopup',
							providerKey,
							nextUrl,
						);
					try {
						// trigger the popup login for the provided social login
						// and return the auth data from the social provider and firebase
						await firebase.login({
							type: 'popup',
							provider: providerKey,
						});

						// get the user details from firebase auth
						const user = firebase.auth().currentUser;

						if (env().name === 'staging') {
							const [userEmail, domain] = user.email.split('@');
							if (
								!whitelist.includes(domain) &&
								!whitelist.includes(userEmail)
							) {
								await user.delete();
								throw new Error(
									'User not in allowed whitelist',
								);
							}
						}

						debug && console.log('user', user);

						// when a user logs in with social provider, their Auth profile
						// is updated (merged?) with the most recent data from the auth provider
						// so email address, email verification, displayName, etc will all
						// be updated to the values from the last logged in social provider
						// we will need to trigger a sync between Auth and Users to ensure
						// the profile information is up-to-date.

						// Cross-domain login - create __session and reCSRF cookies
						await signUserInCrossDomain(user);
						debug &&
							console.log('signUserInCrossDomain - complete');

						// Trigger an update of the firestore users record
						// as the social details of the user may have changed
						await syncFromAuth(firebase);
						debug && console.log('syncFromAuth - complete!');

						await addUserAcquisitionInfo(
							firebase,
							userAcquisitionData,
						);
						// Now that the profile is sync'd with the user, setup the
						// crossDomain cookies that will allow other sessions on other
						// sub domains to login as this user as well
					} catch (error) {
						console.error(
							'error loginOrCreateUserWithPopup:',
							error.message,
						);
						throw error;
					}
				},
			),

			reauthenticate: async (email, password) => {
				// if email is not valid, don't proceed
				if (!isEmail(email)) {
					throw new Error('Email is not valid');
				}

				debug && console.log('reauthenticate');
				return firebase
					.auth()
					.currentUser.reauthenticateWithCredential(
						firebase.auth.EmailAuthProvider.credential(
							email,
							password,
						),
					);
			},

			// profile update methods
			updateDisplayName: async (newName) => {
				debug && console.log('updateDisplayName');
				await firebase
					.auth()
					.currentUser.updateProfile({ displayName: newName });
				await syncFromAuth(firebase);
			},

			updateEmail: async (newEmail, nextURL) => {
				debug && console.log('useAuth: updateEmail');

				// if new email is not valid, don't proceed
				if (!isEmail(newEmail)) {
					throw new Error('Email is not valid');
				}

				const currentUser = firebase.auth().currentUser;

				try {
					await currentUser.verifyBeforeUpdateEmail(
						newEmail,
						nextURL && { url: nextURL },
					);
				} catch (e) {
					if (e.code === 'auth/requires-recent-login') {
						if (await tryReauthentication(firebase, currentUser)) {
							console.log('reauthed');
							await firebase
								.auth()
								.currentUser.verifyBeforeUpdateEmail(
									newEmail,
									nextURL && { url: nextURL },
								);
						}
					}
					throw e;
				}
				await syncFromAuth(firebase);
			},

			updatePassword: async (newPassword) => {
				debug && console.log('useAuth: updatePassword');
				const currentUser = firebase.auth().currentUser;
				if (!newPassword || !newPassword.length >= 6) {
					throw new Error(
						'Your password must be at least 6 characters long',
					);
				}
				try {
					await currentUser.updatePassword(newPassword);
				} catch (e) {
					if (e.code === 'auth/requires-recent-login') {
						if (await tryReauthentication(firebase, currentUser)) {
							await firebase
								.auth()
								.currentUser.updatePassword(newPassword);
						}
					}
					throw e;
				}
			},

			getSocialLoginStatuses: () => {
				debug && console.log('getSocialLoginStatuses');
				return getProviderStatus(firebase.auth().currentUser);
			},

			unlink: async (providerID) => {
				debug && console.log('unlink');
				const providerDataID = Object.entries(
					providerDataToProviderID,
				).find(([_, v]) => providerID === v)[0];
				await firebase.auth().currentUser.unlink(providerDataID);
			},

			linkUserWithEmailAndPassword: async (email, password, nextURL) => {
				debug && console.log('linkUserWithEmailAndPassword');

				// if email is not valid, don't proceed
				if (!isEmail(email)) {
					throw new Error('Email is not valid');
				}

				await firebase
					.auth()
					.currentUser.linkWithCredential(
						firebase.auth.EmailAuthProvider.credential(
							email,
							password,
						),
					);
				const user = firebase.auth().currentUser;
				await signUserInCrossDomain(user);
				await syncFromAuth(firebase);
			},

			linkWithPopup: async (providerKey, nextURL) => {
				debug && console.log('linkWithPopup');
				await firebase
					.auth()
					.currentUser.linkWithPopup(
						providerKey === GOOGLE
							? new firebase.auth.GoogleAuthProvider()
							: new firebase.auth.FacebookAuthProvider(),
					);
				const user = firebase.auth().currentUser;
				await syncFromAuth(firebase);
				await signUserInCrossDomain(user);
			},

			/**
			 * Send email verification which will lead back to a page specified
			 * in `continueUrl` or `continuePath`
			 *
			 * - continueUrl - full url to redirect to, e.g. https://app.remotesocial.io/create-schedule/xxx
			 * - continuePath - path to redirect to in current app - will translate to ${window.location.origin}/${continuePath}
			 *
			 * @param {{ continueUrl?: string; continuePath?: string }}
			 */
			sendEmailVerification: async ({ continueUrl, continuePath }) => {
				return await sendEmailVerification(firebase, {
					continueUrl,
					continuePath,
				});
			},

			/**
			 *
			 * @param {{ inviteId: string }} param0
			 * @returns {Promise<{
			 *   nextAction: 'login' | 'join-account' | 'switch-user',
			 *   inviteEmail: string,
			 *   accountName: string,
			 *   accountId: string,
			 *   senderName: string,
			 *   accountAvatar: string,
			 * }>}
			 */
			handleInvitedPeople: async ({ inviteId }) => {
				return await handleInvitedPeople(firebase, {
					inviteId,
				});
			},

			sendPasswordResetEmail: async (email, continueUrl) => {
				// if email is not valid, don't proceed
				if (!isEmail(email)) {
					throw new Error('Email is not valid');
				}

				return await firebase
					.functions()
					.httpsCallable('platform-sendPasswordReset')({
					email,
					continueUrl: window.location.origin + continueUrl,
				});
			},

			syncProfileFromAuth: blockSync(() => syncFromAuth(firebase)),
		}),
		[firebase],
	);
}

// sync CrossDomain status by checking the presence
// (or absence) of the rsCSRF cookie. If it's present, and we
// aren't already logged in, then trigger a log in. If it's missing,
// and we are logged in and have been for a while, then trigger a
// log out as they would have been logged out on another subdomain.
export const useCrossDomainSync = () => {
	// privde a connection to firebase
	const firebase = useFirebase();
	// debug && console.log('useAuth2: crossDomainSync');
	// create a state for whether a first-run sync has been completed
	const [syncState, setSyncState] = useState(null);
	const [mounted, setMounted] = useState(false);
	let timerHandle = useRef(null); // container for setTimeout calls

	// this loop function will check repeatedly on a schedle
	// for a login status via the rsCSRF cookie appearance
	const loginCheck = blockLogout(async () => {
		debug && console.log('loginCheck');
		if (!mounted) {
			debug && console.log('not mounted');
			return;
		}
		try {
			// debug && console.log('mounted');
			clearTimeout(timerHandle.current); // clear any previously scheduled runs
			setSyncState('login');
			const rsCsrfCookie = getCookie('rsCSRF'); // get the cookie value
			const user = firebase.auth().currentUser; // get the current user
			// if the rsCSRF cookie DOES exist
			if (rsCsrfCookie) {
				debug && console.log('we are supposed to log the user IN here');
				const result = await checkStatusCrossDomain(rsCsrfCookie, user);
				if (result === 'not required') {
					timerHandle.current = setTimeout(
						logoutCheck,
						LOGIN_CHECK_FREQUENCY,
					);
				}
				debug && console.log('result', result);
				return result;
			}
			// schedule another call to this function in a continuous loop
			timerHandle.current = setTimeout(loginCheck, LOGIN_CHECK_FREQUENCY);
		} catch (error) {
			registerError(error);
		}
	});

	// this loop function will check repeatedly on a schedle
	// for a logout status via the rsCSRF cookie appearance
	const logoutCheck = blockLogin(async () => {
		debug && console.log('logoutCheck');
		if (!mounted) {
			debug && console.log('not mounted');
			return;
		}
		try {
			// debug && console.log('mounted');
			clearTimeout(timerHandle.current); // clear any previously scheduled runs
			// set the sync state to 'logout' until further notice
			setSyncState('logout');
			const rsCsrfCookie = getCookie('rsCSRF'); // get the cookie value
			// if the rsCSRF cookie DOES NOT exist
			if (!rsCsrfCookie) {
				debug && console.log('log the user OUT');
				await firebase.logout();
			}
			// schedule another call to this function in a continuous loop
			timerHandle.current = setTimeout(
				logoutCheck,
				LOGOUT_CHECK_FREQUENCY,
			);
		} catch (error) {
			registerError(error);
		}
	});

	// on mounting of the component set mounted status
	// and on unmounting remove any timers that may be scheduled;
	useEffect(() => {
		setMounted(true); // mounted status of hook
		// debug && console.log('useAuth2 useCrossDomainSync useEffect - mounted');
		// callback when the component is unmounted (cleanup)
		return () => {
			// debug && console.log(
			// 'useAuth2 useCrossDomainSync useEffect  - un-mounting'
			// );
			setMounted(false); // reset mounted status
			setSyncState(null); // clear syncState
			clearTimeout(timerHandle.current); // clear any future timeouts
		};
	}, []); // run this effect exactly once when mounted.

	return {
		get csrf() {
			return getCookie('rsCSRF');
		},
		// return whether an initial sync has been performed
		syncState: syncState,
		// start polling to catch a login event from another subdomain
		// if found, run a login action using custom tokens from the
		// newly available cookies on the root domain
		triggerLoginSync: async () => {
			debug && console.log('triggerLoginCheck');
			await loginCheck();
			return 'done';
		},
		// start polling to catch a logout event from another subdomain
		// if found, run a logout action on the current firebase auth session
		triggerLogoutSync: () => {
			debug && console.log('triggerLogoutCheck');
			// call logoutCheck, but if it's blocked from running
			if (logoutCheck() === 'in progress') {
				setTimeout(logoutCheck, LOGOUT_CHECK_FREQUENCY);
			}
		},
	};
};

export const useSynchronizeUserProfile = ({ isAuthenticated, userDoc }) => {
	const { syncProfileFromAuth, currentUser: firebaseUser } = useAuth();

	const MAX_SYNCS_PER_PAGE_LOAD = 2;
	const numberOfSyncs = useRef(0);

	const shouldSkipSync = useCallback(() => {
		if (!userDoc || !firebaseUser) {
			return false;
		}

		const conditions = {
			displayNameEqual: userDoc.displayName === firebaseUser.displayName,
			emailVerifiedEqual:
				userDoc.emailVerified === firebaseUser.emailVerified,
		};

		// if firebase user has no photoURL we generate our own
		// so they are going to be different - don't sync in this case
		if (firebaseUser.photoURL !== null) {
			conditions.photoURLEqual =
				userDoc.photoURL === firebaseUser.photoURL;
		}

		return [...Object.values(conditions)].every((isMet) => isMet);
	}, [userDoc, firebaseUser]);

	useEffect(() => {
		if (!isAuthenticated) {
			return;
		}

		if (numberOfSyncs.current >= MAX_SYNCS_PER_PAGE_LOAD) {
			// if the sync function is not doing it's job due to a bug
			// it will be called infinite number of times, prevent that
			return;
		}

		if (shouldSkipSync()) {
			return;
		}

		// delay synchronization by 5 seconds in cases
		// when it was already triggered and we just
		// waiting for changes to be pushed from server
		const timer = setTimeout(() => {
			if (shouldSkipSync()) {
				return;
			}

			syncProfileFromAuth()
				.catch((err) => {
					registerError(err);
				})
				.finally(() => {
					numberOfSyncs.current += 1;
				});
		}, 5000);

		return () => {
			clearTimeout(timer);
		};
	}, [isAuthenticated, shouldSkipSync, syncProfileFromAuth]);
};
