import React, { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Grid, Typography, Hidden } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { useCurrentUser, useAuth } from '../../hooks';
import { Button, DocumentHead, ErrorMessage } from '../../components';
import { contentAnimation, gifAnimation } from './animation';
import { useStyles } from './OnboardingStyle';
import { registerError } from '../../errors';

export default function VerifyEmail() {
	const currentUser = useCurrentUser();
	const classes = useStyles();
	const location = useLocation();
	const from = location.state?.from || '/';
	const contentSpring = useSpring(contentAnimation);
	const gifSpring = useSpring(gifAnimation);
	const { sendEmailVerification } = useAuth();
	// blocks the user from trying again
	const [verificationSent, setVerificationSent] = useState(false);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const handleResendVerification = async () => {
		setLoading(true);
		try {
			await sendEmailVerification({ continuePath: from });
			setVerificationSent(true);
		} catch (error) {
			registerError(error);
			if (error.code === 'resource-exhausted') {
				setVerificationSent(true);
			} else {
				setError(error);
			}
		} finally {
			setLoading(false);
		}
	};

	if (currentUser.emailVerified) {
		return <Redirect to={from} />;
	}

	return (
		<Grid container spacing={2}>
			<DocumentHead title="Verify your email" />
			<Hidden smDown>
				<Grid
					className={classes.videoContainer}
					item
					md={5}
					container
					spacing={0}
					direction="column"
					alignItems="center"
					justifyContent="center"
				>
					<animated.div style={gifSpring}>
						<img
							className={classes.video}
							alt="Hello"
							src="https://storage.googleapis.com/remotesocial-io-asset-storage/hub/img/email.gif"
						/>
					</animated.div>
				</Grid>
			</Hidden>
			<Grid item xs={12} md={1}></Grid>
			<Grid item xs={12} md={5}>
				<animated.div style={contentSpring}>
					<Grid direction="column" container spacing={2}>
						<Typography
							className={classes.verifyTitle}
							component="h4"
							variant="h4"
							align="center"
							style={{ textAlign: 'center' }}
						>
							We need to verify your email to continue
						</Typography>
						<Typography
							className={classes.verifySubtitle}
							component="h5"
							variant="h5"
							align="center"
							style={{ textAlign: 'center' }}
						>
							We sent a verification link to {currentUser.email}
						</Typography>
						{error && (
							<>
								{error.code === 'already-exists' ? (
									<ErrorMessage>
										Email already verified
									</ErrorMessage>
								) : (
									<ErrorMessage error={error} />
								)}
							</>
						)}
						{verificationSent ? (
							<Typography
								className={classes.verifyTitle}
								component="h4"
								variant="h4"
								align="center"
							>
								Please check your inbox for a verification
								email.
							</Typography>
						) : (
							<Button
								className={classes.registerBtn}
								align="center"
								loading={loading}
								disabled={loading}
								onClick={handleResendVerification}
							>
								{loading
									? 'Sending...'
									: 'Resend verification code'}
							</Button>
						)}
					</Grid>
				</animated.div>
			</Grid>
		</Grid>
	);
}
