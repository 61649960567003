import { mapOf, ValueTypesOf } from '../../shared';

const gameTypes = ['physical', 'bingo', 'trivia'] as const;

export type GameType = ValueTypesOf<typeof gameTypes>;

// TODO: Remove when all is TypeScript
// NOTE: do not copy-paste this constant, we don't really need it
// for string literal types, we could just use strings as is
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GameType = mapOf(gameTypes);
