import React, { useCallback, useState } from 'react';
import { Box, Typography, Slider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Cropper from 'react-easy-crop';
import Button from '../Button';
import getCroppedImg from './cropImage';
import ImageCompressor from '../../utils/image-compression';
import { registerError } from '../../errors';

const useStyles = makeStyles(() => ({
	promptText: {
		fontSize: '.9rem',
		margin: '1rem auto 0.7rem',
		maxWidth: 300,
	},
	centerText: {
		textAlign: 'center',
	},
	editor: {
		backgroundColor: '#20242a',
	},
	cropContainer: {
		position: 'relative',
		width: '100%',
		height: '40vh',
	},
	submitBtn: {
		color: 'white',
	},
	controlLabel: {
		fontSize: '0.8rem',
		color: 'white',
		textAlign: 'center',
	},
}));

const ImageCrop = ({
	image,
	cropShape = 'rect',
	showGrid = true,
	aspect = 1 / 1,
	onImageCropped,
	onCropCancelled,
}) => {
	const classes = useStyles();

	const ZOOM_SCALING_FACTOR = 100;
	const [shape] = useState(cropShape);
	const [gridActive] = useState(showGrid);
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
	const [, setCroppedImage] = useState(null);

	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
	}, []);

	const showCropResult = useCallback(async () => {
		try {
			let resultImage = await getCroppedImg(image, croppedAreaPixels);

			// compress the image now
			resultImage = await ImageCompressor(resultImage);

			const date = new Date();

			const theFile = new File([resultImage], `${date.getTime()}.jpeg`, {
				type: 'image/jpeg',
			});

			onImageCropped && onImageCropped(theFile);

			setCroppedImage(resultImage);
		} catch (error) {
			registerError(error);
		}
	}, [croppedAreaPixels]);

	const defaultAspectRatio = 1 / 1;
	const aspectRatio =
		shape === 'round' ? defaultAspectRatio : aspect || defaultAspectRatio;

	return (
		<Box>
			<Box p={3} className={classes.centerText}>
				<Typography variant="h5" component="h2">
					Edit photo
				</Typography>
				<Typography className={classes.promptText}>
					Upload a photo that’s a minimum resolution 320px by 400px
					.JPEG, .JPG or .PNG
				</Typography>
			</Box>

			<Box className={classes.editor}>
				<Box className={classes.cropContainer}>
					<Cropper
						style={{
							containerStyle: {
								background: '#09264A',
							},
						}}
						cropShape={shape}
						showGrid={gridActive}
						image={image}
						crop={crop}
						zoom={zoom}
						zoomWithScroll={false}
						aspect={aspectRatio}
						onCropChange={setCrop}
						onCropComplete={onCropComplete}
						onZoomChange={setZoom}
					/>
				</Box>
				<Box py={1.5}>
					<Typography className={classes.controlLabel}>
						Drag to reposition photo
					</Typography>
					<Box width={200} mx={'auto'}>
						<Slider
							step={5}
							min={ZOOM_SCALING_FACTOR}
							max={3 * ZOOM_SCALING_FACTOR}
							value={Math.floor(zoom * ZOOM_SCALING_FACTOR)}
							onChange={(event, newValue) => {
								setZoom(newValue / ZOOM_SCALING_FACTOR);
							}}
						/>
					</Box>
				</Box>
			</Box>
			<Box display={'flex'} justifyContent={'space-between'} px={2}>
				<Button
					variant="text"
					fullWidth={false}
					onClick={onCropCancelled}
				>
					Cancel
				</Button>
				<Button
					color="primary"
					fullWidth={false}
					className={classes.submitBtn}
					onClick={showCropResult}
				>
					Save
				</Button>
			</Box>
		</Box>
	);
};

export default ImageCrop;
