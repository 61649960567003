import React, { useState, useEffect } from 'react';
import { useFirestore } from 'react-redux-firebase';
import useMountedRef from '../hooks/useMountedRef.js';

// material-ui
import {
	Button as UIButton,
	CircularProgress,
	Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { registerError } from '../errors';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		textAlign: 'center',
	},
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: '-12px',
		marginLeft: '-12px',
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		textTransform: 'inherit',
	},
	error: {
		border: '1px solid red',
	},
}));

const useLoadable = (fn, prop = 'data', deps = []) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [data, setData] = useState(null);
	const mounted = useMountedRef();
	const load = async () => {
		setLoading(true);
		try {
			setData(await fn());
		} catch (e) {
			registerError(e);
			mounted.current && setError(e);
		} finally {
			mounted.current && setLoading(false);
		}
	};
	useEffect(() => {
		load();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);

	return { loading, error, [prop]: data };
};

const useIntegration = (id) => {
	const firestore = useFirestore();
	return useLoadable(async () => {
		const querySnap = await firestore
			.collection('integrations')
			.doc(id)
			.get();

		return querySnap.data();
	}, 'integration');
};

export default function IntegrationButton({ fullWidth = true, ...props }) {
	const classes = useStyles();

	const {
		loading: integrationsLoading,
		integration,
		error: integrationsError,
	} = useIntegration('slack');

	console.log(integration);

	return (
		<>
			{integration ? (
				<div
					className={clsx(
						classes.wrapper,
						props.error && classes.error,
					)}
				>
					{props.error && (
						<Typography
							component="p"
							variant="subtitle1"
							color="error"
						>
							{props.error.message || props.error}
						</Typography>
					)}
					<UIButton
						type="submit"
						fullWidth={props.fullWidth}
						variant="contained"
						size="large"
						disableElevation
						className={clsx(classes.submit, props.className)}
						onClick={props.click || props.onClick}
						disabled={props.loading || props.disabled}
					>
						{props.text}
						{props.children}
						{props.loading && (
							<CircularProgress
								size={24}
								className={classes.buttonProgress}
							/>
						)}
					</UIButton>
				</div>
			) : (
				<div></div>
			)}
		</>
	);
}
