import React, { useState } from 'react';
import SocialLogin from './SocialLogin';

import { Link, Button, DocumentHead, RemoteSocialLogo } from '../../components';
import { useAuth } from '../../hooks';
import { useSpring, animated } from 'react-spring';
import { contentAnimation, gifAnimation } from './animation';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { Grid, Hidden, Typography, TextField } from '@material-ui/core';
import { useStyles } from './OnboardingStyle';

import { ErrorMessage } from '../../components/errorMessage';
import { isEmail } from '../../utils/validation';
import { registerError } from '../../errors';

export default function Login() {
	const auth = useAuth();
	const classes = useStyles();
	const contentSpring = useSpring(contentAnimation);
	const gifSpring = useSpring(gifAnimation);
	const history = useHistory();
	const location = useLocation();
	const nextUrl = (location.state && location.state.from) || '/';
	const [Credentials, setCredentials] = useState({
		email: '',
		password: '',
	});
	const [Loading, setLoading] = useState(false);
	const [authError, setAuthError] = useState();
	const [buttonText, setButtonText] = useState('Log in');

	const handleChange = (event) => {
		setCredentials({
			...Credentials,
			[event.target.id]: event.target.value,
		});
	};

	const handleSubmit = async (event) => {
		event.preventDefault && event.preventDefault();
		if (!isEmail(Credentials.email)) {
			setAuthError('Email is not valid');
			return;
		} else {
			setAuthError(null);
		}

		try {
			setLoading(true);
			setButtonText('Logging in...');
			setAuthError(null);
			await auth.login(Credentials);
			nextUrl && history.push(nextUrl);
		} catch (error) {
			registerError(error);
			setLoading(false);
			setButtonText('Log in');
			setAuthError(error);
			console.error(error);
		}
	};

	return (
		<Grid container spacing={2}>
			<DocumentHead title="Login" />
			<Hidden smDown>
				<Grid
					className={classes.videoContainer}
					item
					md={5}
					container
					spacing={0}
					direction="column"
					alignItems="center"
					justifyContent="center"
				>
					<animated.div style={gifSpring}>
						<img
							className={classes.video}
							alt="Hello"
							src="https://storage.googleapis.com/remotesocial-io-asset-storage/hub/img/Hello.gif"
						/>
						<img
							className={classes.loginVector}
							alt=""
							src="https://storage.googleapis.com/remotesocial-io-asset-storage/hub/img/loginVector.png"
						/>
					</animated.div>
				</Grid>
			</Hidden>
			<Grid item xs={12} md={1}></Grid>
			<Grid item xs={12} md={5}>
				<animated.div style={contentSpring}>
					<Grid direction="column" container spacing={2}>
						<RemoteSocialLogo className={classes.brand} />
						<Grid item xs={12} align="center">
							<Typography
								component="h3"
								variant="h4"
								align="center"
								className={classes.loginTitle}
							>
								Login with
							</Typography>
						</Grid>
						<SocialLogin nextUrl={nextUrl} />
						<form
							className={classes.form}
							noValidate
							onSubmit={handleSubmit}
						>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									type="email"
									id="email"
									label="Email address"
									name="email"
									autoFocus
									onChange={handleChange}
								/>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									type="password"
									id="password"
									label="Password"
									name="password"
									onChange={handleChange}
								/>
								<ErrorMessage error={authError} />
							</Grid>
							<Grid item xs={12}>
								<Button
									click={handleSubmit}
									disabled={Loading}
									text={buttonText}
									loading={Loading}
									className={classes.registerBtn}
									align="center"
								/>
							</Grid>
						</form>
						<Grid item container direction="row">
							<Grid
								item
								align="center"
								xs={12}
								className={classes.forgotPassword}
							>
								<Link to="/reset-password">
									Forgot your password?
								</Link>
							</Grid>
						</Grid>
						<Grid
							className={classes.signUpHere}
							item
							xs={12}
							alignItems="center"
							container
							direction="column"
							justifyContent="center"
							spacing={0}
						>
							<Grid>
								Don’t have an account?{' '}
								<Link
									to={{
										pathname: '/register',
										state: { from: nextUrl },
									}}
								>
									Sign up here
								</Link>
								.
							</Grid>
						</Grid>
					</Grid>
				</animated.div>
			</Grid>
		</Grid>
	);
}
