import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { env, isDevBuild } from '../environment';
import { appName } from '../environment/appName';

export function initSentry() {
	const enabled = !isDevBuild() && env().name !== 'local';

	const devConfig: Sentry.BrowserOptions = {
		// enable only temporarily, for testing only
		tracesSampleRate: 1.0,
		enabled: false,
	};

	const originsByEnv = {
		local: [/localhost:\d+/, /.*-remotesocial-dev.cloudfunctions.net/],
		staging: [
			/.*-remotesocial-dev.cloudfunctions.net/,
			/backend.remotesocial.app/,
		],
		prod: [
			/.*-remotesocial-io.cloudfunctions.net/,
			/backend.remotesocial.io/,
		],
	};

	const traceSamplingByEnv = {
		local: 0.0,
		staging: 1,
		prod: 1,
	};

	const warningSamplingByEnv = {
		local: 0.0,
		staging: 1,
		prod: 0.1,
	};

	Sentry.init({
		dsn: 'https://f9c1f6cfdd4447ab9b1f730827fa77aa@o484813.ingest.sentry.io/5538575',
		enabled,
		integrations: [
			new Integrations.BrowserTracing({
				tracingOrigins: originsByEnv[env().name],
				beforeNavigate: (context) => {
					return {
						...context,
						name: `${appName()}${context.name}`,
					};
				},
			}),
		],
		tracesSampleRate: traceSamplingByEnv[env().name],
		environment: env().name,
		release: `${appName()}@${process.env['REACT_APP_VERSION']}`,
		initialScope: (scope) => {
			scope.setTag('appName', appName());
			scope.setTag('commit', process.env['REACT_APP_VERSION']);
			return scope;
		},
		...(isDevBuild() && devConfig),
		beforeSend: (event) => {
			if (event.level === Sentry.Severity.Warning) {
				// sample warnings
				const warningsRate = warningSamplingByEnv[env().name];

				const contexts = event.contexts || {};
				contexts['SAMPLING'] = {
					warningsSampleRate: warningsRate,
				};

				return Math.random() < warningsRate ? event : null;
			}
			return event;
		},
	});
}
