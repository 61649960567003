import { useLocation } from 'react-router';

type QueryParams = {
	accountId?: string;
};

export function useQueryParams(): QueryParams {
	const search = new URLSearchParams(useLocation().search);
	return { accountId: search.get('accountId') || undefined };
}

/** @deprecated use useQueryParams */
export function useQuery() {
	return new URLSearchParams(useLocation().search);
}
