/*
  Let people into this page only if they are NOT authenticated

  This means:
    1. Auth/Profile has sucessfully loaded (handled by Loader)
    2. The user is not currently logged in: isEmpty(profile)
*/

import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useCurrentUser } from '../hooks';
import { Loading } from '../components';

function UnAuthenticatedRoute({ component: Component, blocked, ...rest }) {
	const location = useLocation();
	const { from } = location.state || { from: { pathname: '/' } };
	const user = useCurrentUser();

	if (!user.isLoaded) {
		return <Loading showRandomLabels={true} />;
	}

	return (
		<Route
			{...rest}
			render={(props) =>
				!user.isAuthenticated || !blocked ? (
					<Component {...props} {...rest} continue={from.pathname} />
				) : (
					<Redirect to={from} />
				)
			}
		/>
	);
}

export default UnAuthenticatedRoute;
