import {
	createRoutes,
	buildRoute,
	ContinueOpts,
	buildContinueUrl,
} from './types';

// Be careful not to create cyclic dependencies with routes.
// The role of this file is just to declare paths and how
// route parameters are built into paths/state to ensure
// we reuse same logic and don't forget continueUrl where needed

export const routes = createRoutes({
	home: '/' as const,
	handleLink: '/handle-link' as const,
	resetPassword: '/reset-password' as const,
	onboard: '/onboard' as const,
	logout: '/logout' as const,
	profile: '/profile' as const,
	/** @deprecated use `select-circle` route */
	selectAccount: buildRoute(
		`/select-account/:gameType`,
		(gameType: 'bingo' | 'trivia' | 'physical') => {
			return {
				pathname: `/select-account/${gameType}`,
			};
		},
	),
	selectCircle: buildRoute('/select-circle', (opts: ContinueOpts) => {
		return {
			pathname: '/select-circle',
			state: buildContinueUrl(opts),
		};
	}),
	verifyEmail: buildRoute('/verify-email', (opts: ContinueOpts) => {
		return {
			pathname: '/verify-email',
			state: buildContinueUrl(opts),
		};
	}),
	createFirstAccount: buildRoute(
		'/create-first-account',
		(opts: ContinueOpts) => {
			return {
				pathname: '/create-first-account',
				state: buildContinueUrl(opts),
			};
		},
	),
	createAccount: buildRoute('/create-account', (opts: ContinueOpts) => {
		return {
			pathname: '/create-account',
			state: buildContinueUrl(opts),
		};
	}),
	integrateSlack: buildRoute('/integrate-slack', (opts: ContinueOpts) => {
		return {
			pathname: '/integrate-slack',
			state: buildContinueUrl(opts),
		};
	}),
	login: buildRoute('/login', (opts: ContinueOpts) => {
		return {
			pathname: '/login',
			state: buildContinueUrl(opts),
		};
	}),
	register: buildRoute('/register', (opts: ContinueOpts) => {
		return {
			pathname: '/register',
			state: buildContinueUrl(opts),
		};
	}),
	welcome: buildRoute('/welcome', (opts: ContinueOpts) => {
		return {
			pathname: '/welcome',
			state: buildContinueUrl(opts),
		};
	}),
	upgrade: buildRoute('/register/upgrade', (opts: ContinueOpts) => {
		return {
			pathname: '/register/upgrade',
			state: buildContinueUrl(opts),
		};
	}),
});
