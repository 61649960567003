import {
	Container,
	createStyles,
	makeStyles,
	Paper,
	Theme,
	useMediaQuery,
} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) =>
	createStyles({
		paper: {
			height: 'auto',

			padding: theme.spacing(0),
			borderRadius: theme.spacing(3),

			[theme.breakpoints.down('xs')]: {
				borderRadius: 0,
			},
		},
	}),
);

type Props = {
	// only supposed to be used to setup layout of the internal components
	className?: string;
	variant?: 'single-column' | 'two-column';
};

/**
 * Ensures central positioning and consistent padding.
 * - On desktop paper is elevated to produce shadows and round border.
 * - On extra small screens no need to elevate.
 */
export const ScreenContainer: React.ComponentType<Props> = ({
	children,
	className,
	variant,
}) => {
	const styles = useStyles();
	const isExtraSmall = useMediaQuery<Theme>((theme) =>
		theme.breakpoints.down('xs'),
	);
	const maxWidth = variant === 'two-column' ? 'md' : 'xs';
	return (
		<Container {...(!isExtraSmall && { maxWidth })} fixed disableGutters>
			<Paper
				elevation={isExtraSmall ? 0 : 3}
				className={clsx(styles.paper, className)}
			>
				{children}
			</Paper>
		</Container>
	);
};
