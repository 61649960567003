import React, { useCallback, useContext, useState, useEffect } from 'react';
import { registerError } from '../errors';
import { useCurrentUser } from '../hooks';

const Context = React.createContext({});

export const GameProvider = ({ firebase, children }) => {
	const user = useCurrentUser();
	const [hasJoinedGame, setHasJoinedGame] = useState(null);

	const joinGame = useCallback(
		(gameId) => {
			(async () => {
				const joinGame = firebase
					.functions()
					.httpsCallable('platform-addPlayerToGame');

				try {
					await joinGame({
						gameId,
						userId: user.uid,
					});
				} catch (e) {
					registerError(e);
					return;
				}

				const playerRef = firebase
					.database()
					.ref(`/games/${gameId}/playersData/${user.uid}`);

				await playerRef.update({ connected: true });
				await playerRef.onDisconnect().update({ connected: false });

				setHasJoinedGame(true);
			})();
		},
		[firebase, user.uid],
	);

	const leaveGame = useCallback(
		(gameId) => {
			(async () => {
				const playerRef = firebase
					.database()
					.ref(`/games/${gameId}/playersData/${user.uid}`);

				await playerRef.update({ connected: false });

				setHasJoinedGame(false);
			})();
		},
		[firebase, user.uid],
	);

	return (
		<Context.Provider
			value={{
				hasJoinedGame,
				joinGame,
				leaveGame,
			}}
		>
			{children}
		</Context.Provider>
	);
};

export const useGame = (gameId) => {
	const { joinGame, leaveGame, hasJoinedGame } = useContext(Context);

	useEffect(() => {
		if (gameId) {
			joinGame(gameId);
		}

		return () => {
			leaveGame(gameId);
		};
	}, [gameId, joinGame, leaveGame]);

	return hasJoinedGame;
};
