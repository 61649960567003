/** CurrentUser2
 * This context monitors the firebase auth and firestore
 * to return a user object with the user profile
 * when a user has successfully authenticated
 **/

import React, { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import {
	useCrossDomainSync,
	useSynchronizeUserProfile,
} from '../hooks/useAuth';
import { isDevBuild } from '@remote-social/common/src/environment';
import { registerError } from '@remote-social/common/src/errors';

// const for developer environment and debug console output
const dev = isDevBuild();
const enableLogs = false;
const debug = dev && enableLogs;

/**
 * @typedef {{
 *   uid: string,
 *   displayName: string,
 *   photoURL?: string,
 *   isAnonymous: boolean,
 *   email?: string,
 *   emailVerified: boolean,
 *   isAuthenticated: boolean,
 *   accounts: string[],
 * }} User
 */

/**
 * @typedef {{ isLoaded: true, } & User} LoadedUser
 */

/**
 * @typedef {{
 *     isLoaded: false,
 *     isAuthenticated: false
 *   } & Partial<Omit<User, 'isAuthenticated'>>
 * } LoadingUser
 */

/**
 * @typedef {LoadedUser | LoadingUser} UseCurrentUserState
 */

/**
 * Temporary helper while we didn't migrate to TypeScript
 *
 * @type {React.Context<UseCurrentUserState>}
 */
const CurrentUserContext = React.createContext();

// the current user provider
export const CurrentUserProvider = ({ children }) => {
	// debug && console.log('CurrentUser2: CurrentUserProvider');
	// fetch the current auth from redux state
	const auth = useSelector((state) => state.firebase?.auth);
	// confirm that crossDomainSync has been run at least once
	const crossDomainSync = useCrossDomainSync();
	const [initialSync, setInitialSync] = useState(false);

	// setup listening to firestore for updates to the auth profile
	useFirestoreConnect(
		auth?.uid && [
			{
				collection: 'users',
				doc: auth.uid,
			},
		],
	);

	// assign the path to the users profile document in firestore
	const profilePath = `users/${auth?.uid}`;

	// fetch the profile data from redux state
	const profile = useSelector(
		(state) => auth?.uid && state.firestore?.data?.users?.[auth.uid],
	);
	// assign the status of the profile data (loaded, loading, pending)
	const profileLoadingState = useSelector((state) =>
		state.firestore?.status?.requested?.[profilePath]
			? 'loaded'
			: state.firestore?.status?.requesting?.[profilePath]
			? 'loading'
			: 'pending',
	);

	// return the currentUser profile data to the client
	const currentUser = useMemo(() => {
		// auth has not finished loading or is missing
		if (!auth || !auth.isLoaded) {
			debug && console.log('auth is not loaded');
			// return not loaded state
			// trigger an initial login sync
			debug && console.log('CUP: loginSync-1');
			crossDomainSync.triggerLoginSync();
			return {
				uid: undefined,
				isLoaded: false,
				isAuthenticated: false,
			};
		}

		// auth has finished loading
		if (auth && auth.isLoaded) {
			// auth isEmpty
			if (auth.isEmpty) {
				debug && console.log('auth is loaded but auth is empty');
				// the auth record is loaded, but there is no authenticated user
				// we can check at this point whether the user was logged in
				// on another tab with cross-domain sync

				// set up polling to look for the appearance of a rsCSRF cookie
				// we need to wait for this to run at least once so that we don't
				// show the logged out state to the user when they may be already
				// logged in on different subdomain. After the first run
				// we can poll for updates on a schedule
				if (crossDomainSync.syncState !== 'login') {
					debug &&
						console.log(
							'previous syncState',
							crossDomainSync.syncState,
						);
					// trigger to kick-off login sync
					debug && console.log('start login check');
					debug && console.log('CUP: loginSync-2');
					crossDomainSync
						.triggerLoginSync()
						.then((res) => {
							debug && console.log('loginSyncResponse', res);
							setInitialSync(true);
						})
						.catch((error) => {
							registerError(error);
						});
				}

				// return loaded but not authenticated
				return {
					uid: undefined,
					isLoaded: initialSync,
					isAuthenticated: false,
				};
			}

			// auth is NOT empty but profile is missing or not loaded
			if (!profile || profileLoadingState !== 'loaded') {
				// profile is missing or not loaded yet
				debug &&
					console.log('auth is loaded but profile is not loaded');
				// return not loaded state
				return {
					uid: auth && auth.uid,
					profile: false,
					isLoaded: false,
					isAuthenticated: true,
				};
			}
		}

		// auth has finished loading and there is an authenticated user
		debug && console.log('auth is loaded and profile is loaded');
		if (crossDomainSync.syncState !== 'logout') {
			debug && console.log('start logout check');
			debug && console.log('CUP: logoutSync-1');
			crossDomainSync.triggerLogoutSync();
		}

		return {
			uid: auth.uid,
			profile: true,
			...profile,
			// trust Firebase for emailVerified field first, but fallback to our field
			// to not break the experience for users who were verified by a custom DB update hack
			emailVerified: auth.emailVerified || profile.emailVerified,
			isLoaded: true,
			isAuthenticated: true,
		};
	}, [auth, profile, initialSync, auth?.emailVerified]);

	React.useEffect(() => {
		const dataLayer = window.dataLayer || [];
		if (
			auth &&
			auth.isLoaded &&
			profile &&
			!dataLayer.find((data) => data.uid === auth.uid)
		) {
			const userCreatedAt = profile.createdAt.toDate().toISOString();
			const userUpdatedAt = profile.updatedAt.toDate().toISOString();

			dataLayer.push({
				event: 'ANALYTICS/USER_LOADED',
				uid: auth.uid,
				userCreatedAt,
				userUpdatedAt,
			});
		}
	}, [auth, profile]);

	useSynchronizeUserProfile({
		isAuthenticated: currentUser.isAuthenticated,
		userDoc: profile,
	});

	return (
		<CurrentUserContext.Provider value={currentUser}>
			{children}
		</CurrentUserContext.Provider>
	);
};

export const useCurrentUser = () => useContext(CurrentUserContext);
