import { SlackIntegration } from '@contracts/platform/slack/integration';
import { isPending, isSuccess } from '../../store-tools';
import { literalAs } from '../../utils/literalAs';
import { useBackendFunction } from '../../hooks/useBackendFunction';
import { useCurrentUser } from '../..';
import React from 'react';

type Opts = {
	accountId?: string;
	requiredScopes: string[];
};

export const useSlackIntegration = ({ accountId, requiredScopes }: Opts) => {
	const user = useCurrentUser();
	const integrationsRequest = useBackendFunction(
		'platform-getSlackIntegrations',
		{
			callOnChange: () => {
				if (!accountId || !user.isAuthenticated) {
					// prevents the call
					return;
				}
				return {
					accountId,
					requiredScopes,
				};
			},
			initialValue: literalAs<SlackIntegration[]>([]),
		},
	);

	return React.useMemo(
		() => ({
			...integrationsRequest,
			// temporary for backward compatibility:
			loaded: isSuccess(integrationsRequest),
			loading: isPending(integrationsRequest),
			integrations: integrationsRequest.data,
		}),
		[integrationsRequest],
	);
};
