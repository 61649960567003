import React from 'react';
import { IconButton } from '@material-ui/core';
import announcements from '../assets/images/announcements.svg';
import { makeStyles } from '@material-ui/styles';

declare global {
	interface Window {
		Frill: Frill;
		Frill_Config: FrillConfig[];
	}

	class Frill {
		widget(config: FrillConfig): FrillWidget;
	}

	class FrillWidget {
		open(): void;
		close(): void;
		destroy(): void;
	}

	interface FrillConfig {
		key: string;
		container?: HTMLElement;
		ssoToken?: string;
		callbacks: {
			onReady(frillWidget: FrillWidget): void;
		};
	}
}

const useStyles = makeStyles(() => ({
	icon: {
		height: 28,
	},
}));

const frillAnnouncementKey = '6ffc2c8d-4f71-42d1-a5f3-78cb20c6a183';

export const FrillAnnouncements: React.FC = () => {
	const classes = useStyles();
	React.useEffect(() => {
		let widget: FrillWidget;
		const config: FrillConfig = {
			key: frillAnnouncementKey,
			callbacks: {
				onReady: (frillWidget) => {
					widget = frillWidget;
				},
			},
		};

		window.Frill_Config = window.Frill_Config || [];
		window.Frill_Config.push(config);

		if ('Frill' in window) {
			widget = window.Frill.widget(config);
		}

		return () => {
			widget?.destroy();
			window.Frill_Config = window.Frill_Config.filter(
				(c: FrillConfig) => c !== config,
			);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<IconButton className="frill-announce">
			<img
				src={announcements}
				alt="announcements"
				className={classes.icon}
			/>
		</IconButton>
	);
};
