import React from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { useAsyncFunction } from '../hooks';

export const currentTimezone = () => {
	try {
		return Intl.DateTimeFormat().resolvedOptions().timeZone;
	} catch (e) {
		// low chance browsers do not support the above
		return 'Australia/Sydney';
	}
};

type SearchableTimeZone = {
	name: string;
	displayName: string;
	searchText: string;
};

const getTimeZones = async (): Promise<SearchableTimeZone[]> => {
	const result = await import('@vvo/tzdb');
	return result.getTimeZones().map((zone) => ({
		name: zone.name,
		displayName: `${zone.name} (${zone.currentTimeFormat})`,
		searchText: [
			zone.currentTimeFormat,
			zone.abbreviation,
			zone.alternativeName,
			zone.countryName,
		].join(' '),
	}));
};

const timezoneFilterOptions = createFilterOptions<SearchableTimeZone>({
	ignoreCase: true,
	stringify: (tz) => tz.searchText,
});

type Props = {
	value?: string;
	onChange?: (value: string) => void;
	label?: string;
	helperText?: string;
};

export const TimeZoneSelect: React.ComponentType<Props> = React.memo(
	({ value, onChange: onChangeProp, label, helperText }) => {
		const timeZonesRequest = useAsyncFunction(getTimeZones, {
			initialValue: [],
		});

		const { initiate } = timeZonesRequest;

		React.useEffect(() => {
			initiate();
		}, [initiate]);

		const [timeZone, setTimeZone] = React.useState(() => {
			return value ?? currentTimezone();
		});

		const selectedTimeZone = React.useMemo(
			() =>
				timeZonesRequest.data.find(
					(zone) => zone.name === timeZone,
				) ?? {
					name: timeZone,
					displayName: timeZone,
					searchText: '',
				},
			[timeZone, timeZonesRequest],
		);

		const onChange = React.useCallback(
			(
				_event: React.ChangeEvent<{}>,
				timeZone: SearchableTimeZone | null,
			) => {
				if (!timeZone) {
					return;
				}
				setTimeZone(timeZone.name);
				if (!onChangeProp) {
					return;
				}
				onChangeProp(timeZone.name);
			},
			[onChangeProp],
		);

		return (
			<Autocomplete
				value={selectedTimeZone}
				onChange={onChange}
				filterOptions={timezoneFilterOptions}
				options={timeZonesRequest.data}
				getOptionLabel={(tz) => tz.displayName}
				renderInput={(params) => (
					<TextField
						{...params}
						label={label}
						margin="dense"
						variant="outlined"
						helperText={helperText}
					/>
				)}
			/>
		);
	},
);
TimeZoneSelect.displayName = 'TimeZone';
