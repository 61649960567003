import React, { useReducer, Reducer, Dispatch } from 'react';
import { ensureError, ErrorType, registerError } from '../errors';
import type {
	RequestState,
	RequestStateWithPayload,
} from '../store-tools/requestState';

type Actions<Data, Extra = {}> = (
	| {
			type: 'pending';
	  }
	| {
			type: 'error';
			error: ErrorType;
	  }
	| {
			type: 'success';
			data: Data;
	  }
) &
	Extra;

const createReducer = <T, Extra, I>(): Reducer<
	RequestStateWithPayload<T, I> & Extra,
	Actions<T>
> => {
	return (state, action) => {
		switch (action.type) {
			case 'pending': {
				const { type, ...extra } = action;
				return {
					...state,
					...extra,
					status: type,
				};
			}
			case 'error': {
				const { type, error, ...extra } = action;
				return {
					...state,
					...extra,
					status: type,
					error: error,
				};
			}
			case 'success': {
				const { type, data, ...extra } = action;
				return {
					...state,
					...extra,
					status: action.type,
					data: action.data,
				};
			}
		}
	};
};

// ensures there is only one request at a time and tracks
// component mounted event to warn about unmounting and lost state
const useRequestResultsDispatcher = <Data, Extra>(
	dispatchFn: Dispatch<Actions<Data, Extra>>,
) => {
	const unmountedError = React.useMemo(
		() => new Error('Component unmounted during useAsyncRequest request'),
		[],
	);
	const dispatch: typeof dispatchFn = (action) => {
		if (!isMounted) {
			return;
		}
		dispatchFn(action);
	};

	let isMounted = true;
	let currentPromise: Promise<Data> | undefined;

	const unmounted = () => {
		if (currentPromise) {
			const msg =
				'💥 Woopsy! The component was unmounted during a request ' +
				'(leading to a lost request state), which should not happen! Lift the state up to context! ' +
				'Or prevent the unmount.';
			console.warn(new Error(msg).stack);
			registerError(unmountedError);
		}
		isMounted = false;
	};

	const initiate = <Fn extends (...args: any[]) => Promise<Data>>(
		params: {
			call: Fn;
			args: Parameters<Fn>;
		} & Extra,
	): void => {
		if (currentPromise) {
			return;
		}

		const { call, args, ...extra } = params;

		dispatch({
			...extra,
			type: 'pending',
		});

		try {
			currentPromise = call(...args);
		} catch (err) {
			// handle the case when supposedly
			// async function throws synchronously
			currentPromise = Promise.reject(err);
		}

		currentPromise
			.then((value) => {
				dispatch({
					type: 'success',
					data: value,
				});
			})
			.catch((error) => {
				registerError(error);
				dispatch({
					type: 'error',
					error: ensureError(error),
				});
			})
			.finally(() => {
				currentPromise = undefined;
			});
	};
	return {
		initiate,
		unmounted,
	};
};

type UseAsyncRequestResult<Data, Extra, I> = RequestState<Data, I> &
	Extra & {
		initiate: <Fn extends (...args: any[]) => Promise<Data>>(
			params: {
				call: Fn;
				args: Parameters<Fn>;
			} & Extra,
		) => void;
	};

export function useAsyncRequest<Data = void, Extra = {}, I = undefined>(
	initial?: I,
): UseAsyncRequestResult<Data, Extra, I>;
export function useAsyncRequest<Data = void, Extra = {}, I = undefined>(
	initial: I,
): UseAsyncRequestResult<Data, Extra, I> {
	const [state, dispatch] = useReducer(createReducer<Data, Extra, I>(), {
		status: 'initial',
		data: initial,
	});

	// we use ref because dispatcher is used both in effect and callback
	const dispatcher = React.useRef(
		useRequestResultsDispatcher<Data, Extra>(dispatch),
	).current;

	// let the dispatcher know when our component unmounts
	// so it can handle state mutation correctly
	React.useEffect(() => () => dispatcher.unmounted(), [dispatcher]);

	const initiate = React.useCallback(
		<Fn extends (...args: any[]) => Promise<Data>>(
			params: {
				call: Fn;
				args: Parameters<Fn>;
			} & Extra,
		) => {
			dispatcher.initiate(params);
		},
		[dispatcher],
	);

	const result = React.useMemo(
		() =>
			Object.assign({}, state, {
				initiate,
			}),
		[state, initiate],
	);

	return result as UseAsyncRequestResult<Data, Extra, I>;
}
