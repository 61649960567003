import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';

import { DocumentHead } from '@remote-social/common';

import Layout from '../Layout';
import { CTAButton } from '../../components/CTAButton';

const useStyles = makeStyles((theme) => ({
	header: {
		fontWeight: 800,
		fontSize: 48,
		marginTop: theme.spacing(4),
	},
	listNumber: {
		display: 'inline-block',
		verticalAlign: 'middle',
		marginRight: theme.spacing(2),
	},
	instructions: {
		fontWeight: 800,
		listStyle: 'none',
		'& > li': {
			margin: theme.spacing(2, 'auto', 2), //, '1rem'),
			fontSize: 24,
		},
		margin: theme.spacing(10, 'auto', 8),
	},
}));

const Emoji = ({ char, label, className }) => (
	<span className={className} role="img" aria-label={label}>
		{char}
	</span>
);

export default function Home() {
	const classes = useStyles();

	return (
		<Layout>
			<DocumentHead title="Home" />
			<Grid item xs={12} align="center">
				<Typography variant="h2" className={classes.header}>
					Let’s Get Physical
				</Typography>
				<Typography component="div" align="left">
					<ol className={classes.instructions}>
						<li>
							<Emoji
								char="😀"
								label="grinning face"
								className={classes.listNumber}
							/>
							Connect to your team’s Slack
						</li>
						<li>
							<Emoji
								char="🗓"
								label="spiral calendar"
								className={classes.listNumber}
							/>
							Create your challenge schedule
						</li>
						<li>
							<Emoji
								char="🕺"
								label="man dancing"
								className={classes.listNumber}
							/>
							Get physical
						</li>
					</ol>
				</Typography>
				<CTAButton to="/select-account/physical">
					Create Physical Challenge
				</CTAButton>
			</Grid>
		</Layout>
	);
}
