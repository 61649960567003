import { ensureError } from '../errors';
import { registerAttemptedCall } from '../errors/registerAttemptedCall';
import { RetryConfig, makeAsyncFunctionRetry } from '../utils/retryWithBackoff';
import { ApiCall } from './apiCall';

/**
 * NOTE: Sometimes our functions cold start for a very long time (> 10 second)
 * and that sometimes leads to functions being aborted in GCP. Aborted functions
 * return HTTP 500 - we want to implement retry with backoff as recommended by
 * Google Cloud Documentation
 */

// TODO: These should be moved to contracts

const readonlyFunctions = [
	'expcentre-getShowcase',
	'expcentre-getShareTribeClientID',
	'platform-getAccounts',
	'platform-getAccountMembers',
	'platform-getAccountMembersWithRolesAndInvites',
	'platform-selectGameAccount',
	'platform-getSlackIntegrations',
	'platform-listChannels',
	'platform-generateSlackOAuthURL',
	'platform-handleInvitedPeople',
	'trivia-refreshGameCategories',
	'trivia-getTriviaCategoriesConfig',
	'trivia-getCustomTriviaCategory',
	'trivia-getCustomTriviaCategories',
	'trivia-isValidQuestion',
];

const relativelySafeToRetryImportantFunctions = [
	'platform-createGame',
	'platform-addPlayerToGame',
	'platform-removePlayerFromGame',
	'platform-createZiagoGame',
	'platform-syncFromAuth',

	'platform-setSentiment',

	'bingo-startGame',
	'bingo-drawNumber',
	'bingo-checkBingo',
	'bingo-closeRound',

	'trivia-startGame',
	'trivia-submitAnswer',
	'trivia-updateConfig',
	'trivia-nextQuestion',

	'trivia-createCustomTriviaCategory',
	'trivia-deleteCustomTriviaCategory',
	'trivia-addCategoryQuestion',
	'trivia-deleteCategoryQuestion',

	'physical-addOrEditChallenge',
	'physical-stopChallenge',
];

const idempotentFunctions = [
	'platform-invitePeople',
	'platform-updateMemberRole',
	'platform-createOrUpdateGameSchedule',

	'trivia-updateCustomTriviaCategory',
	'trivia-publishCustomTriviaCategory',
	'trivia-updateCategoryQuestion',

	'connect-updateSchedule',
];

const isInternalErrorOrTimeout = (error: any) => {
	const err = ensureError(error);
	return (
		(err.code && ['internal', 'deadline-exceeded'].includes(err.code)) ||
		err.message.toUpperCase() === 'INTERNAL'
	);
};

export const autoRetryConfigForFunction = (
	functionName: string,
): RetryConfig => {
	const logAttempt = (data: {
		error: any;
		attempts: number;
		wouldRetry: boolean;
	}) => {
		registerAttemptedCall(data.error, {
			functionName,
			attempts: data.attempts,
			wouldRetry: data.wouldRetry,
		});
	};

	if (readonlyFunctions.includes(functionName)) {
		return {
			initialInterval: 100,
			maxRetries: 4,
			shouldRetry: isInternalErrorOrTimeout,
			logAttempt,
		};
	} else if (idempotentFunctions.includes(functionName)) {
		return {
			initialInterval: 1000,
			maxRetries: 3,
			shouldRetry: isInternalErrorOrTimeout,
			logAttempt,
		};
	} else if (relativelySafeToRetryImportantFunctions.includes(functionName)) {
		return {
			initialInterval: 1000,
			maxRetries: 3,
			shouldRetry: isInternalErrorOrTimeout,
			logAttempt,
		};
	}

	return {
		initialInterval: 1000,
		maxRetries: 1,
		logAttempt,
	};
};

export const withAutoRetry = (apiCall: ApiCall): ApiCall => {
	const apiCallWithAutoRetry: ApiCall = (functionName, ...rest) => {
		const config = autoRetryConfigForFunction(functionName);
		const retried = makeAsyncFunctionRetry(config, () => {
			return apiCall(functionName, ...rest);
		});
		return retried();
	};
	return apiCallWithAutoRetry;
};
