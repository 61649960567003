import React from 'react';

// material-ui
import { Container, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import './layout-fonts.css';

const paperBg = 'https://cdn.remotesocial.io/physical/img/paper-bg.svg';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexGrow: 1,
		alignItems: 'center',
		justifyItems: 'center',
		zIndex: 2,
		margin: '3rem auto',
	},
	paper: {
		padding: '2rem',
		borderRadius: '2.5rem',
		border: `4px solid ${theme.palette.common.white}`,
		boxShadow: `0 0 1rem ${theme.palette.text.hint}`,
		width: '100%',
		minHeight: '350px',
		position: 'relative',
		backgroundImage: `url(${paperBg})`,
	},
	logo: {
		fill: theme.palette.primary.main,
		maxWidth: '5rem',
		margin: '0.5rem auto 2rem',
		zIndex: 1,
	},
	siteContent: {},
}));

export default function Layout({ children, ...rest }) {
	const classes = useStyles();
	return (
		<Container maxWidth="sm" className={classes.container} {...rest}>
			<Paper className={classes.paper}>{children}</Paper>
		</Container>
	);
}
