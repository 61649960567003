import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PageNotFound, useCurrentUser } from '@remote-social/common/src';
import {
	getCommonAuthRoutes,
	useEnsureHasAccountForPaths,
	useRedirectToLoginForPrivateRoutes,
} from './internal';
import { useRedirectToHomeForLoginRoutes } from './internal/useRedirectToHomeForLoginRoutes';
import { Allowed, isAllowed } from './user-land-routes/isAllowedRoute';

type Props = {
	// paths that require at least one account created
	createAccountPaths?: string[];

	// NOTE: there is no way to enforce type of element
	// unless we pass in Props instead of Elements

	/**
	 * Public routes, accepts a Route and Redirect or Fragment and Switch
	 * which wrap multiple Route's or Redirect's
	 */
	public?: React.ReactElement;

	/**
	 * Private routes, accepts a Route and Redirect or Fragment and Switch
	 * which wrap multiple Route's or Redirect's
	 */
	private?: React.ReactElement;

	ScreenContainer?: React.ComponentType<{
		variant?: 'two-column' | 'single-column';
	}>;

	Loading?: React.ComponentType;
};

type Container = React.ReactElement<{ children: React.ReactNode }>;

function isContainer(element: React.ReactElement): element is Container {
	const allowed: React.JSXElementConstructor<any>[] = [
		// switch is allowed because we remove it and wrap everything
		// with our own switch
		Switch,
		React.Fragment,
	];
	return (
		React.isValidElement(element) &&
		typeof element.type !== 'string' &&
		allowed.includes(element.type)
	);
}

function collectRoutes(fragment: React.ReactElement): Allowed[] {
	if (Array.isArray(fragment)) {
		return fragment.filter(isAllowed);
	} else if (React.isValidElement(fragment)) {
		if (isAllowed(fragment)) {
			return [fragment];
		} else if (isContainer(fragment)) {
			// wrapping by Switch or Fragment?
			if (Array.isArray(fragment.props.children)) {
				return fragment.props.children.filter(isAllowed);
			} else if (isAllowed(fragment.props.children)) {
				return [fragment.props.children];
			}
		}
	}
	throw new Error(`Invalid element type passed ${fragment}`);
}

const optional = <T,>(arr: T[] | false): T[] => (Array.isArray(arr) ? arr : []);

export const CommonRouter: React.ComponentType<Props> = (props) => {
	const user = useCurrentUser();
	const { createAccountPaths = [] } = props;

	const commonRoutes = React.useMemo(
		() =>
			getCommonAuthRoutes({
				ScreenContainer: props.ScreenContainer,
			}),
		[props.ScreenContainer],
	);

	const publicRoutes = React.useMemo(
		() => [
			...commonRoutes.public,
			...optional(!!props.public && collectRoutes(props.public)),
		],
		[commonRoutes.public, props.public],
	);
	const privateRoutes = React.useMemo(
		() => [
			...commonRoutes.private,
			...optional(!!props.private && collectRoutes(props.private)),
		],
		[commonRoutes.private, props.private],
	);
	const unauthenticated = commonRoutes.unauthenticated;

	const rules =
		useEnsureHasAccountForPaths(createAccountPaths).filter(isAllowed);
	const redirectToHome = useRedirectToHomeForLoginRoutes({
		unauthenticatedRoutes: unauthenticated,
	}).filter(isAllowed);
	const redirectToLogin = useRedirectToLoginForPrivateRoutes({
		privateRoutes,
	}).filter(isAllowed);

	const routeElements = [
		...rules,
		...publicRoutes,
		...(user.isAuthenticated
			? [...redirectToHome, ...privateRoutes]
			: [...unauthenticated, ...redirectToLogin]),
		<Route>
			<PageNotFound />
		</Route>,
	];

	// this bit is relatively fast, so probably doesn't need
	// loading indicator
	if (!user.isLoaded) {
		return props.Loading ? <props.Loading /> : null;
	}

	return (
		<Switch
			children={routeElements.map((route, i) =>
				React.cloneElement(route, {
					key: i,
				}),
			)}
		/>
	);
};
