import React from 'react';
import Logo from './RemoteSocialLogo';
import { Grid, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { CommonThemeProvider } from '../styles/commonThemeProvider';
import { env } from '../environment';

import LinkedIn from '../assets/images/linkedin.svg';
import Facebook from '../assets/images/facebook.svg';
import Instagram from '../assets/images/instagram.svg';

const useStyles = makeStyles((theme) => ({
	footer: {
		background: '#FFF',
		padding: theme.spacing(3),
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(5),
		},
	},
	footerContent: {
		justifyContent: 'flex-start',
	},
	text: {
		[theme.breakpoints.up('md')]: {
			marginLeft: '-25px',
		},
		[theme.breakpoints.up('lg')]: {
			marginLeft: '-40px',
		},
		[theme.breakpoints.up('xl')]: {
			marginLeft: '-70px',
		},
	},
	footerText: {
		color: theme.palette.text.primary,
		[theme.breakpoints.up('md')]: {
			display: 'inline-flex',
		},
		'& > *': {
			marginRight: '3px',
			display: 'inline-block',
			fontSize: '10px',
			lineHeight: '1.5',
			fontWeight: '400',
			fontFamily: theme.typography.fontFamily,
			[theme.breakpoints.up('sm')]: {
				fontSize: '0.9rem',
			},
			[theme.breakpoints.up('md')]: {
				fontSize: '1rem',
			},
		},
	},
	logo: {
		height: '35px',
		color: 'inherit',
		fill: 'inherit',
	},
	socialContent: {
		justifyContent: 'center',
	},
	socialIcons: {
		margin: theme.spacing(1, 0.7),
		fill: theme.palette.text.primary,
	},
}));

const FooterInternal = () => {
	const classes = useStyles();
	const envConfig = env();

	return (
		<Grid container className={classes.footer}>
			<Grid
				item
				xs={12}
				container
				alignItems="center"
				className={classes.footerContent}
			>
				<Grid item xs={2} sm={1} md={1}>
					<Link href={envConfig.hubUrl}>
						<Logo className={classes.logo} />
					</Link>
				</Grid>
				<Grid item xs={10} className={classes.text}>
					<Grid className={classes.footerText}>
						<Typography>
							Copyright © {new Date().getFullYear()}
						</Typography>
						<Link
							color="inherit"
							href={envConfig.webUrl}
							target="_blank"
							rel="noopener noreferrer"
						>
							Remote Social.
						</Link>
						<Typography>{'All rights reserved. '}</Typography>
						<Link
							color="inherit"
							href={`${envConfig.webUrl}/legal/terms-conditions`}
						>
							Terms
						</Link>
						<Typography>|</Typography>
						<Link
							color="inherit"
							href={`${envConfig.webUrl}/legal/privacy`}
						>
							Privacy
						</Link>
					</Grid>
				</Grid>
			</Grid>
			<Grid item container xs={12} className={classes.socialContent}>
				&nbsp;
			</Grid>
			<Grid item container xs={12} className={classes.socialContent}>
				<Link
					href="https://www.facebook.com/remotesocialfb"
					target="_blank"
					rel="noopener noreferrer"
				>
					<img
						className={classes.socialIcons}
						src={Facebook}
						alt="Facebook"
					></img>
				</Link>
				<Link
					href="https://www.instagram.com/remotesocial/"
					target="_blank"
					rel="noopener noreferrer"
				>
					<img
						className={classes.socialIcons}
						src={Instagram}
						alt="Instagram"
					></img>
				</Link>
				<Link
					href="https://www.linkedin.com/company/remotesocial-io/"
					target="_blank"
					rel="noopener noreferrer"
				>
					<img
						className={classes.socialIcons}
						src={LinkedIn}
						alt="LinkedIn"
					></img>
				</Link>
			</Grid>
		</Grid>
	);
};

export default function Footer() {
	return (
		<CommonThemeProvider>
			<FooterInternal />
		</CommonThemeProvider>
	);
}
