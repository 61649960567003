import * as Sentry from '@sentry/browser';
import { isDevBuild } from '../environment';
import { FunctionsError } from '../firebase/functionsError';
import { registerErrorOnce } from './registerErrorOnce';
import { ensureError } from './types';

export function registerAttemptedCall(
	error: unknown,
	extras: {
		functionName: string;
		attempts: number;
		wouldRetry: boolean;
	},
) {
	registerErrorOnce(error, () => {
		// These would be available once we allow the server side
		// to read them in CORS settings
		const { functionExecutionId, traceContext, httpStatus } =
			error as FunctionsError;

		if (isDevBuild()) {
			console.error(error, {
				...extras,
				...(httpStatus && {
					httpStatus,
				}),
				...(functionExecutionId && {
					functionExecutionId,
				}),
				...(traceContext && {
					traceContext,
				}),
			});
			return;
		}
		Sentry.captureException(error, (scope) => {
			const defaultFingerprints = '{{ default }}';
			if (extras.wouldRetry) {
				scope.setLevel(Sentry.Severity.Warning);
			}
			scope.setFingerprint([
				defaultFingerprints,
				String(extras.wouldRetry),
				extras.functionName,
			]);

			if (typeof httpStatus === 'number' && httpStatus < 500) {
				// these indicate bugs in the client code or actually
				// can be result of invalid user input as well - but
				// we log them anyway to ensure that we are collecting
				// all of the data
				scope.setLevel(Sentry.Severity.Warning);
			}
			if (httpStatus) {
				scope.setTag('httpStatus', httpStatus);
			}

			const err = ensureError(error);

			scope.setContext('API ERROR', {
				functionName: extras.functionName,
				attempts: extras.attempts,
				wouldRetry: extras.wouldRetry,
				...(err.code && {
					code: err.code,
				}),
				...(httpStatus && {
					httpStatus,
				}),
				...(functionExecutionId && {
					functionExecutionId,
				}),
				...(traceContext && {
					traceContext,
				}),
			});

			if (err.code) {
				scope.setTag('errorCode', err.code);
			}

			return scope;
		});
	});
}
