import React from 'react';
import {
	Menu,
	Grid,
	Dialog,
	MenuList,
	IconButton,
	useMediaQuery,
	createStyles,
	makeStyles,
	Theme,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { useCurrentUser } from '../../hooks';
import { ProfileMenu } from './profileMenu';
import { UserAvatar } from '../userAvatar';

const useStyles = makeStyles((theme) =>
	createStyles({
		popoverClass: {
			[theme.breakpoints.down('xs')]: {
				width: '100%',
				height: 'calc(100% - 85px)',
				maxWidth: 'unset',
				maxHeight: 'unset',
				left: '0 !important',
			},
		},
	}),
);

export const LoggedInLinks: React.ComponentType = () => {
	const user = useCurrentUser();
	const classes = useStyles();

	const menuAnchorRef = React.useRef<HTMLDivElement>(null);
	const [menuOpen, setMenuOpen] = React.useState<
		'closed' | 'dialogue' | 'menu'
	>('closed');

	const shouldUseMenu = useMediaQuery((theme: Theme) =>
		theme.breakpoints.up('sm'),
	);

	const handleOpen = React.useCallback(() => {
		if (shouldUseMenu) {
			setMenuOpen('menu');
		} else {
			setMenuOpen('dialogue');
		}
	}, [setMenuOpen, shouldUseMenu]);

	const handleClose = React.useCallback(() => {
		setMenuOpen('closed');
	}, [setMenuOpen]);

	if (!user.isLoaded) {
		return null;
	}

	return (
		<React.Fragment>
			<Grid item xs={1} container direction="row" alignItems="center">
				<UserAvatar
					ref={menuAnchorRef}
					withName={false}
					orientation="horizontal"
					user={user}
					click={handleOpen}
				/>
			</Grid>
			<Dialog
				fullScreen
				open={menuOpen === 'dialogue'}
				onClose={handleClose}
			>
				<Grid container alignItems="center" justifyContent="flex-end">
					<IconButton onClick={handleClose}>
						<ClearIcon fontSize="small" />
					</IconButton>
				</Grid>
				<ProfileMenu close={handleClose} />
			</Dialog>
			<Menu
				PopoverClasses={{ paper: classes.popoverClass }}
				getContentAnchorEl={null}
				anchorEl={menuAnchorRef.current}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'right',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'center',
					horizontal: 'right',
				}}
				open={menuOpen === 'menu'}
				onClose={handleClose}
			>
				<MenuList key="desktop">
					<ProfileMenu close={handleClose} />
				</MenuList>
			</Menu>
		</React.Fragment>
	);
};
