import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
	Grid,
	Typography,
	TextField,
	Link as UILink,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	makeStyles,
} from '@material-ui/core';

import {
	UserAvatar,
	ErrorMessage,
	Button,
	EditableField,
	EditIconButton,
	DocumentHead,
} from '../../components';
import {
	useCurrentUser,
	useAuth,
	useMountedRef,
	GOOGLE,
	FACEBOOK,
} from '../../hooks';
import { useCurrentAccount } from '../../contexts';
import {
	SocialErrorMessage,
	SocialLoginButton,
} from '../../components/SocialLoginButton';
import { changeOpacity } from '../../utils/colors';
import { isEmail } from '../../utils/validation';
import { registerError } from '../../errors';

const useStyles = makeStyles((theme) => ({
	profileHeader: {
		position: 'relative',
	},
	profilePhoto: {},
	profileBody: {
		overflowX: 'scroll',
		marginTop: theme.spacing(4),
	},
	bubble: {
		position: 'absolute',
		top: 0,
		left: '50%',
		padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
		background: theme.palette.background.paper,
		color: theme.palette.primary.main, // theme.palette.getContrastText(theme.palette.primary.main),
		borderRadius: theme.spacing(1),
		transform: `translate(15px, -22px)`,
		fontWeight: 'bold',
		boxShadow: `1px 0px 5px ${changeOpacity(
			theme.palette.primary.main,
			0.5,
		)}`,
		cursor: 'pointer',
		userSelect: 'none',
		zIndex: 10,
		'&::before': {
			display: 'block',
			content: '""',
			height: 0,
			width: 0,
			position: 'absolute',
			bottom: '-8px',
			left: 0,
			borderLeft: `7px solid ${theme.palette.background.paper}`,

			borderBottom: '10px solid transparent',
			borderTop: '10px solid transparent',
		},
	},
	bubbleP: {
		padding: 0,
		margin: 0,
		color: 'inherit',
	},
	accountList: {
		marginBottom: theme.spacing(4),
		marginLeft: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	displayName: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '50px',
	},
	displayNameInput: {
		height: '50px',
	},
	supportInfo: {
		position: 'absolute',
		bottom: theme.spacing(2),
		right: theme.spacing(4),
		fontSize: theme.typography.fontSize - 6,
	},
	fullWidth: { width: '100%' },
	sectionTitle: {
		marginBottom: theme.spacing(1),
	},
	linkedProvider: {
		opacity: 0.5,
		'&::after': {
			content: '"Linked"',
			display: 'flex',
			alignItems: 'center',
			position: 'absolute',
			top: 0,
			bottom: 0,
			right: theme.spacing(2),
		},
		'&:hover': {
			'&::after': {
				content: '"Unlink"',
			},
		},
	},
	unlinkedProvider: {
		'&:hover': {
			'&::after': {
				content: '"Link"',
				display: 'flex',
				alignItems: 'center',
				position: 'absolute',
				top: 0,
				bottom: 0,
				right: theme.spacing(2),
			},
		},
	},
}));

const greetings = [
	'Hi',
	'Howdy',
	"G'day",
	'Hola',
	'Sup?',
	'Hey there',
	'Yo!',
	'Howdy Pardner',
	"How's it hanging?",
	'Greetings',
	'Tally ho',
	'Konnichiwa',
	'Good day',
	'Bonjour',
	'Guten Tag',
	"What's up?",
	'Aloha',
	'Kia ora',
	'Bula',
	'Namaste',
	'Nǐ hǎo',
	'Olá',
	'Ciao',
	'Sà-wàt-dii',
	'Shalom',
	'Hej',
];

const UserAccountsList = () => {
	const { owned, setCurrentAccount } = useCurrentAccount();
	const history = useHistory();
	const classes = useStyles();
	const onAccountUrlClick = (id) => () => {
		setCurrentAccount(id);
		history.replace('/');
	};
	return (
		<Grid item xs={12} className={classes.profileBody}>
			<Typography
				className={classes.sectionTitle}
				component="h5"
				variant="h5"
				align="left"
			>
				Accounts you own
			</Typography>

			<div className={classes.accountList}>
				{owned.map(({ id, ...rest }) => (
					<Typography
						component={UILink}
						href="#"
						variant="button"
						color="primary"
						key={id}
						onClick={onAccountUrlClick(id)}
					>
						{rest.name}
					</Typography>
				))}
			</div>
		</Grid>
	);
};

const WarningDialog = ({ open, onClose, onConfirm }) => {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			color="primary"
		>
			<DialogTitle id="alert-dialog-title">
				Delete Player account?
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Your player account does not contain an email address!
					<br />
					&nbsp;
					<br />
					Logging out will cause the account to be deleted and all
					player data will be lost. Consider adding an email address
					to the account to save your information.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onConfirm} color="primary">
					Log out anyway
				</Button>
				<Button
					onClick={onClose}
					variant="contained"
					color="primary"
					autoFocus
				>
					Take me back
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const UpdateEmailForm = () => {
	const user = useCurrentUser();
	const classes = useStyles();
	const [email, setEmail] = useState(user.email);
	const [password, setPassword] = useState('');
	const [error, setError] = useState(null);
	const [submitState, setSubmitState] = useState('unsubmitted');
	const {
		updateEmail,
		linkUserWithEmailAndPassword,
		reauthenticate,
		currentUser: authUser,
	} = useAuth();
	const mounted = useMountedRef();

	const wasAnonymous = useRef(authUser.isAnonymous).current;

	const onlyPasswordProvider =
		authUser.providerData?.length === 1 &&
		authUser.providerData[0].providerId === 'password';
	const hasPasswordField = authUser.isAnonymous || onlyPasswordProvider;
	const onSubmit = async (e) => {
		e.preventDefault();

		// don't call the API if the email is not valid
		if (!isEmail(email)) {
			setError(new Error('Email is not valid'));
			return;
		} else {
			// clean the error from an earlier state
			setError(null);
		}

		try {
			setSubmitState('submitting');
			if (wasAnonymous) {
				await linkUserWithEmailAndPassword(
					email,
					password,
					window.location.href,
				);
			} else {
				password && (await reauthenticate(user.email, password));
				await updateEmail(email, window.location.href);
			}
			mounted.current && setSubmitState('submitted');
		} catch (e) {
			registerError(e);
			mounted.current && setSubmitState('unsubmitted');
			mounted.current && setError(e);
		}
	};
	return (
		<form onSubmit={onSubmit}>
			<Typography
				className={classes.sectionTitle}
				component="h5"
				variant="h5"
				align="left"
			>
				{wasAnonymous ? (
					<>
						Set your email and password
						<br />
						to avoid losing your game history
					</>
				) : (
					'Update your email address'
				)}
			</Typography>
			{submitState === 'submitted' ? (
				wasAnonymous ? (
					<div>
						Almost done! Please verify your email address to create
						your account.
					</div>
				) : (
					<div>
						Almost done! Please verify your new email address to
						finish updating it.
					</div>
				)
			) : (
				<>
					<ErrorMessage error={error} />
					<TextField
						className={classes.fullWidth}
						label="Email"
						type="email"
						defaultValue={user.email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					{hasPasswordField && (
						<TextField
							label="Password"
							type="password"
							autoComplete={
								wasAnonymous
									? 'new-password'
									: 'current-password'
							}
							onChange={(e) => setPassword(e.target.value)}
						/>
					)}
					<Button disabled={submitState === 'submitting'}>
						{wasAnonymous
							? 'Set my details'
							: 'Update my email address'}
					</Button>
				</>
			)}
		</form>
	);
};

const ChangePasswordForm = () => {
	const user = useCurrentUser();
	const classes = useStyles();
	const [newPassword, setNewPassword] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState(null);
	const [submitState, setSubmitState] = useState('unsubmitted');
	const { updatePassword, reauthenticate, currentUser: authUser } = useAuth();
	const mounted = useMountedRef();

	const onlyPasswordProvider =
		authUser.providerData?.length === 1 &&
		authUser.providerData[0].providerId === 'password';
	const onSubmit = async (e) => {
		e.preventDefault();
		try {
			setSubmitState('submitting');
			onlyPasswordProvider &&
				(await reauthenticate(user.email, password));
			await updatePassword(newPassword);
			mounted.current && setSubmitState('submitted');
		} catch (e) {
			registerError(e);
			mounted.current && setSubmitState('unsubmitted');
			mounted.current && setError(e);
		}
	};
	return (
		<form onSubmit={onSubmit}>
			<Typography
				className={classes.sectionTitle}
				component="h5"
				variant="h5"
				align="left"
			>
				Change your password
			</Typography>
			{submitState === 'submitted' ? (
				<div>Your password has been updated!</div>
			) : (
				<>
					<ErrorMessage error={error} />
					{onlyPasswordProvider && (
						<TextField
							className={classes.fullWidth}
							label="Current password"
							type="password"
							autoComplete="current-password"
							onChange={(e) => setPassword(e.target.value)}
						/>
					)}
					<TextField
						className={classes.fullWidth}
						label="New password"
						type="password"
						autoComplete="new-password"
						onChange={(e) => setNewPassword(e.target.value)}
					/>
					<Button disabled={submitState === 'submitting'}>
						Change my password
					</Button>
				</>
			)}
		</form>
	);
};

export default function Profile() {
	const user = useCurrentUser();
	const history = useHistory();
	const classes = useStyles();
	const {
		updateDisplayName,
		getSocialLoginStatuses,
		currentUser: authUser,
	} = useAuth();

	const [displayName, setDisplayName] = useState(user.displayName);
	const [showLogoutDialog, setShowLogoutDialog] = useState(false);
	const [socialError, setSocialError] = useState(null);
	const [greeting, setGreeting] = useState(
		greetings[Math.floor(Math.random() * greetings.length)],
	);

	const [socialLoginStatuses, setSocialLoginStatuses] = useState(
		getSocialLoginStatuses,
	);
	// handle signout of the application
	const handleSignOut = () => {
		history.push('/logout');
	};
	// handle a click of the logout button
	const handleLogOutButton = () => {
		if (!authUser.isAnonymous) {
			handleSignOut();
		} else {
			setShowLogoutDialog(true);
		}
	};
	const handleClose = () => {
		setShowLogoutDialog(false);
	};

	const updateGreeting = () => {
		// console.log('updateGreeting');
		const newGreeting =
			greetings[Math.floor(Math.random() * greetings.length)];
		if (newGreeting !== greeting) {
			return setGreeting(newGreeting);
		} else {
			return updateGreeting();
		}
	};

	const onSubmitDisplayNameChange = async () => {
		if (displayName.length > 3 && displayName.length < 50) {
			if (displayName !== user.displayName) {
				await updateDisplayName(displayName);
			}
			return true;
		}
	};

	return (
		<>
			<DocumentHead title="User profile" />
			<Grid container direction="column" spacing={1}>
				<Grid item xs={12} className={classes.profileHeader}>
					<UserAvatar
						withName={false}
						user={user}
						className={classes.profilePhoto}
					/>
					<EditableField
						label="Display name"
						value={displayName}
						inputClassName={classes.displayNameInput}
						onChange={(e) => setDisplayName(e.target.value)}
						onSubmit={onSubmitDisplayNameChange}
					>
						{({ startEditing }) => (
							<Typography
								component="h5"
								variant="h5"
								align="center"
								className={classes.displayName}
							>
								{user.displayName}{' '}
								<EditIconButton onClick={startEditing} />
							</Typography>
						)}
					</EditableField>
					<div className={classes.bubble} onClick={updateGreeting}>
						<p className={classes.bubbleP}>{greeting}</p>
					</div>
				</Grid>
				<UserAccountsList />
				<Grid item xs={12} className={classes.profileBody}>
					<UpdateEmailForm />
				</Grid>
				{authUser.isAnonymous ? null : (
					<Grid item xs={12} className={classes.profileBody}>
						<ChangePasswordForm />
					</Grid>
				)}
				<Grid item xs={12} className={classes.profileBody}>
					<Typography
						className={classes.sectionTitle}
						component="h5"
						variant="h5"
						align="left"
					>
						Set up login providers
					</Typography>
					<SocialErrorMessage error={socialError} />
					{Object.entries(getSocialLoginStatuses()).map(
						([providerID, isLinked]) => {
							const provider =
								providerID === GOOGLE
									? 'Google'
									: providerID === FACEBOOK
									? 'Facebook'
									: 'Unknown provider';
							return (
								<SocialLoginButton
									key={providerID}
									aria-label={
										isLinked
											? `Your ${provider} account is linked. Click to unlink it.`
											: `Link your ${provider} account.`
									}
									className={
										isLinked
											? classes.linkedProvider
											: classes.unlinkedProvider
									}
									provider={providerID}
									mode={isLinked ? 'unlink' : 'link'}
									setError={setSocialError}
									onComplete={() =>
										setSocialLoginStatuses(
											getSocialLoginStatuses(),
										)
									}
								/>
							);
						},
					)}
				</Grid>
				<Grid item xs={12} className={classes.profileBody}>
					<Button onClick={handleLogOutButton}>Log out</Button>
				</Grid>
			</Grid>
			<div className={classes.supportInfo}>ID: {user.uid}</div>
			<WarningDialog
				open={showLogoutDialog}
				onClose={handleClose}
				onConfirm={handleSignOut}
			/>
		</>
	);
}
