export function ensureExists<T>(value: T | null | undefined): NonNullable<T> {
	if (value == null) {
		throw new TypeError(
			`value should be defined, but got "${String(value)}"`,
		);
	}
	return value as NonNullable<T>;
}

type Falsy = false | null | undefined | 0 | '';

export function ensureTruthy<T>(value: T | Falsy): T {
	if (!value) {
		throw new TypeError(
			`value should be truthy, but got "${String(value)}"`,
		);
	}
	return value;
}

export function ensure(condition: unknown): asserts condition {
	if (!condition) {
		throw new TypeError(
			`condition should be met, but got "${String(condition)}"`,
		);
	}
}
