// NOTE: Just a fun experiment, let's see where this might bring us
// and if there is a real value to this _stretch_, TimeOfDay can be just a string

import { Joi, ValueTypesOf } from '../shared';

const hours = [
	'00',
	'01',
	'02',
	'03',
	'04',
	'05',
	'06',
	'07',
	'08',
	'09',
	'10',
	'11',
	'12',
	'13',
	'14',
	'15',
	'16',
	'17',
	'18',
	'19',
	'20',
	'21',
	'22',
	'23',
	'24',
] as const;

type Hour = ValueTypesOf<typeof hours>;

const minutes = [
	'00',
	'01',
	'02',
	'03',
	'04',
	'05',
	'06',
	'07',
	'08',
	'09',
	'10',
	'11',
	'12',
	'13',
	'14',
	'15',
	'16',
	'17',
	'18',
	'19',
	'20',
	'21',
	'22',
	'23',
	'24',
	'25',
	'26',
	'27',
	'28',
	'29',
	'30',
	'31',
	'32',
	'33',
	'34',
	'35',
	'36',
	'37',
	'38',
	'39',
	'40',
	'41',
	'42',
	'43',
	'44',
	'45',
	'46',
	'47',
	'48',
	'49',
	'50',
	'51',
	'52',
	'53',
	'54',
	'55',
	'56',
	'57',
	'58',
	'59',
] as const;

type Minute = ValueTypesOf<typeof minutes>;

export type TimeOfDay = `${Hour}:${Minute}`;

export const parseTimeOfDay = (timeOfDay: TimeOfDay) => {
	const [hour, minute] = timeOfDay.split(':');
	return {
		hour: Number(hour),
		minute: Number(minute),
	};
};

export const timesOfDayTypes = [
	// TODO: Add more
	'every-hour-on-the-hour',
] as const;

/**
 * A helper which identifies how an array of @see TimeOfDay was constructed
 */
export type TimesOfDayType = ValueTypesOf<typeof timesOfDayTypes>;

export const timesOfDayTypeSchema = Joi.string().valid(...timesOfDayTypes);

export const timeOfDaySchema = Joi.string().regex(
	/([0-1][0-9]|2[0-4]):[0-5][0-9]/,
);
