import React from 'react';
import { Select, MenuItem } from '@material-ui/core';

export const CircleSwitcher = ({
	selectedAccountID,
	accounts,
	onChange,
	...rest
}) => {
	const handleSelect = (e) => {
		const value = e.target.value;
		if (value === '__none') {
			return;
		} else {
			onChange({
				account: accounts.find((a) => a.id === value),
				isNew: false,
			});
		}
	};

	if (!accounts || accounts.length === 0) {
		return null; // no accounts
	}

	return (
		<>
			<Select
				{...rest}
				value={selectedAccountID || '__none'}
				onChange={handleSelect}
			>
				<MenuItem value="__none">Select a circle...</MenuItem>
				{accounts.map((a) => (
					<MenuItem key={a.id} value={a.id}>
						<img src={a.avatarUrl} alt="" />
						{a.name}
					</MenuItem>
				))}
			</Select>
		</>
	);
};
