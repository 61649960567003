import { appName } from './appName';
import { envNameFromHostname } from './envNameFromHostname';
import { createFirebaseConfig, FirebaseConfig } from './firebaseConfigs';
import { isDevBuild } from './isDevBuild';
import { EnvName, envNames } from './types';
import { UrlConfig, urlsByEnv } from './urls';

export type EnvConfig = {
	name: EnvName;
	firebaseConfig: FirebaseConfig;
	// specifies auth function used for cross-domain auth - only used
	// during development when pointing to prod/staging from local environment
	dev_authFunctionsBaseUrl?: string;
} & UrlConfig;

const defaultEnvName = (): EnvName => {
	const hostname = global.window.location.hostname;
	const envName = envNameFromHostname(hostname);
	return envName;
};

const currentEnvName = (): EnvName => {
	if (isDevBuild()) {
		// only allow changing environment during development
		// for testing hot-fixes and backward compatibility
		const envName =
			process.env['REACT_APP_ENV'] ||
			global.localStorage.getItem('environment');
		if (envName && envNames.includes(envName)) {
			return envName as EnvName;
		}
	}
	return defaultEnvName();
};

export const env = (): EnvConfig => {
	const envName = currentEnvName();
	const currentAppName = appName();
	const firebaseConfig = createFirebaseConfig(currentAppName, envName);
	return {
		name: envName,
		firebaseConfig,
		...urlsByEnv(envName),
		...(currentEnvName() !== defaultEnvName() &&
			isDevBuild() && {
				dev_authFunctionsBaseUrl: firebaseConfig.functionsBaseUrl,
			}),
	};
};
