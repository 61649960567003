import React from 'react';
import {
	Button,
	createStyles,
	Divider,
	Grid,
	makeStyles,
	MenuItem,
	Typography,
} from '@material-ui/core';
import { useCurrentUser } from '../../hooks';
import { useCurrentAccount } from '../../contexts';
import { useHistory } from 'react-router-dom';
import { UserAvatar } from '../userAvatar';
import clsx from 'clsx';

const useStyles = makeStyles((theme) =>
	createStyles({
		profileMenu: {
			padding: theme.spacing(0, 2),
			minWidth: `35ch`,
			[theme.breakpoints.up('sm')]: {
				maxWidth: `40ch`,
			},
		},
		profileButtons: {
			// Using half-spacing end's up as 1 spacing when buttons stuck together
			margin: theme.spacing(0.5, 0),
		},
		sectionProfile: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		userAvatar: {
			margin: '0 auto',
		},
		profileData: {
			width: '100%',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			textAlign: 'center',
		},
		userDisplayName: {
			marginTop: theme.spacing(1),
		},
		userEmail: {
			marginBottom: '0.5rem',
		},
		divider: {
			margin: theme.spacing(1, 0),
		},
		circleList: {
			minHeight: '8rem',
		},
		// TODO: Use List and ListSelectedItem, stop using colors
		activeAccount: {
			background: 'rgba(196, 196, 196, 0.25)',
		},
	}),
);

type Props = {
	close: () => void;
};

export const ProfileMenu: React.ComponentType<Props> = ({ close }) => {
	const user = useCurrentUser();
	const classes = useStyles();
	const history = useHistory();
	const { setCurrentAccount, currentAccount, accounts } = useCurrentAccount();

	const handleClose = () => {
		close && close();
	};

	const handleSettings = () => {
		handleClose();
		history.push('/profile');
	};

	const handleCreate = () => {
		handleClose();
		history.push('/create-account');
	};

	const handleLogout = () => {
		handleClose();
		history.push('/logout');
	};

	const selectAccount = (id: string) => {
		handleClose();
		setCurrentAccount(id);
	};

	const isOwner = (account: { owner: string }) => {
		return account.owner === user.uid;
	};

	const buttonStyles: React.ComponentProps<typeof Button> = {
		className: classes.profileButtons,
		size: 'small',
		variant: 'outlined',
		fullWidth: true,
	};

	return (
		<div className={classes.profileMenu}>
			<div className={classes.sectionProfile}>
				<UserAvatar
					withName={false}
					orientation="horizontal"
					user={user}
					className={classes.userAvatar}
					size="4rem"
				/>
				<Typography
					variant="h3"
					className={clsx(
						classes.profileData,
						classes.userDisplayName,
					)}
				>
					{user.displayName}
				</Typography>
				<Typography
					variant="subtitle2"
					className={clsx(classes.profileData, classes.userEmail)}
				>
					{user.email}
				</Typography>
				<Button onClick={handleSettings} {...buttonStyles}>
					Manage profile
				</Button>
				<Button onClick={handleLogout} {...buttonStyles}>
					Logout
				</Button>
			</div>
			{currentAccount && (
				<>
					<Divider className={classes.divider} />
					<div>
						<Typography component="h4">Your Circles</Typography>
						<div className={classes.circleList}>
							{accounts?.map((a) => (
								<MenuItem
									key={a.id}
									value={a.id}
									className={
										a.id === currentAccount.id
											? classes.activeAccount
											: ''
									}
									onClick={() => selectAccount(a.id)}
								>
									<img src={a.avatarUrl} alt="" />

									<Grid container direction="column">
										<Typography>{a.name}</Typography>
										<Grid container direction="row">
											<Grid item xs={11}>
												{isOwner(a) && (
													<Typography
														variant="caption"
														component="span"
													>
														(Owner)
													</Typography>
												)}
											</Grid>
										</Grid>
									</Grid>
								</MenuItem>
							))}
						</div>
						<Button onClick={handleCreate} {...buttonStyles}>
							Create new Circle
						</Button>
					</div>
				</>
			)}
		</div>
	);
};
