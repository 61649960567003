import React, { useState } from 'react';
import SocialLogin from './SocialLogin';

import { Link, Button, RemoteSocialLogo, DocumentHead } from '../../components';
import { useAuth, useCurrentUser, useMountedRef } from '../../hooks';

// router
import { useHistory, useLocation } from 'react-router-dom';

// material-ui
import { Grid, Typography } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ErrorMessage } from '../../components/errorMessage';
import { isEmail } from '../../utils/validation';
import { registerError } from '../../errors';

// material-ui styles
const useStyles = makeStyles((theme) => ({
	brand: {
		height: '3rem',
		fill: theme.palette.common.white,
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},

	loginBlurb: {
		marginTop: theme.spacing(2),
		fontSize: '0.8rem',
	},
}));

// login component
export default function Register() {
	// connect to dispatch
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();
	const auth = useAuth();
	const user = useCurrentUser();

	const mounted = useMountedRef();

	const [{ generalError, emailError, passwordError }, setUpgradeError] =
		useState({});

	// State
	const [Loading, setLoading] = React.useState(false);
	const [credentials, setCredentials] = React.useState({
		email: user.email || '',
		password: '',
	});

	const nextUrl = (location.state && location.state.from) || '/profile';

	// update credentials with current value
	const handleChange = (event) => {
		setCredentials({
			...credentials,
			[event.target.id]: event.target.value,
		});
	};

	// dispatch the credentials to Firebase Auth
	const handleSubmit = async (event) => {
		event.preventDefault && event.preventDefault();

		// don't call the API if the email is not valid
		if (!isEmail(credentials.email)) {
			setUpgradeError({
				emailError: 'Email is not valid',
			});
			return;
		} else {
			// clean the error from an earlier state
			setUpgradeError({});
		}

		try {
			setLoading(true);
			setUpgradeError({});
			await auth.linkUserWithEmailAndPassword(
				credentials.email,
				credentials.password,
			);
			mounted.current && nextUrl && history.push(nextUrl);
		} catch (error) {
			if (mounted.current) {
				switch (error.code) {
					case 'auth/invalid-email':
						setUpgradeError({
							emailError: 'Please enter a valid email address.',
						});
						break;
					case 'auth/wrong-password':
					case 'auth/email-already-in-use':
						setUpgradeError({
							emailError:
								'A user already exists with this email address.',
						});
						break;
					case 'auth/weak-password':
						setUpgradeError({
							passwordError:
								'Please use a password with 6 characters or more.',
						});
						break;
					default: {
						registerError(error);
						setUpgradeError({
							generalError:
								'Something went wrong. (code: ' +
								error.code +
								')',
						});
					}
				}
			}
			console.log(error);
		} finally {
			mounted.current && setLoading(false);
		}
	};

	// render the login form
	return (
		<Grid container direction="column" spacing={2}>
			<DocumentHead title="Upgrade account" />
			<Grid item xs={12} align="center">
				<RemoteSocialLogo className={classes.brand} />
				<Typography
					component="h5"
					variant="h5"
					align="center"
					className={classes.loginTitle}
				>
					CREATE HOST ACCOUNT WITH
				</Typography>
			</Grid>
			<SocialLogin mode="link" nextUrl={nextUrl} />
			<Grid item xs={12}>
				<Typography
					component="p"
					variant="body1"
					align="left"
					className={classes.loginBlurb}
				>
					In order to create your own games you will need to add an
					email address and password to your account
				</Typography>
				<form
					className={classes.form}
					noValidate
					onSubmit={handleSubmit}
				>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						type="email"
						id="email"
						label="Email address"
						name="email"
						value={credentials.email}
						autoFocus
						onChange={handleChange}
						disabled={Boolean(user.email)}
						error={emailError}
						helperText={emailError}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						type="password"
						id="password"
						label="Password"
						name="password"
						error={passwordError}
						helperText={passwordError}
						onChange={handleChange}
					/>
					<ErrorMessage error={generalError} />
					<Button
						click={handleSubmit}
						text="Create host account"
						loading={Loading}
					/>
				</form>
			</Grid>
			<Grid item container direction="row">
				<Grid item align="center" xs={12}>
					<Link to="/login">Already have an account? Login here</Link>
				</Grid>
			</Grid>
		</Grid>
	);
}
