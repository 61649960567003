import React from 'react';
import { Redirect } from 'react-router-dom';
import {
	AuthenticatedRoute,
	UnAuthenticatedRoute,
	AuthenticatedHostRoute,
} from '../../routes';
import Auth from './Auth';
import Logout from './Logout';
import SelectAccount from './SelectAccount';
import { SelectCircle } from './selectCircle';

function getAuthRoutes() {
	return [
		<Redirect
			key="/register/host"
			exact
			path="/register/host"
			to="/register"
		/>,
		<AuthenticatedRoute
			key="/logout"
			path="/logout"
			variant="Logout"
			component={Logout}
			redirectToPath="/login"
			containerVariant="single-column"
		/>,
		<AuthenticatedRoute
			key="/select-account"
			path="/select-account/:gameType"
			component={Auth}
			variant="SelectAccount"
			redirectToPath="/"
			containerVariant="single-column"
		/>,
		<AuthenticatedRoute
			key="/select-circle"
			path="/select-circle"
			component={Auth}
			variant="SelectCircle"
			redirectToPath="/"
			containerVariant="single-column"
		/>,
		<AuthenticatedHostRoute
			key="/create-first-account"
			path="/create-first-account"
			component={Auth}
			variant="Create"
			redirectToPath="/verify-email"
			firstAccount={true}
		/>,
		<AuthenticatedHostRoute
			key="/create-account"
			path="/create-account"
			component={Auth}
			variant="Create"
			redirectToPath="/verify-email"
		/>,
		<AuthenticatedRoute
			key="/verify-email"
			path="/verify-email"
			component={Auth}
			variant="VerifyEmail"
			redirectToPath="/"
		/>,
		<AuthenticatedRoute
			key="/welcome"
			path="/welcome"
			component={Auth}
			variant="Welcome"
			redirectToPath="/"
		/>,
		<UnAuthenticatedRoute
			key="/onboard"
			path="/onboard"
			component={Auth}
			variant="Onboard"
			blocked={true}
		/>,
		<UnAuthenticatedRoute
			key="/handle-invite"
			path="/handle-invite"
			component={Auth}
			variant="HandleInvite"
			blocked={false}
		/>,
		<UnAuthenticatedRoute
			key="/login"
			path="/login"
			component={Auth}
			variant="Login"
			blocked={true}
		/>,
		<UnAuthenticatedRoute
			key="/reset-password"
			path="/reset-password"
			component={Auth}
			variant="ResetPassword"
			containerVariant="single-column"
		/>,
		<UnAuthenticatedRoute
			key="/register"
			path="/register"
			component={Auth}
			variant="Register"
			blocked={true}
		/>,
		<AuthenticatedRoute
			key="/register/upgrade"
			path="/register/upgrade"
			component={Auth}
			variant="RegisterUpgrade"
			redirectToPath="/register"
		/>,
		<AuthenticatedRoute
			key="/profile"
			path="/profile"
			component={Auth}
			variant="Profile"
			redirectToPath="/login"
			containerVariant="single-column"
		/>,
		<UnAuthenticatedRoute
			key="/handle-link"
			path="/handle-link"
			component={Auth}
			variant="HandleLink"
			containerVariant="single-column"
		/>,
	];
}

export { Auth, SelectAccount, SelectCircle, getAuthRoutes };
