import React, { useEffect } from 'react';

// material-ui
import { Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import { contentAnimation, gifAnimation } from './animation';
import { useStyles } from './OnboardingStyle';
import { useLocation } from 'react-router-dom';

const TIMEOUT = 4 * 1000;

export default function Welcome() {
	const classes = useStyles();
	const contentSpring = useSpring(contentAnimation);
	const gifSpring = useSpring(gifAnimation);
	const history = useHistory();
	const location = useLocation();
	const nextUrl = (location.state && location.state.from) || '/';

	useEffect(() => {
		const redirectTimeout = setTimeout(() => {
			nextUrl && history.push(nextUrl);
		}, TIMEOUT);
		return function cleanup() {
			clearTimeout(redirectTimeout);
		};
	}, [nextUrl, history]);

	return (
		<Grid container spacing={2} className={classes.welcomeContainer}>
			<animated.div
				style={gifSpring}
				className={classes.welcomVideoContainer}
			>
				<Grid
					className={classes.welcomVideoContainer}
					item
					xs={12}
					container
					direction="column"
					justifyContent="center"
					alignItems="center"
				>
					<img
						className={classes.welcomeVideo}
						alt="Welcome"
						src="https://storage.googleapis.com/remotesocial-io-asset-storage/hub/img/Welcome.gif"
					/>
					<img
						className={classes.welcomeVector}
						alt=""
						src="https://storage.googleapis.com/remotesocial-io-asset-storage/hub/img/welcomeVector.png"
					/>
				</Grid>
			</animated.div>
			<Grid item xs={12} align="center">
				<animated.div style={contentSpring}>
					<Typography
						component="h3"
						variant="h3"
						align="center"
						className={classes.welcomeTitle}
					>
						Welcome to Remote Social
					</Typography>
				</animated.div>
			</Grid>
		</Grid>
	);
}
