import React, { useState, useEffect } from 'react';
import {
	Select,
	MenuItem,
	FormControl,
	FormGroup,
	FormControlLabel,
	Checkbox,
	makeStyles,
	TextField,
	Typography,
} from '@material-ui/core';
import Autocomplete, {
	createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { getTimeZones } from '@vvo/tzdb';
// TODO: Do not import from functions!
import { scheduleBuilder } from '../../../functions/src/shared/scheduling';
import clsx from 'clsx';

const prettyHour = (h) => padTime(h) + ':00';
//(h) => (h > 12 ? h - 12 : h) + (h > 11 ? 'pm' : 'am');
const padTime = (n) => n.toString().padStart(2, '0');

const useStyles = makeStyles((theme) => ({
	weekdayControl: {
		display: 'flex',
	},
	weekdayGroup: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		'& > .MuiFormControlLabel-root': {
			margin: theme.spacing('auto', 1),
			'&:first-child': {
				marginLeft: 0,
			},
			'&:last-child': {
				marginRight: 0,
			},
		},
	},
	weekdayLabel: {
		fontWeight: 700,
	},
	weekdayCheckbox: {
		color: theme.palette.common.white,
		'&.Mui-checked': {
			color: theme.palette.common.white,
		},
	},
	selectTiming: {
		'& > .MuiSelect-root': {
			marginRight: theme.spacing(1),
		},
	},
	[theme.breakpoints.up('md')]: {
		selectHour: {
			minWidth: 100,
		},
		selectTimezone: {
			minWidth: 210,
		},
	},
	schedulingFieldRow: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		marginTop: theme.spacing(2),
	},
	schedulingField: {
		marginRight: theme.spacing(3),
	},
	schedulingFieldTitle: {
		marginBottom: theme.spacing(1),
		fontWeight: 'bold',
		fontSize: theme.typography.fontSize - 2,
	},
}));

const weekdays = [
	['Mon', 'MON'],
	['Tue', 'TUE'],
	['Wed', 'WED'],
	['Thu', 'THU'],
	['Fri', 'FRI'],
	['Sat', 'SAT'],
	['Sun', 'SUN'],
];
const SelectWeekdays = ({ value, defaultValue, onChange }) => {
	const classes = useStyles();
	const [checkedDays, setCheckedDays] = useState(
		defaultValue || ['MON', 'TUE', 'WED', 'THU', 'FRI'],
	);

	const _value = value || checkedDays;
	const _onChange = (e) => {
		setCheckedDays((value) =>
			e.target.checked === value.includes(e.target.name)
				? // value already reflects this
				  value
				: e.target.checked
				? value.concat(e.target.name)
				: value.filter((v) => v !== e.target.name),
		);
		onChange && onChange(e);
	};
	return (
		<FormControl
			required
			component="fieldset"
			className={classes.weekdayControl}
		>
			<FormGroup className={classes.weekdayGroup} aria-label="days">
				{weekdays.map(([label, name]) => (
					<FormControlLabel
						key={name}
						control={
							<Checkbox
								name={name}
								className={classes.weekdayCheckbox}
								checked={_value.includes(name)}
								onChange={_onChange}
							/>
						}
						label={
							<Typography
								variant="body2"
								className={classes.weekdayLabel}
							>
								{label}
							</Typography>
						}
						labelPlacement="top"
					/>
				))}
			</FormGroup>
		</FormControl>
	);
};

const timeZones = getTimeZones();
const findTZ = (name) => timeZones.find((tz) => tz.name === name);
const guessCurrentTimezone = () => {
	const currOffset = new Date().getTimezoneOffset();
	// which places have their current offset?
	const possibleZones = timeZones.filter(
		(tz) => tz.currentTimeOffsetInMinutes === -currOffset,
	);
	if (possibleZones.length === 0) {
		// we got nothing
		return 'America/Los_Angeles';
	}
	if (possibleZones.length === 1) {
		// only one option
		return possibleZones[0].name;
	}
	// try to match the timezone name
	const currTZ = /\(([^)]+)\)/.exec(new Date().toString())?.[1];
	const matchingZones = possibleZones.filter((tz) =>
		currTZ.startsWith(tz.alternativeName.replace(/ Time$/, '')),
	);
	return (matchingZones[0] || possibleZones[0]).name;
};
// Allows users to search by cities that aren't the first major city.
const timezoneFilterOptions = createFilterOptions({
	stringify: (tz) => tz.currentTimeFormat + ' ' + tz.abbbreviation,
});
const SelectTimezone = ({
	value,
	defaultValue = guessCurrentTimezone,
	onChange,
	className,
	label = 'Timezone',
}) => {
	const classes = useStyles();
	const [tz, setTimezone] = useState(() => {
		let provided =
			typeof defaultValue === 'function' ? defaultValue() : defaultValue;
		return findTZ(provided) || timeZones[0];
	});
	const selected = (value && timeZones.find((tz) => tz.name === value)) || tz;
	return (
		<Autocomplete
			className={clsx(classes.selectTimezone, className)}
			value={selected}
			filterOptions={timezoneFilterOptions}
			onChange={(e, newTZ) => (
				setTimezone(newTZ), onChange(newTZ && newTZ.name)
			)}
			options={timeZones}
			getOptionLabel={(tz) => `${tz.mainCities[0]} (${tz.abbreviation})`}
			renderInput={(params) => (
				<TextField {...params} label={label} variant="outlined" />
			)}
		/>
	);
};

const SchedulingField = ({ title, children }) => {
	const classes = useStyles();
	return (
		<div className={classes.schedulingField}>
			<Typography
				variant="body2"
				className={classes.schedulingFieldTitle}
			>
				{title}
			</Typography>
			{children}
		</div>
	);
};

export const CreateSchedule = ({
	defaultDaysOfWeek = ['MON', 'TUE', 'WED', 'THU', 'FRI'],
	defaultTimezone = guessCurrentTimezone,
	defaultStartHour = 9,
	defaultEndHour = 17,
	defaultMinute = 0,
	defaultIntervalHours = 1,
	onChange,
}) => {
	const classes = useStyles();
	const [start, setStart] = useState(defaultStartHour);
	const [end, setEnd] = useState(defaultEndHour);
	const [intervalHours, setIntervalHours] = useState(defaultIntervalHours);
	const [minute, setMinute] = useState(defaultMinute);
	const [emitCounter, setEmitCounter] = useState(0);

	const [daysOfWeek, setDaysOfWeek] = useState(defaultDaysOfWeek);
	const toggleWeekday = (e) =>
		setDaysOfWeek((value) =>
			e.target.checked === value.includes(e.target.name)
				? // value already reflects this
				  value
				: e.target.checked
				? value.concat(e.target.name)
				: value.filter((v) => v !== e.target.name),
		);

	const [tz, setTimezone] = useState(defaultTimezone);

	const emitChange = () => {
		const timesOfDay = [];
		const startHour = start + (minute < 0 ? -1 : 0);
		const endHour = end + (minute < 0 ? -1 : 0);
		const minuteStr = ':' + padTime(minute < 0 ? 60 + minute : minute);
		let h = startHour;
		while (h < endHour || (h === startHour && h === endHour)) {
			timesOfDay.push(padTime(h) + minuteStr);
			h += intervalHours;
		}
		onChange(
			scheduleBuilder(tz)
				.recurs('WEEKLY')
				.on(daysOfWeek)
				.at(timesOfDay)
				.build(),
			{
				startHour: start,
				endHour: end,
				minute,
				intervalHours,
				daysOfWeek,
				timezone: tz,
			},
		);
	};
	const andEmitChange =
		(fn) =>
		(...arg) => {
			fn(...arg);
			setEmitCounter((i) => i + 1);
		};

	useEffect(emitChange, [emitCounter]);

	return (
		<div>
			<SelectWeekdays
				value={daysOfWeek}
				onChange={andEmitChange(toggleWeekday)}
			/>

			<div className={classes.schedulingFieldRow}>
				<SchedulingField title="every">
					<Select
						className={classes.selectTiming}
						variant="outlined"
						label="Interval"
						value={intervalHours}
						onChange={andEmitChange((e) =>
							setIntervalHours(e.target.value),
						)}
					>
						{[
							['1 hour', 1],
							['2 hours', 2],
							['3 hours', 3],
							['4 hours', 4],
						].map(([label, value]) => (
							<MenuItem key={value} value={value}>
								{label}
							</MenuItem>
						))}
					</Select>
				</SchedulingField>
				<SchedulingField title={'\u00A0'}>
					<Select
						className={classes.selectTiming}
						variant="outlined"
						label="Offset"
						value={minute}
						onChange={andEmitChange((e) =>
							setMinute(e.target.value),
						)}
					>
						{[
							['on the hour', 0],
							['at quarter past', 15],
							['at half past', 30],
							['at quarter to', 45],
							['at five to', 55],
						].map(([name, m]) => {
							const padded = padTime(m < 0 ? 60 + m : m);
							return (
								<MenuItem key={name} value={m}>
									{name}
								</MenuItem>
							);
						})}
					</Select>
				</SchedulingField>
			</div>
			<div className={classes.schedulingFieldRow}>
				<SchedulingField title="from">
					<Select
						className={classes.selectHour}
						variant="outlined"
						label="Start time"
						value={start}
						onChange={andEmitChange((e) =>
							setStart(e.target.value),
						)}
					>
						{[7, 8, 9, 10, 11, 12, 13].map((h) => (
							<MenuItem key={h} value={h}>
								{prettyHour(h)}
							</MenuItem>
						))}
					</Select>
				</SchedulingField>
				<SchedulingField title="to">
					<Select
						className={classes.selectHour}
						variant="outlined"
						label="End time"
						value={end}
						onChange={andEmitChange((e) => setEnd(e.target.value))}
					>
						{[13, 14, 15, 16, 17, 18].map((h) => (
							<MenuItem key={h} value={h}>
								{prettyHour(h)}
							</MenuItem>
						))}
					</Select>
				</SchedulingField>
				<SchedulingField title="in">
					<SelectTimezone
						label=""
						value={tz}
						onChange={andEmitChange((tz) => setTimezone(tz))}
					/>
				</SchedulingField>
			</div>
		</div>
	);
};
