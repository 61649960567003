/* 
  Dynamic Color
  - designed to provide variable color results
  - based on different inputs
  eg: 
  - determine whether or not to use a 'light' or 'dark'
  - based on the background color of the parent element
  - starting with a baseline HSLA color:
    - darker colour has +saturation -lightness
    - lighter colour has -saturation +lightness
*/

// config constants
const hueVariance = 8; // +- shift in hue from baseline
const satVariance = 12; // +- shift in saturation from baseline
const lightVariance = 10; // +- shift in lightness from baseline
const luminanceThreshold = 50;

export type HslColor = {
	h: number;
	s: number;
	l: number;
};

export type HslaColor = HslColor & {
	a: number;
};

export type RgbColor = readonly [number, number, number];

// utility to find the modulo of 360
const mod = (n: number, m: number) => {
	const r = n % m;
	return Math.floor(r >= 0 ? r : r + m);
};

/**
 * Given a color value in HSLA return either a lighter or darker
 * shade using a predetermined formula that ensures the color is dark
 * enough to be seen in AAA
 */
function getColorShade(
	hsla: HslaColor,
	variant: 'dark' | 'light' = 'dark',
): HslaColor {
	const newHSLA = { ...hsla };

	if (variant === 'dark') {
		newHSLA.h = mod(hsla.h - hueVariance, 360); // hue rotated down (mod 360)
		newHSLA.s = Math.min(hsla.s + satVariance, 100); // saturation dialled up (max 100)
		newHSLA.l = Math.max(hsla.l - lightVariance, 0); // lightness dialled down (min 0)
	} else {
		newHSLA.h = mod(hsla.h + hueVariance, 360); // hue rotated up (mod 360)
		newHSLA.s = hsla.s; // saturation unchanged
		newHSLA.l = Math.min(hsla.l + lightVariance, 100); // lightness dialled up (max 100)
	}

	return newHSLA;
}

function getGreyShade(hsla: HslaColor, variant = 'dark'): HslaColor {
	const newHSLA = { ...hsla };
	if (variant === 'dark') {
		newHSLA.l = Math.max(hsla.l - lightVariance, 0); // lightness dialled down (min 0)
	} else {
		newHSLA.l = Math.min(hsla.l + lightVariance, 100); // lightness dialled up (max 100)
	}
	return newHSLA;
}

function hslToRgb({ h, s, l }: HslColor): RgbColor {
	let r, g, b;

	h = h / 360;
	s = s / 100;
	l = l / 100;

	if (s === 0) {
		// achromatic (greyscale)
		r = g = b = l;
	} else {
		function hue2rgb(p: number, q: number, t: number) {
			if (t < 0) t += 1;
			if (t > 1) t -= 1;
			if (t < 1 / 6) return p + (q - p) * 6 * t;
			if (t < 1 / 2) return q;
			if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
			return p;
		}
		const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
		const p = 2 * l - q;
		r = hue2rgb(p, q, h + 1 / 3);
		g = hue2rgb(p, q, h);
		b = hue2rgb(p, q, h - 1 / 3);
	}
	return [r, g, b] as const;
}

// convert an RGB value to a luminance score (0-100)
function luminanceForRgb(rgb: RgbColor) {
	const lum = 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2];
	return Math.floor(lum * 100);
}

// return either "light" or "dark"
// based on the luminance value of a provided hsla object
// {h:.. , s:.., l:.., a:.. }
function getContrastTheme(hsla: HslaColor) {
	const rgb = hslToRgb(hsla);
	const luminance = luminanceForRgb(rgb);
	const output = luminance <= luminanceThreshold ? 'dark' : 'light';
	return output;
}

// get a gradient color
function getGradientColor(left: string, right: string) {
	const gradientAngle = '45deg';
	const midpoint = '45%';
	return `linear-gradient(${gradientAngle}, ${left}, ${midpoint}, ${right})`;
}

// convert {h, s, l, a} object into an HSLA string e.g.;'hsla(180deg, 75%, 20%, 0.9)'
const makeHSLA = ({ h, s, l, a }: HslaColor) => {
	// material-ui cannot handle HSLA well and treats it as RGB sometimes leading
	// to weird color issues - like snackbar having a white text on white background
	if (a === 1) {
		return `hsl(${h}deg, ${s}%, ${l}%)`;
	}
	return `hsla(${h}deg, ${s}%, ${l}%, ${a})`;
};

const hslRegex =
	/^hsl\((-?[\d.]+(?:%|deg)?[,\s]+)(-?[\d.]+%?[,\s]+)(-?[\d.]+%?)\)$/;

const parseHSL = (hslaString: string): HslaColor | null => {
	const result = hslRegex.exec(hslaString);
	if (!result) {
		return null;
	}
	const [, h, s, l] = result;
	if (!h || !s || !l) {
		return null;
	}

	return {
		h: parseFloat(h),
		s: parseFloat(s),
		l: parseFloat(l),
		a: 1,
	};
};

const hslaRegex =
	/^hsla\((-?[\d.]+(?:%|deg)?[,\s]+)(-?[\d.]+%?[,\s]+)(-?[\d.]+%?[,\s]+)\s*\/*\s*([\d.]+)%?\)$/;

// convert HSLA string back into {h, s, l, a} numeric integer values
const parseHSLA = (hslaString: string): HslaColor | null => {
	const result = hslaRegex.exec(hslaString);
	if (!result) {
		return parseHSL(hslaString);
	}
	const [, h, s, l, a] = result;
	if (!h || !s || !l || !a) {
		return null;
	}

	return {
		h: parseFloat(h),
		s: parseFloat(s),
		l: parseFloat(l),
		a: parseFloat(a),
	};
};

// return a version of an hsla object with a new opacity (a) value
const applyOpacity = (hsla: HslaColor, opacity: number) => {
	return { ...hsla, a: opacity };
};

function changeOpacity(color: string, opacity: number) {
	if (typeof color !== 'string') {
		throw new Error('Invalid color value supplied');
	}
	const hsla = parseHSLA(color);
	if (!hsla) {
		if (color[0] === '#') {
			// convert to six digit notation from three-digits notation e.g. #RGB to #RRGGBB
			color =
				color.length < 5
					? '#' +
					  [1, 2, 3]
							.map((i) => String(color[i]) + String(color[i]))
							.join('')
					: color;
			return (
				color.substring(0, 7) + Math.floor(opacity * 255).toString(16)
			);
		}
		throw new Error('Could not change opacity of ' + color);
	}
	return makeHSLA(applyOpacity(hsla, opacity));
}

export {
	getColorShade,
	getGreyShade,
	getContrastTheme,
	getGradientColor,
	makeHSLA,
	parseHSLA,
	applyOpacity,
	changeOpacity,
};
