import React, { useState, useEffect } from 'react';
import { auth } from '../../utils';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth, useCurrentUser, useQuery } from '../../hooks';
import { Button, ErrorMessage } from '../../components';
import {
	Box,
	Container,
	CircularProgress,
	Typography,
	TextField,
	makeStyles,
} from '@material-ui/core';
import { isDevBuild } from '../../environment';
import { registerError } from '../../errors';

const dev = isDevBuild();
const enableLogs = true;
const debug = dev && enableLogs;

const useStyles = makeStyles((theme) => ({
	container: {},
	root: {
		display: 'flex',
		flexFlow: 'column wrap',
		minHeight: '296px',
	},
	title: {
		flexGrow: 0,
		marginBottom: '0.5rem',
	},
	result: {
		display: 'flex',
		flexFlow: 'column wrap',
		justifyContent: 'space-evenly',
		alignItems: 'center',
		flexGrow: 1,
	},
	action: {
		margin: '0.5rem',
	},
	img: {
		border: '1px solid black',
		borderRadius: '0.5rem',
		margin: '0.5rem 0',
		width: '100%',
	},
	loadingBox: {},
	loading: {
		color: 'inherit',
	},
	reset: {
		display: 'flex',
		flexFlow: 'column wrap',
		justifyContent: 'space-evenly',
		flexGrow: 1,
	},
	header: {
		textAlign: 'center',
	},
}));

function forcedRedirect(url) {
	window.location.href = url;
}

// if there is an error in any of the links this will render
const LinkError = () => {
	const classes = useStyles();

	return (
		<div className="root">
			<Typography variant="h3" align="center" className={classes.title}>
				Incorrect or missing link
			</Typography>
			<img
				src="https://cdn.remotesocial.io/hub/img/lost.gif"
				className={classes.img}
				alt="Lost?"
			/>
			<Typography
				variant="body1"
				className={classes.result}
				align="center"
			>
				The URL link you clicked is not correct. <br />
				Please check the link.
			</Typography>
		</div>
	);
};

const Loading = () => {
	const classes = useStyles();

	return (
		<Box className={classes.loadingBox}>
			<CircularProgress className={classes.loading} />
		</Box>
	);
};

const ResetPassword = ({ actionCode, continueUrl }) => {
	// the link will help us to retrieve a user
	// and then action a password reset for the user
	const [view, setView] = useState('loading');
	const [title, setTitle] = useState('Checking link');
	const [password, setPassword] = useState('');
	const [email, setEmail] = useState('');
	const [error, setError] = useState(null);
	const clientAuth = useAuth();
	const classes = useStyles();

	// handle the password field being edited
	const handleChange = (event) => {
		setPassword(event.target.value);
	};

	// handle submitting the new password
	const handleSubmit = async () => {
		try {
			await auth.confirmPasswordReset(actionCode, password);
			// log the user in here
			await clientAuth.login({ email, password });
			forcedRedirect(continueUrl);
		} catch (error) {
			console.error('error submitting password', error.message);
			throw error;
		}
	};

	// process the actioncode
	useEffect(() => {
		// convert an action code into an email address
		auth.verifyPasswordResetCode(actionCode)
			.then((email) => {
				debug && console.log('user email', email);
				setTitle('Create a new password');
				setView('createPassword');
				setEmail(email);
				setError(false);
			})
			.catch((error) => {
				registerError(error);
				debug && console.log('resetPassword error', error.message);
				setTitle('Error resetting password');
				setView('error');
				setError(error.message);
			});
	}, [actionCode]);

	const output = {
		loading: <Loading />,
		createPassword: (
			<>
				<div className={classes.action}>
					<Typography variant="h5" className={classes.header}>
						{email}
					</Typography>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						type="password"
						id="password"
						label="Password"
						name="password"
						value={password}
						onChange={handleChange}
					/>
					<Button
						variant="contained"
						color="primary"
						onClick={handleSubmit}
					>
						Update password
					</Button>
				</div>
			</>
		),
		error: (
			<>
				<Typography variant="body1">{error}</Typography>
				<Button variant="contained" href="/reset-password">
					Request a new link
				</Button>
			</>
		),
	};

	return (
		<div className={classes.root}>
			<Typography variant="h3" align="center" className={classes.title}>
				{title}
			</Typography>
			<div className={classes.reset}>{output[view]}</div>
		</div>
	);
};

const VerifyEmail = ({ actionCode, continueUrl }) => {
	const [view, setView] = useState('loading');
	const [title, setTitle] = useState('Verifying email');
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const classes = useStyles();
	const clientAuth = useAuth();
	const user = useCurrentUser();
	const location = useLocation();

	function handleResend() {
		setLoading(true);
		clientAuth
			.sendEmailVerification({ continueUrl })
			.then((response) => {
				setTitle('Verify email sent');
				setView('resend');
			})
			.catch((error) => {
				registerError(error);
				if (error.code === 'resource-exhausted') {
					setTitle('Verify email sent');
					setView('resend');
					return;
				} else if (error.code === 'already-exists') {
					setTitle('Email already verified');
				} else {
					setTitle('Error resending email');
				}
				setError(error);
				setView('error');
			})
			.finally(() => {
				setLoading(false);
			});
	}

	const { emailVerified = false, isAuthenticated } = user;
	const { syncProfileFromAuth } = clientAuth;

	// validate the actionCode
	useEffect(() => {
		debug && console.log('continue', continueUrl);

		if (emailVerified) {
			// user is already verified, just forward them along
			debug && console.log('user is verified already');
			if (
				(continueUrl.includes('hub.remotesocial') ||
					continueUrl.includes('localhost')) &&
				continueUrl.includes('/create')
			) {
				forcedRedirect(continueUrl);
				return;
			} else {
				setTitle('Email verified');
				setView('verified');
				return;
			}
		}

		auth.applyActionCode(actionCode)
			.then(async (response) => {
				debug && console.log('response:', response);
				if (isAuthenticated) {
					// we don't have to login to verify
					await syncProfileFromAuth();
				}

				if (
					continueUrl.includes('hub.remotesocial') &&
					continueUrl.includes('/create-first-account')
				) {
					forcedRedirect(continueUrl);
					return;
				}

				setTitle('Email verified');
				setView('verified');
			})
			.catch((error) => {
				registerError(error);
				setTitle('Error verifying email');
				setError(error);
				setView('error');
			});
	}, [
		emailVerified,
		actionCode,
		continueUrl,
		syncProfileFromAuth,
		isAuthenticated,
	]);

	const loginNextUrl = [
		location.pathname,
		location.search,
		location.hash,
	].join('');

	const output = {
		loading: <Loading />,
		verified: (
			<>
				<img
					src="https://cdn.remotesocial.io/hub/img/success.gif"
					className={classes.img}
					alt="Success"
				/>
				<div className={classes.action}>
					{continueUrl ? (
						<Button variant="contained" href={continueUrl}>
							Continue to Remote Social
						</Button>
					) : (
						<Button variant="contained" href="/">
							Return to the hub
						</Button>
					)}
				</div>
			</>
		),
		resend: (
			<img
				src="https://cdn.remotesocial.io/hub/img/resend.gif"
				className={classes.img}
				alt="Resend email"
			/>
		),
		error: (
			<>
				<img
					src="https://cdn.remotesocial.io/hub/img/fail.gif"
					className={classes.img}
					alt="Failed"
				/>
				<Typography variant="body1" align="center">
					<ErrorMessage error={error} />
				</Typography>
				<div className={classes.action}>
					{isAuthenticated ? (
						<Button
							variant="contained"
							onClick={handleResend}
							loading={loading}
						>
							Resend verification email
						</Button>
					) : (
						<Button
							variant="contained"
							to="/login"
							nextUrl={loginNextUrl}
						>
							Login to resend email
						</Button>
					)}
				</div>
			</>
		),
	};

	return (
		<div className={classes.root}>
			<Typography variant="h3" align="center" className={classes.title}>
				{title}
			</Typography>
			<div className={classes.result}>{output[view]}</div>
		</div>
	);
};

const InviteEmail = ({ inviteId }) => {
	const history = useHistory();

	useEffect(() => {
		history.push(`/handle-invite?inviteId=${inviteId}`);
	}, [history, inviteId]);

	return <Loading />;
};

// handle all auth emails and process action codes
export default function HandleLink() {
	const classes = useStyles();
	const query = useQuery();

	const mode = query.get('mode');
	const oobCode = query.get('oobCode');
	const continueUrl = query.get('continueUrl');

	const contents = React.useMemo(() => {
		switch (mode) {
			case 'verifyEmail':
				return (
					<VerifyEmail
						actionCode={oobCode}
						continueUrl={continueUrl}
					/>
				);

			case 'resetPassword':
				return (
					<ResetPassword
						actionCode={oobCode}
						continueUrl={continueUrl}
					/>
				);
			case 'inviteEmail':
				return <InviteEmail inviteId={oobCode} />;

			default:
				return <LinkError />;
		}
	}, [mode, oobCode, continueUrl]);

	return (
		<Container fixed maxWidth="sm" className={classes.container}>
			{contents}
		</Container>
	);
}
