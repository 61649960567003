import { LocationDescriptorObject, Location } from 'history';

export type ContinueOpts =
	| 'no-continue-url'
	| {
			continueUrl: string;
	  }
	| Location;

type ContinueState = {
	from: string;
};

type RouteBuilder = (opts: any) => LocationDescriptorObject<any>;

type ComplexRoute<
	Path extends string = string,
	Builder extends RouteBuilder = RouteBuilder,
> = Builder & {
	path: Path;
};

type CommonRoutes<T = ComplexRoute> = {
	[key in string]: string | T;
};

export function buildContinueUrl(
	opts: ContinueOpts,
): ContinueState | undefined {
	if (opts === 'no-continue-url') {
		return undefined;
	}
	if ('pathname' in opts) {
		const location = opts;
		const state = location.state as
			| {
					from?: string;
			  }
			| undefined;

		if (state && state.from) {
			return {
				from: state.from,
			};
		} else {
			return {
				from: [location.pathname, location.search, location.hash].join(
					'',
				),
			};
		}
	}
	return {
		from: opts.continueUrl,
	};
}

export function buildRoute<Path extends string, Builder extends RouteBuilder>(
	path: Path,
	builder: Builder,
): ComplexRoute<Path, Builder> {
	return Object.assign(builder, { path });
}

export function createRoutes<
	R extends CommonRoutes<ComplexRoute | CommonRoutes>,
>(routes: R): Readonly<R> {
	return Object.freeze(routes);
}
