import React from 'react';
import { Button } from '../../components';

// material-ui
import { Grid, Hidden, Typography } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';

import { useStyles } from './OnboardingStyle';
import { contentAnimation, gifAnimation } from './animation';
import { useLocation } from 'react-router-dom';

export default function Onboard() {
	const classes = useStyles();

	const location = useLocation();
	const nextUrl = (location.state && location.state.from) || '/';
	const contentSpring = useSpring(contentAnimation);
	const gifSpring = useSpring(gifAnimation);

	return (
		<Grid container spacing={2} className={classes.container}>
			<Hidden smDown>
				<Grid
					className={classes.videoContainer}
					item
					md={5}
					container
					spacing={0}
					direction="column"
					alignItems="center"
					justifyContent="center"
				>
					<animated.div style={gifSpring}>
						<img
							className={classes.video}
							alt="Hi Five"
							src="https://storage.googleapis.com/remotesocial-io-asset-storage/hub/img/onboard.gif"
						/>
					</animated.div>
				</Grid>
			</Hidden>
			<Grid item xs={12} md={1}></Grid>
			<Grid item xs={12} md={5}>
				<animated.div style={contentSpring}>
					<Grid direction="column" container spacing={2}>
						<Grid item xs={12} align="center">
							<Typography
								component="h3"
								variant="h4"
								align="center"
								className={classes.onboardTitle}
							>
								Get Started with
								<br />
								Remote Social
							</Typography>
							<Typography
								component="h5"
								variant="subtitle2"
								align="center"
								className={classes.onboardSubTitle}
							>
								The all-in-one platform where teams come
								together to build strong connections.
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Button
								to="/register"
								text="Sign up"
								className={classes.signupBtn}
								nextUrl={nextUrl}
								align="center"
							/>
						</Grid>
						<Grid item xs={12}>
							<Button
								to="/login"
								text="Log in"
								className={[
									classes.registerBtn,
									classes.loginBtn,
								]}
								nextUrl={nextUrl}
								align="center"
							/>
						</Grid>
						<Grid
							className={classes.signUpHere}
							item
							justifyContent="center"
							xs={12}
							alignItems="center"
							container
							direction="column"
							spacing={0}
						>
							<Grid>
								Lost? Back to the Remote Social{' '}
								<a
									href="https://www.remotesocial.io/"
									target="_blank"
									rel="noopener noreferrer"
									className={classes.homeLink}
								>
									Home page
								</a>
								.
							</Grid>
						</Grid>
					</Grid>
				</animated.div>
			</Grid>
		</Grid>
	);
}
