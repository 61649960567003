import { Joi, SchemaOf, timeZoneSchema, ValueTypesOf } from '../shared';
import { storableMapSchema, StorableMap } from '../shared/dataTypes';
import {
	TimeOfDay,
	timeOfDaySchema,
	TimesOfDayType,
	timesOfDayTypeSchema,
} from './timeOfDay';

export type AtLeastOneTimeOfDay = [TimeOfDay, ...TimeOfDay[]];

export const daysOfWeek = [
	'monday',
	'tuesday',
	'wednesday',
	'thursday',
	'friday',
	'saturday',
	'sunday',
] as const;

export type DayOfWeek = ValueTypesOf<typeof daysOfWeek>;

export type AtLeastOneDayOfWeek = [DayOfWeek, ...DayOfWeek[]];

export type ScheduleJobParams = StorableMap;

export const jobTypes = [
	// to ensure various features do not clash - let's
	// at least declare the types of jobs we can have
	'connect-activity',
] as const;

export type ScheduleJobType = ValueTypesOf<typeof jobTypes>;

export type ScheduleTiming = {
	startDateIso?: string;
	endDateIso?: string;
	daysOfWeek: AtLeastOneDayOfWeek;
	timesOfDay: AtLeastOneTimeOfDay;
	timeZone: string;
};

export type ScheduleInfo = {
	scheduleId: string;
	timesOfDayType?: TimesOfDayType;
	isActive: boolean;
} & ScheduleTiming;

export type Schedule = ScheduleInfo & {
	topic: string;
	type: ScheduleJobType;
	params?: ScheduleJobParams;
	// ignored on prod:
	nowDateIso?: string;
};

export const dayOfWeekSchema = Joi.string().valid(...daysOfWeek);

export const jobTypeSchema = Joi.string().valid(...jobTypes);

export const scheduleTimingSchemaMap: SchemaOf<ScheduleTiming> = {
	startDateIso: Joi.string().isoDate().optional(),
	endDateIso: Joi.string().isoDate().optional(),
	daysOfWeek: Joi.array().items(dayOfWeekSchema).min(1).required(),
	timesOfDay: Joi.array().items(timeOfDaySchema).min(1).required(),
	timeZone: timeZoneSchema.required(),
};

export const scheduleInfoSchemaMap: SchemaOf<ScheduleInfo> = {
	...scheduleTimingSchemaMap,
	scheduleId: Joi.string().required(),
	isActive: Joi.boolean().required(),
	timesOfDayType: timesOfDayTypeSchema.optional(),
};

export const scheduleSchemaMap: SchemaOf<Schedule> = {
	...scheduleInfoSchemaMap,
	topic: Joi.string().required(),
	type: jobTypeSchema.required(),
	params: storableMapSchema.optional(),
	// ignored on prod:
	nowDateIso: Joi.string().isoDate().optional(),
};
