import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {
	useSlackIntegration,
	ErrorMessage,
	useCreateGame,
	Loading,
	CreateSchedule,
	ConnectToSlack,
	useSlackChannels,
	DocumentHead,
} from '@remote-social/common';
import { GameType } from '@contracts/platform';

//material-ui
import { Typography, TextField, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/styles';

import Layout from '../Layout';
import { CTAButton } from '../../components/CTAButton';
import { useFirebase, useFirestoreConnect } from 'react-redux-firebase';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
	header: {
		fontWeight: 800,
		fontSize: 36,
		marginTop: theme.spacing(4),
		textAlign: 'center',
	},
	sectionHeader: {
		margin: theme.spacing(8, 0, 2),
	},
	instructions: {
		marginBottom: theme.spacing(1),
	},
	scheduleButton: {
		marginTop: theme.spacing(8),
	},
	createTitle: {
		marginBottom: theme.spacing(2),
	},
	paper: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: theme.spacing(1),
	},
	formControl: {
		borderBottom: '0',
	},
	formSelect: {
		borderBottom: 'none',
	},
	formInput: {
		margin: `0 ${theme.spacing(1)}px`,
		flexGrow: 1,
	},
	formButton: {
		margin: 0,
	},
}));

const ConnectWithSlack = ({ accountID, gameID }) => {
	const classes = useStyles();
	const redirectUrl = [`/create`, accountID, gameID]
		.filter(Boolean)
		.join('/');
	return (
		<Layout>
			<DocumentHead title="Connect to Slack" />
			<Typography variant="h2" className={classes.header}>
				Connect to Slack
			</Typography>
			<ConnectToSlack
				accountID={accountID}
				redirectURL={redirectUrl}
				CTAButton={CTAButton}
			/>
		</Layout>
	);
};

const CreateChallenge = ({ accountID, gameID, integrationID, game }) => {
	const classes = useStyles();
	const { loading, error, channels } = useSlackChannels({
		accountId: accountID,
		integrationId: integrationID,
	});
	const [selectedChannel, setSelectedChannel] = useState(null);
	const [schedule, setSchedule] = useState();
	const firebase = useFirebase();
	const history = useHistory();

	useEffect(() => {
		if (channels && game && game.channel) {
			console.log(
				channels,
				game.channel,
				channels.find((c) => c.id === game.channel.id),
			);
			setSelectedChannel(channels.find((c) => c.id === game.channel.id));
		}
	}, [channels, game]);

	const formIsValid = Boolean(selectedChannel && schedule);

	const onSubmit = async () => {
		const fn = firebase
			.functions()
			.httpsCallable('physical-addOrEditChallenge');
		await fn({
			accountID,
			gameID,
			integrationID,
			channel: {
				id: selectedChannel.slackChannelId,
				name: selectedChannel.name,
			},
			schedule,
		});
		history.push(`/game/${accountID}/${gameID}`);
	};

	return (
		<Layout>
			<DocumentHead title="Create Challenge" />
			<Typography variant="h2" className={classes.header}>
				Create your schedule
			</Typography>

			<Typography variant="h4" className={classes.sectionHeader}>
				Select Slack channel
			</Typography>

			<Typography variant="body2" className={classes.instructions}>
				Enter a Slack channel to use for the physical challenge or pick
				an existing one using the dropdown.
			</Typography>

			<Autocomplete
				options={channels}
				getOptionLabel={(option) => '#' + option.name}
				value={selectedChannel}
				onChange={(e, newChannel) =>
					setSelectedChannel(newChannel || null)
				}
				renderInput={(params) => (
					<TextField
						{...params}
						label="#channel"
						variant="outlined"
						error={Boolean(error) || params.error}
						helperText={
							error ? error.message || error : params.helperText
						}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									{loading ? (
										<CircularProgress
											color="inherit"
											size={20}
										/>
									) : null}
									{params.InputProps.endAdornment}
								</>
							),
						}}
					/>
				)}
			/>

			<Typography variant="h4" className={classes.sectionHeader}>
				Run the physical challenge each
			</Typography>
			<CreateSchedule
				{...(game.schedule
					? {
							defaultDaysOfWeek: game.schedule.daysOfWeek,
							defaultTimezone: game.schedule.timezone,
							defaultStartHour: game.schedule.startHour,
							defaultEndHour: game.schedule.endHour,
							defaultMinute: game.schedule.minute,
							defaultIntervalHours: game.schedule.intervalHours,
					  }
					: {})}
				onChange={(schedule, rawSchedule) => setSchedule(rawSchedule)}
			/>

			<CTAButton
				disabled={!formIsValid}
				onClick={onSubmit}
				className={classes.scheduleButton}
			>
				Schedule Physical Challenge
			</CTAButton>
		</Layout>
	);
};

const useGame = (accountID, gameID) => {
	const storeAs = accountID + '--' + gameID;
	useFirestoreConnect(
		accountID &&
			gameID && {
				storeAs,
				collection: 'accounts',
				doc: accountID,
				subcollections: [
					{
						collection: 'games',
						doc: gameID,
					},
				],
			},
	);
	const loading = useSelector(
		(state) => state.firestore.status.requesting[storeAs],
	);
	return {
		loaded:
			useSelector((state) => state.firestore.status.requested[storeAs]) &&
			!loading,
		error: useSelector((state) => state.firestore.errors[storeAs]),
		game: useSelector((state) => state.firestore.data[storeAs]),
	};
};

export default function Create() {
	const { account: accountID, game: gameID } = useParams();
	const search = new URLSearchParams(useLocation().search);
	const oauthError = search.get('error');
	const {
		game,
		loaded: gameLoaded,
		error: gameError,
	} = useGame(accountID, gameID);

	const { error, pending, createGame } = useCreateGame({
		gameType: GameType.physical,
	});

	// set up Slack integration
	const {
		loaded: loadedIntegrations,
		error: integrationsError,
		integrations: slackIntegrations,
	} = useSlackIntegration({
		accountId: accountID,
		requiredScopes: [
			'chat:write',
			'commands',
			'reactions:read',
			'reactions:write',
			'channels:manage',
			'channels:read',
			'channels:join',
			'users.profile:read',
		],
	});
	const integrationID =
		slackIntegrations.length > 0
			? slackIntegrations[0].integrationId
			: undefined;

	useEffect(() => {
		if (integrationID && (!accountID || !gameID) && !pending) {
			createGame(accountID);
		}
		// I don't want this to re-run in the space between createGame() being called and the browser being redirected (while pending is false again)
		// so I only re-run when the step before (integrationID) changes.
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [integrationID]);

	if (integrationsError || error || oauthError || gameError) {
		return (
			<Layout>
				<ErrorMessage
					error={
						integrationsError || error || oauthError || gameError
					}
				/>
			</Layout>
		);
	}

	if (!loadedIntegrations) {
		return (
			<Layout>
				<Loading />
			</Layout>
		);
	}

	if (!integrationID) {
		return <ConnectWithSlack accountID={accountID} gameID={gameID} />;
	}

	const creatingOrWillCreateGame = pending || !accountID || !gameID;
	if (creatingOrWillCreateGame || !gameLoaded) {
		return (
			<Layout>
				<Loading />
			</Layout>
		);
	}

	return (
		<CreateChallenge
			accountID={accountID}
			gameID={gameID}
			integrationID={integrationID}
			game={game}
		/>
	);
}
