import React from 'react';
import {
	isInitial,
	isPending,
	isSuccess,
	isError,
	RequestState,
} from '../store-tools';
import { useSlackStore } from './store';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import {
	CircularProgress,
	createStyles,
	makeStyles,
	TextField,
	Typography,
} from '@material-ui/core';
import { SlackChannel } from '@contracts/platform';
import { UnreachableError } from '../errors';

type SlackChannelInfo = Pick<SlackChannel, 'slackChannelId' | 'name'>;

const useStyles = makeStyles((_theme) => {
	return createStyles({
		hash: {
			marginLeft: `0.3em`,
		},
	});
});

export const useSlackChannelSelect = (opts?: {
	defaultValue?: string | SlackChannelInfo;
}) => {
	const [slackChannel, setSlackChannel] = React.useState<
		string | SlackChannelInfo
	>(opts?.defaultValue || 'remote-social');

	const inputRef = React.useRef<HTMLInputElement>();

	const displayListOfSlackChannels = React.useCallback(() => {
		if (!inputRef.current) {
			return;
		}
		inputRef.current.focus();
	}, []);

	return {
		slackChannel,
		displayListOfSlackChannels,
		slackChannelSelectProps: {
			inputRef,
			onChange: setSlackChannel,
			value: slackChannel,
		},
	};
};

type Props = {
	variant: 'select-existing' | 'select-or-create';
	inputRef?: React.MutableRefObject<HTMLInputElement | undefined>;

	value: string | SlackChannelInfo | undefined;
	onChange: (channelName: string | SlackChannelInfo) => void;
};

const getOptionLabel = (option: string | SlackChannelInfo): string =>
	typeof option === 'object' ? option.name : option;

const renderOption = (option: string | SlackChannelInfo): string =>
	typeof option === 'object' ? `# ${option.name}` : `# ${option}`;

const determineChannelStatus = (
	channel: string | SlackChannelInfo | undefined,
	listChannelsRequest: RequestState<SlackChannel[], SlackChannel[]>,
):
	| { status: 'unselected'; entry?: undefined }
	| { status: boolean | 'unknown'; entry: SlackChannel | undefined } => {
	if (!channel) {
		return {
			status: 'unselected',
		};
	}
	const entry =
		typeof channel === 'object'
			? listChannelsRequest.data.find(
					(entry) => entry.slackChannelId === channel.slackChannelId,
			  )
			: listChannelsRequest.data.find((entry) => entry.name === channel);
	return {
		status: isSuccess(listChannelsRequest) ? Boolean(entry) : 'unknown',
		entry,
	};
};

const helperText = (channelStatus: boolean | 'unknown' | 'unselected') => {
	switch (channelStatus) {
		case true:
			return 'You are using an existing channel';
		case false:
			return `The specified channel doesn't exist yet and going to be created`;
		case 'unknown':
		case 'unselected':
			return `Enter a Slack channel to be created, or pick an existing one`;
		default:
			throw new UnreachableError(channelStatus);
	}
};

export const SlackChannelSelect: React.ComponentType<Props> = React.memo(
	({ variant, inputRef, value, onChange: onChangeProp }) => {
		const styles = useStyles();
		const { listChannelsRequest } = useSlackStore();

		const triggerLoadChannels = React.useCallback(() => {
			if (
				isInitial(listChannelsRequest) ||
				isError(listChannelsRequest)
			) {
				listChannelsRequest.initiate();
			}
		}, [listChannelsRequest]);

		React.useEffect(() => {
			triggerLoadChannels();
		}, [triggerLoadChannels]);

		const onChange = React.useCallback(
			(_: unknown, channel: string | SlackChannelInfo | null) => {
				onChangeProp(channel || '');
			},
			[onChangeProp],
		);
		const [inputValue, setInputValue] = React.useState(
			typeof value === 'object' ? value.name : value,
		);
		const onInputChange = React.useCallback(
			(_: unknown, value: string) => {
				setInputValue(value);
			},
			[setInputValue],
		);

		const onBlur = React.useCallback(() => {
			if (value !== inputValue) {
				const { entry } = determineChannelStatus(
					inputValue,
					listChannelsRequest,
				);
				onChangeProp(entry ?? inputValue ?? '');
			}
		}, [value, inputValue, listChannelsRequest, onChangeProp]);

		const channelStatus = React.useMemo(
			() => determineChannelStatus(value, listChannelsRequest),
			[listChannelsRequest, value],
		);

		React.useEffect(() => {
			if (
				channelStatus.status === true &&
				channelStatus.entry &&
				channelStatus.entry !== value
			) {
				// we just got a response from BE
				// that this channel exists, so we can
				// use channel id
				onChangeProp(channelStatus.entry);
			}
		}, [channelStatus, onChangeProp, value]);

		const loading = isPending(listChannelsRequest);

		const renderInput = React.useCallback(
			(params: AutocompleteRenderInputParams) => (
				<TextField
					{...params}
					variant="outlined"
					size="small"
					fullWidth
					label="Channel"
					error={channelStatus.status === 'unselected'}
					helperText={helperText(channelStatus.status)}
					InputProps={{
						...params.InputProps,
						inputRef,
						startAdornment: (
							<Typography className={styles.hash}>#</Typography>
						),
						endAdornment: (
							<>
								{loading && (
									<CircularProgress
										size="1em"
										color="inherit"
									/>
								)}
								{params.InputProps.endAdornment}
							</>
						),
					}}
				/>
			),
			[inputRef, loading, styles.hash, channelStatus],
		);

		return (
			<Autocomplete
				size="small"
				value={value ?? ''}
				inputValue={inputValue}
				options={listChannelsRequest.data as SlackChannelInfo[]}
				getOptionLabel={getOptionLabel}
				loading={loading}
				onChange={onChange}
				onInputChange={onInputChange}
				freeSolo={variant === 'select-or-create'}
				renderOption={renderOption}
				renderInput={renderInput}
				openOnFocus={true}
				onBlur={onBlur}
			/>
		);
	},
);
