import { mapOf, ValueTypesOf } from '../../shared';

// NOTE: do not copy snake_case, use kebab-case
const playerStatuses = ['winner', 'loser', 'loser_disqualified'] as const;

export type PlayerStatus = ValueTypesOf<typeof playerStatuses>;

// TODO: Remove when all is TypeScript
// NOTE: do not copy-paste this constant, we don't really need it
// for string literal types, we could just use strings as is
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlayerStatus = mapOf(playerStatuses);
