import React, { useEffect } from 'react';

export default function PageNotFound() {
	// update the document title tage
	useEffect(() => {
		document.title = `${process.env.REACT_APP_SITE_TITLE} - Page not found`;
	}, []);

	return (
		<header className="App-header">
			<h1>Page not found</h1>
			<a href="/">Home</a>
		</header>
	);
}
