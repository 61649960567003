import React from 'react';
import { Location } from 'history';
import type { createAccountsApi } from './Account';
import { AccountsContext } from './Account';

export type Account = {
	id: string;
	avatarUrl?: string;
	name: string;
	role: 'member' | 'admin' | 'owner';
	owner: string;
};

type CreateAccountsApi = typeof createAccountsApi;

export type UseCurrentAccountsState = {
	currentAccount?: Account;
	currentAccountId?: string;
	accounts?: Array<Account>;
	owned?: Array<Account>;
	/** @deprecated (soon to be replaced by `status`) whether current account is loaded */
	isLoaded: boolean;
	/** @deprecated (soon to be replaced by `status`) whether list of accounts is loaded */
	loadingAccounts: boolean;
	error?: Error;
	refresh?: () => void;
	needsAccountCreated?: boolean;
} & ReturnType<CreateAccountsApi>;

type Props = {
	useAccountIdFromQueryParam?: Location;
};

export const useCurrentAccount = (props?: Props): UseCurrentAccountsState => {
	const { useAccountIdFromQueryParam } = props ?? {};

	const state: UseCurrentAccountsState =
		React.useContext<UseCurrentAccountsState>(AccountsContext);

	React.useEffect(() => {
		if (useAccountIdFromQueryParam) {
			const search = new URLSearchParams(
				useAccountIdFromQueryParam.search,
			);

			const accountId = search.get('accountId');

			if (accountId && state.currentAccount?.id !== accountId) {
				state.setCurrentAccount(accountId);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return state;
};
