import type firebase from 'firebase';
import { isDevBuild, env } from '../environment';
import { setUser } from '../errors/setUser';
import { createApiCall } from './apiCall';
import { withAutoRetry } from './autoRetry';
import { withLocalChaosMonkey } from './chaosMonkey';

export type Firebase = typeof firebase;

export function initFirebase() {
	const config = env();

	// keep using pre-initialized firebase that is coming from __firebase/init.js (see public/index.html)

	const firebase = window.firebase as any as Firebase;

	firebase.initializeApp(config.firebaseConfig);

	const auth = firebase.auth();
	const firestore = firebase.firestore();
	const database = firebase.database();

	auth.onAuthStateChanged((user) => {
		setUser(
			user && {
				// do not send more than required
				uid: user.uid,
			},
		);
	});

	const functionsBaseUrl =
		config.name === 'local'
			? `http://${window.location.hostname}:5001/remotesocial-dev/us-central1`
			: config.firebaseConfig.functionsBaseUrl;

	const functions = monkeyPatchFunctionsWithCustomApiCall(
		firebase,
		functionsBaseUrl,
	);

	if (isDevBuild() && config.name === 'local') {
		console.warn('🚨  Using local emulators');

		const hostname = window.location.hostname;

		auth.useEmulator(`http://${hostname}:9099`);
		functions.useEmulator(hostname, 5001);
		database.useEmulator(hostname, 9000);
		firestore.settings({
			host: `${hostname}:8080`,
			ssl: false,
		});
	}

	auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);

	if (
		!isDevBuild() &&
		config.name !== 'local' &&
		config.firebaseConfig.measurementId
	) {
		console.log('Using analytics', config.firebaseConfig.measurementId);
		firebase.analytics();
	}

	return {
		firebase,
		auth,
		firestore,
		database,
		functions,
	};
}

function createFunctions(
	firebase: Firebase,
	functionsBaseUrl?: string,
): firebase.functions.Functions {
	const fns = firebase.functions() as firebase.functions.Functions & {
		_url: (name: string) => string;
	};
	fns.httpsCallable = (name, options) => {
		const apiCall = withAutoRetry(
			withLocalChaosMonkey(
				createApiCall(firebase, {
					baseUrl: functionsBaseUrl,
				}),
			),
		);
		return (data) => {
			return apiCall(name, data, options);
		};
	};
	return fns;
}

function monkeyPatchFunctionsWithCustomApiCall(
	firebase: Firebase,
	functionsBaseUrl?: string,
) {
	const functions = createFunctions(firebase, functionsBaseUrl);

	// Now prevent from new instance of functions created
	const previousFunctions = firebase.functions.bind(firebase);
	const newFunctions: typeof previousFunctions = ((app) => {
		if (!app) {
			// typical usage of firebase functions in our app
			return functions;
		}
		// for future untypical usage assume default behavior Firebase
		// provides
		return previousFunctions(app);
	}) as typeof previousFunctions;
	firebase.functions = newFunctions.bind(firebase);

	return functions;
}
