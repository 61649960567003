export const shuffle = (array: any[]) => {
	let randomIndex;
	// While there remain elements to shuffle...
	for (let index = array.length - 1; index > 0; index--) {
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * index);
		// And swap it with the current element.
		[array[index], array[randomIndex]] = [array[randomIndex], array[index]];
	}
	return array;
};
