import React from 'react';
import { useFirebase } from 'react-redux-firebase';
import { useAsyncFunction } from './useAsyncFunction';
import type { MergedApi } from '@contracts/api';
import type { RequestState } from '../store-tools/requestState';
import type { AsyncReturnType } from '../utils/types';

type Param<K extends keyof MergedApi> = Parameters<MergedApi[K]>[0];
type Return<K extends keyof MergedApi> = AsyncReturnType<MergedApi[K]>;

type ResultWithParams<K extends keyof MergedApi, I = undefined> = RequestState<
	Return<K>,
	I
> & {
	initiate: (...params: Parameters<MergedApi[K]>) => void;
};

type ResultNoParams<K extends keyof MergedApi, I = undefined> = RequestState<
	Return<K>,
	I
> & {
	initiate: () => void;
};

export function useCallable<K extends keyof MergedApi>(functionName: K) {
	const firebase = useFirebase();

	const fn = React.useMemo(() => {
		const fn = firebase
			.functions()
			.httpsCallable<K, Param<K>, Return<K>>(functionName);
		return (params: Param<K>) => fn(params).then((data) => data.data);
	}, [firebase, functionName]);

	return fn;
}

export function useBackendFunction<K extends keyof MergedApi, I = undefined>(
	functionName: K,
	opts?: {
		callOnChange?: undefined;
		initialValue?: I;
	},
): ResultWithParams<K, I>;
export function useBackendFunction<K extends keyof MergedApi, I = undefined>(
	functionName: K,
	opts: {
		callOnChange: () => Param<K> | undefined;
		initialValue?: I;
	},
): ResultNoParams<K, I>;
export function useBackendFunction<K extends keyof MergedApi, I = undefined>(
	functionName: K,
	opts?: {
		callOnChange?: () => Param<K> | undefined;
		initialValue?: I;
	},
): ResultWithParams<K, I> {
	const fn = useCallable(functionName);
	// eslint-disable-next-line
	return useAsyncFunction(fn, opts as any);
}
