import React, { useContext } from 'react';

import {
	PageNotFound,
	AuthenticatedRoute,
	AuthenticatedHostRoute,
	getAuthRoutes,
	Header,
	AppLayout,
	AudioControl,
	Auth,
} from '@remote-social/common';

// router
import { Switch, Route, Redirect, Link as RouterLink } from 'react-router-dom';

// Components
import Home from './features/home/Home';
import Create from './features/create/Create';
import Game from './features/game/Game';

// Materialize Styles
import { makeStyles } from '@material-ui/core/styles';
import { Fab, Container, Link, Typography, Hidden } from '@material-ui/core';
import { VolumeUp, VolumeOff } from '@material-ui/icons';
import clsx from 'clsx';

const HEADER_HEIGHT = 64;
const LOGO_WRAPPER_HEIGHT = 80;

const useStyles = makeStyles((theme) => ({
	logoWrapper: {
		display: 'block',
		width: '100%',
		borderRadius: '0 0 2.5rem 2.5rem',
		boxShadow: theme.shadows[5],
		background: theme.palette.background.header,
		textAlign: 'center',
		position: 'relative',
		zIndex: 1,
		'&:before': {
			content: '""',
			background: theme.palette.background.header,
			width: '100%',
			height: 24,
			position: 'absolute',
			left: 0,
			top: -12,
		},
	},
	logo: {
		fontFamily: '"Monoton", cursive',
		textAlign: 'center',
		display: 'inline-block',
		fill: theme.palette.primary.main,
		height: 'auto',
		padding: theme.spacing(2, 0),
		zIndex: 2,
		fontSize: '2rem',
		[theme.breakpoints.up('sm')]: {
			fontSize: '3rem',
			marginTop: -HEADER_HEIGHT,
			position: 'relative',
		},
	},
	video: {
		objectFit: 'cover',
		width: '100%',
		height: 'calc(100% + ' + LOGO_WRAPPER_HEIGHT + 'px)',
		top: -LOGO_WRAPPER_HEIGHT,
		left: 0,
		position: 'absolute',
		zIndex: 0,
		[theme.breakpoints.down('xs')]: {
			top: -HEADER_HEIGHT - LOGO_WRAPPER_HEIGHT,
			height:
				'calc(100% + ' + (HEADER_HEIGHT + LOGO_WRAPPER_HEIGHT) + 'px)',
		},
	},
	fab: {
		zIndex: 2,
		[theme.breakpoints.up('sm')]: {
			position: 'relative',
			top: '2rem',
		},
		[theme.breakpoints.only('sm')]: {
			top: '3em',
			left: '-7em',
		},
		[theme.breakpoints.down('xs')]: {
			boxShadow: 'none',
		},
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.common.white,
		'&:hover': {
			backgroundColor: 'hsla(180deg, 0%, 100%, 0.6)',
		},
	},
	headerContainer: {
		[theme.breakpoints.down('xs')]: {
			zIndex: 1,
		},
	},
	alignHeaderWhenSmall: {
		[theme.breakpoints.down('xs')]: {
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
			width: 'auto',
		},
	},
}));

// URLs for CDN hosted content
const Poster = 'https://cdn.remotesocial.io/physical/img/Poster.png';
const PhysicalVideo =
	'https://cdn.remotesocial.io/physical/video/PhysicalChallenge.mp4';

const MyHeader = ({ className }) => {
	const classes = useStyles();
	const { isMuted, toggleMute } = useContext(AudioControl) || {};
	return (
		<div className={clsx(classes.headerContainer, className)}>
			<Header className={classes.alignHeaderWhenSmall}>
				<Hidden smDown>
					<Fab
						color="primary"
						aria-label="mute"
						size="large"
						onClick={toggleMute}
						className={classes.fab}
					>
						{isMuted ? <VolumeOff /> : <VolumeUp />}
					</Fab>
				</Hidden>
			</Header>
			<Container maxWidth="sm">
				<Link
					className={classes.logoWrapper}
					component={RouterLink}
					to="/"
				>
					<Typography variant="h1" className={classes.logo}>
						Physical Challenge
					</Typography>
				</Link>
			</Container>
		</div>
	);
};

export default function App() {
	const classes = useStyles();
	const { isMuted } = useContext(AudioControl) || {};
	return (
		<AppLayout Header={MyHeader}>
			<Hidden smDown>
				<video
					playsInline
					autoPlay
					muted={isMuted}
					loop
					poster={Poster}
					id="physical"
					className={classes.video}
				>
					<source src={PhysicalVideo} type="video/mp4" />
				</video>
			</Hidden>
			<Switch>
				{/* Home Route */}
				<Route exact path="/" component={Home} />

				<AuthenticatedRoute
					exact
					path="/select-account/physical"
					component={Auth}
					variant="SelectAccount"
					createFunction="platform-selectGameAccount"
				/>
				{/* Auth Routes */}
				{getAuthRoutes()}

				{/* Game Routes */}
				<AuthenticatedHostRoute
					path="/create/:account?/:game?"
					component={Create}
				/>
				<AuthenticatedRoute
					path="/game/:account/:game"
					component={Game}
					redirectToPath="/login"
				/>
				<Redirect exact path="/game/:account" to="/game" />
				<AuthenticatedRoute
					path="/game"
					component={Game}
					redirectToPath="/register"
				/>

				{/* Default Route (404) */}
				<Route component={PageNotFound} />
			</Switch>
		</AppLayout>
	);
}
