import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Grid, Theme, Typography } from '@material-ui/core';
import {
	CircleSwitcher,
	DocumentHead,
	LinkButton,
	Loading,
	PageError,
	RemoteSocialLogo,
} from '../../components';
import { Account, routes, useCurrentAccount, useSessionStorage } from '@common';

const useStyles = makeStyles((theme: Theme) => ({
	brand: {
		height: '3rem',
		fill: theme.palette.text.primary,
	},
	heading: {
		marginTop: theme.spacing(2),
	},
}));

type SelectCircleState = {
	circleWasSelected: boolean;
	setCircleWasSelected: (newValue: boolean) => void;
};

export const useSelectCircleState = (): SelectCircleState => {
	const [circleWasSelected, setCircleWasSelected] =
		useSessionStorage('circleWasSelected');

	return {
		circleWasSelected,
		setCircleWasSelected,
	};
};

export const SelectCircle: React.ComponentType = () => {
	const { setCircleWasSelected } = useSelectCircleState();
	const history = useHistory();
	const location =
		useLocation<ReturnType<typeof routes.selectCircle>['state']>();
	const nextUrl = location?.state?.from || '/';
	const {
		loadingAccounts,
		accounts,
		error,
		currentAccount,
		setCurrentAccount,
	} = useCurrentAccount();
	const classes = useStyles();

	const handleChange = React.useCallback(
		({ account }: { account: Account }) => setCurrentAccount(account.id),
		[setCurrentAccount],
	);

	React.useEffect(() => {
		return history.listen(() => setCircleWasSelected(true));
	}, [history, setCircleWasSelected]);

	React.useEffect(() => {
		if (!loadingAccounts && accounts?.length === 1) {
			history.replace(nextUrl);
		}
	}, [accounts, history, loadingAccounts, nextUrl, setCircleWasSelected]);

	if (error) {
		return <PageError error={error} />;
	}

	return (
		<Grid container direction="column" alignItems="center" spacing={2}>
			<DocumentHead title="Select Circle" />
			<Grid item>
				<RemoteSocialLogo variant="text" className={classes.brand} />
				<Typography
					component="h5"
					variant="h5"
					align="center"
					className={classes.heading}
				>
					SELECT CIRCLE
				</Typography>
			</Grid>
			{loadingAccounts ? (
				<Loading />
			) : (
				<>
					<Grid item>
						<CircleSwitcher
							selectedAccountID={currentAccount?.id}
							accounts={accounts}
							onChange={handleChange}
							fullWidth
							variant={'outlined'}
						/>
					</Grid>
					<Grid item>
						<LinkButton
							variant="contained"
							color="primary"
							disabled={!currentAccount?.id}
							to={nextUrl}
							size="large"
						>
							Select
						</LinkButton>
					</Grid>
				</>
			)}
		</Grid>
	);
};
