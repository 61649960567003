import { isError, isPending } from '../../store-tools';
import { useBackendFunction } from '../../hooks';
import { SlackChannel } from '@contracts/platform';

export const useSlackChannels = (opts: {
	accountId?: string;
	integrationId?: string;
	requestOnMount?: boolean;
}) => {
	const { accountId, integrationId } = opts;

	const listChannelsRequest = useBackendFunction('platform-listChannels', {
		callOnChange: () => {
			if (!accountId || !integrationId) {
				return undefined;
			}

			return {
				accountId,
				integrationId,
			};
		},
		initialValue: [] as SlackChannel[],
	});

	return {
		listChannelsRequest,
		// temporary for backward compatibility
		// remove when Physical is fully integrated into Connect
		loading: isPending(listChannelsRequest),
		error: isError(listChannelsRequest)
			? listChannelsRequest.error
			: undefined,
		channels: listChannelsRequest.data || [],
	};
};
