import { useState, useRef, useEffect, useMemo } from 'react';
import { useMountedRef } from '../hooks';
import { registerError } from '../errors';

export default function useLoadable(fn, prop = 'data', deps = []) {
	const unmountedError = useMemo(
		() => new Error('Component unmounted during useLoadable request'),
		[],
	);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [data, setData] = useState(null);
	const [_counter, setCounter] = useState(0);
	const refresh = useRef(() => setCounter((c) => c + 1)).current;
	const mounted = useMountedRef();
	const req = useRef();
	const load = async () => {
		let thisReq;
		setLoading(true);
		setError(null);
		try {
			thisReq = req.current = fn();
			const response = await req.current;
			thisReq === req.current && mounted.current && setData(response);
		} catch (e) {
			registerError(e);
			thisReq === req.current && mounted.current && setError(e);
		} finally {
			if (!mounted.current) {
				registerError(unmountedError);
			}
			thisReq === req.current && mounted.current && setLoading(false);
		}
	};
	useEffect(() => {
		load();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps.concat(_counter));

	return { loading, error, [prop]: data, refresh };
}
