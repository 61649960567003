import { useState, useCallback } from 'react';
import { registerError } from '../errors';

const makeUseStorage = (storageProp) =>
	function (key, initialValue) {
		// State to store our value
		// Pass initial state function to useState so logic is only executed once
		const [storedValue, setStoredValue] = useState(() => {
			try {
				// Get from local storage by key
				const item = window[storageProp].getItem(key);
				// Parse stored json or if none return initialValue
				return item ? JSON.parse(item) : initialValue;
			} catch (error) {
				registerError(error);
				return initialValue;
			}
		});

		// Return a wrapped version of useState's setter function that ...
		// ... persists the new value to localStorage.
		const setValue = useCallback(
			(value) => {
				try {
					// Allow value to be a function so we have same API as useState
					const valueToStore =
						value instanceof Function ? value(storedValue) : value;
					// Save state
					setStoredValue(valueToStore);
					// Save to local storage
					window[storageProp].setItem(
						key,
						JSON.stringify(valueToStore),
					);
				} catch (error) {
					registerError(error);
				}
			},
			[key, storedValue, setStoredValue],
		);

		return [storedValue, setValue];
	};

export const useSessionStorage = makeUseStorage('sessionStorage');
export const useLocalStorage = makeUseStorage('localStorage');
export default useSessionStorage;
