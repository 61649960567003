import { makeHSLA } from '@remote-social/common';

const appConfig = {
	title: 'Physical Challenge', // customise the app title
	baseUrl: 'remotesocial.io',
	subdomain: 'app', // customise the subdomain
	design: {
		background: {
			//h: 0, s: 0, l: 95, a: 1,
			//h: 180, s: 0, l: 100, a: 1,
			h: 277,
			s: 68,
			l: 60,
			a: 1,
		},
		main: {
			//h: 180, s: 0, l: 100, a: 1,
			h: 332,
			s: 95,
			l: 48,
			a: 1,
		},
		headerBackground: {
			h: 180,
			s: 0,
			l: 100,
			a: 1,
		},
		headerText: {
			h: 332,
			s: 95,
			l: 48,
			a: 1,
		},
		paper: {
			h: 332,
			s: 95,
			l: 48,
			a: 1,
		},
		error: {
			h: 180,
			s: 0,
			l: 100,
			a: 1,
		},
		overrides: {
			MuiPaper: {
				root: {
					background:
						'linear-gradient(208.83deg, #FD469A 0%, #EF0973 50.52%, #C70760 100%)',
				},
			},
			MuiButton: {
				contained: {
					color: makeHSLA({ h: 180, s: 0, l: 0, a: 1 }),
				},
			},
		},
		//paper: 'linear-gradient(208.83deg, #FD469A 0%, #EF0973 50.52%, #C70760 100%)'
	},
};

export { appConfig };
