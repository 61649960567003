import { ErrorMessage } from './errorMessage';
import { ScreenContainer } from './screenContainer';
import {
	Typography,
	Link,
	Button,
	makeStyles,
	createStyles,
} from '@material-ui/core';
import { ErrorType } from '../errors';
import { useLocation } from 'react-router';
import { FluidImgContainer } from './fluidImgContainer';
import SeriouslyImg from '../assets/images/seriously.png';

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			display: 'grid',

			placeItems: 'center',
			gridTemplateRows: 'auto 1fr auto',

			gap: theme.spacing(2),
			padding: theme.spacing(4),

			[theme.breakpoints.down('xs')]: {
				alignContent: 'start',
				gridTemplateRows: 'auto',
				padding: theme.spacing(2),
			},

			textAlign: 'center',
		},
	}),
);

type Props = {
	error: string | ErrorType;
	extraErrorInfo?: string;
};

const imageSize = {
	width: 500,
	height: 281,
};

export const PageError: React.ComponentType<Props> = ({
	error,
	extraErrorInfo,
}) => {
	const styles = useStyles();
	const location = useLocation<{ from?: string }>();
	// make sure we do not loose continueUrl when we reload
	const href =
		location.state?.from ??
		[location.pathname, location.search, location.hash].join('');
	return (
		<ScreenContainer className={styles.container}>
			<FluidImgContainer {...imageSize}>
				<img src={SeriouslyImg} alt="Something Went Wrong" />
			</FluidImgContainer>
			<Typography variant="h2">Oops!</Typography>
			<ErrorMessage
				error={error}
				extraErrorInfo={extraErrorInfo}
				variant="h4"
				color="textPrimary"
			/>
			<Button href={href} variant="contained" color="primary">
				Reload Page
			</Button>
			<Typography component="p" variant="body2">
				If this happens again, send us email at{' '}
				<Link href="mailto:support@remotesocial.io">
					support@remotesocial.io
				</Link>
			</Typography>
		</ScreenContainer>
	);
};
