import React, { useEffect, useState } from 'react';
import { Button, DocumentHead } from '../../components';
import { useHistory } from 'react-router-dom';
import { Grid, Hidden, Typography, TextField } from '@material-ui/core';
import {
	useCurrentUser,
	useCurrentAccount,
	isPending,
	isSuccess,
} from '@remote-social/common';
import { useStyles } from './OnboardingStyle';
import { useSpring, animated } from 'react-spring';
import { contentAnimation, gifAnimation } from './animation';
import { useLocation } from 'react-router-dom';
import DropZoneField from '@remote-social/common/src/components/ImageDropField';
import { Formik } from 'formik';
import Placeholder from '../../components/ImageCrop/Placeholder';
import { ErrorMessage } from '@common/components';
import { useAsyncFunction } from '../../hooks';

export default function Create({ firstAccount }) {
	const classes = useStyles();
	const user = useCurrentUser();
	const {
		createAccount,
		createFirstAccount,
		needsAccountCreated,
		loadingAccounts,
	} = useCurrentAccount();
	const history = useHistory();
	const contentSpring = useSpring(contentAnimation);
	const gifSpring = useSpring(gifAnimation);
	const location = useLocation();
	const shouldWelcome =
		firstAccount ||
		(typeof needsAccountCreated === 'boolean' && needsAccountCreated);
	const originalContinueUrl = (location.state && location.state.from) || '/';
	const nextUrl = React.useMemo(
		() =>
			shouldWelcome
				? {
						pathname: '/welcome',
						state: {
							from: originalContinueUrl,
						},
				  }
				: originalContinueUrl,
		[originalContinueUrl, shouldWelcome],
	);
	const [circleName, setCircleName] = useState('');
	const [avatarUrl, setAvatarUrl] = useState(null);

	useEffect(() => {
		setCircleName(`${user.displayName}'s Circle`);
	}, [user.displayName]);

	const handleChange = (event) => {
		setCircleName(event.target.value);
	};

	const createRequest = useAsyncFunction(async () => {
		if (firstAccount) {
			// would only be used if we are on /create-first-account
			// route
			await createFirstAccount({
				name: circleName,
				photoURL: avatarUrl,
			});
		} else {
			await createAccount({
				name: circleName,
				photoURL: avatarUrl,
			});
		}
	});

	const buttonText = isPending(createRequest)
		? 'Creating account...'
		: loadingAccounts
		? 'Loading'
		: 'Done';

	const createdSuccessfully = isSuccess(createRequest);

	React.useEffect(() => {
		if (
			(firstAccount &&
				typeof needsAccountCreated === 'boolean' &&
				!needsAccountCreated) ||
			createdSuccessfully
		) {
			// don't need to create account anymore - just redirect
			history.push(nextUrl);
		}
	}, [
		history,
		nextUrl,
		needsAccountCreated,
		firstAccount,
		createdSuccessfully,
	]);

	return (
		<Grid container spacing={2}>
			<DocumentHead title="Create Circle" />
			<Hidden smDown>
				<Grid
					className={classes.videoContainer}
					item
					md={5}
					container
					spacing={0}
					direction="column"
					alignItems="center"
					justifyContent="center"
				>
					<animated.div style={gifSpring}>
						<img
							className={classes.video}
							alt="World Waving"
							src="https://storage.googleapis.com/remotesocial-io-asset-storage/hub/img/worldWavingTest.gif"
						/>
					</animated.div>
				</Grid>
			</Hidden>
			<Grid item xs={12} md={1}></Grid>
			<Grid item xs={12} md={5} className={classes.content}>
				<animated.div style={contentSpring}>
					<Grid direction="column" container spacing={2}>
						<Grid item xs={12} align="center">
							<Typography
								component="h3"
								variant="h3"
								align="center"
								className={classes.createTitle}
							>
								Let's create your Remote Social Circle
							</Typography>
							<Typography
								component="h5"
								variant="subtitle2"
								align="center"
								className={classes.circleSubTitle}
							>
								Give your Circle a name and avatar
							</Typography>
						</Grid>
						<Formik
							className={classes.createForm}
							initialValues={{
								image: '',
							}}
						>
							{() => (
								<>
									<Grid
										justifyContent="center"
										alignItems="center"
										item
										xs={12}
										container
										direction="column"
									>
										<DropZoneField
											name="image"
											placeholder={Placeholder}
											onImageUploaded={(e) =>
												setAvatarUrl(e)
											}
											uploader={
												'platform-uploadCircleAvatar'
											}
										/>
									</Grid>
									<Grid
										container
										direction="column"
										spacing={2}
									>
										<Grid item xs={12} align="center">
											<TextField
												margin="normal"
												required
												inputProps={{
													min: 0,
													style: {
														textAlign: 'center',
													},
												}}
												fullWidth
												type="text"
												value={circleName}
												id="circleName"
												name="circleName"
												autoFocus
												onChange={handleChange}
											/>
											<ErrorMessage
												error={createRequest.error}
											/>
										</Grid>
									</Grid>
									<Typography
										component="h5"
										variant="subtitle2"
										align="center"
										className={classes.circleNameSubTitle}
									>
										(Don't worry, you can change this later)
									</Typography>
									<Grid item xs={12}>
										<Button
											click={createRequest.initiate}
											text={buttonText}
											loading={
												isPending(createRequest) ||
												loadingAccounts
											}
											className={classes.doneBtn}
											align="center"
										/>
									</Grid>
								</>
							)}
						</Formik>
					</Grid>
				</animated.div>
			</Grid>
		</Grid>
	);
}
