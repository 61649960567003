import React from 'react';
import { Link as RouterLink, NavLink as RouterNavLink } from 'react-router-dom';
import { Link as UILink, ListItem } from '@material-ui/core';

export const Link = (props) => <UILink {...props} component={RouterLink} />;
export const NavLink = (props) => (
	<UILink {...props} component={RouterNavLink} />
);
export const ListItemLink = (props) => (
	<ListItem button {...props} component={RouterNavLink} />
);
