import React from 'react';
import {
	Box,
	CircularProgress,
	makeStyles,
	Theme,
	Typography,
	useMediaQuery,
} from '@material-ui/core';
import { Duration } from 'luxon';

const STEP = 100; // ms

const useStyles = makeStyles((theme) => ({
	wrapper: {
		position: 'relative',
	},
	circle: {
		position: 'relative',
		zIndex: 2,
	},
	track: {
		borderWidth: '6px',
		borderStyle: 'solid',
		borderColor: '#BDBDBD',
		width: 'calc(100%)',
		height: 'calc(100%)',
		borderRadius: '100%',
		position: 'absolute',
		zIndex: 1,

		[theme.breakpoints.up('md')]: {
			borderWidth: '9px',
		},
	},
	number: {
		fontSize: '14px',
		fontWeight: 'normal',

		[theme.breakpoints.up('md')]: {
			fontSize: '24px',
		},
	},
}));

type UseCountdownIndicatorProps = {
	/** duration in ms e.g., 1000 = 1 second */
	duration: number;
	onUpdate: (timeLeft: number) => void;
	onComplete?: () => void;
};

const useCountdownIndicator = (props: UseCountdownIndicatorProps) => {
	const { duration, onUpdate, onComplete } = props;

	// @ts-ignore
	React.useEffect(timer, [duration, onUpdate, onComplete]);

	function timer() {
		const startTime = Date.now();
		let relevant = true;
		let timeLeft = duration;
		let lastUpdate = Infinity;

		requestAnimationFrame(function onFrame() {
			if (!relevant) {
				return;
			}

			timeLeft = Math.max(0, duration - (Date.now() - startTime));

			if (lastUpdate - timeLeft > STEP) {
				onUpdate(timeLeft);
				lastUpdate = timeLeft;
			}

			if (timeLeft <= 0) {
				onComplete?.();
			} else {
				requestAnimationFrame(onFrame);
			}
		});

		return () => (relevant = false);
	}
};

type CountdownIndicatorProps = {
	/** duration in ms e.g., 1000 = 1 second */
	duration: number;
	onComplete?: () => void;
	size?: number;
	thickness?: number;
};

export const CountdownIndicator: React.ComponentType<CountdownIndicatorProps> =
	(props: CountdownIndicatorProps) => {
		const { duration, onComplete, size, thickness } = props;
		const [timeLeft, setTimeLeft] = React.useState(duration);
		const classes = useStyles();

		const isDesktop = useMediaQuery<Theme>((theme) =>
			theme.breakpoints.up('md'),
		);

		const circleSize = size || isDesktop ? 120 : 60;
		const circleThickness = thickness || isDesktop ? 3 : 4;

		useCountdownIndicator({ duration, onUpdate: setTimeLeft, onComplete });

		const seconds = React.useCallback(() => {
			return Duration.fromMillis(timeLeft).toFormat('m:ss');
		}, [timeLeft]);

		return (
			<Box
				position="relative"
				display="inline-flex"
				className={classes.wrapper}
				style={{ width: circleSize }}
			>
				<CircularProgress
					color="primary"
					variant="determinate"
					value={(timeLeft / duration) * 100}
					size={circleSize}
					thickness={circleThickness}
					className={classes.circle}
				/>
				<Box
					top={0}
					left={0}
					bottom={0}
					right={0}
					position="absolute"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<div className={classes.track} />
					<Typography
						className={classes.number}
						variant="h3"
						component="div"
						color="textPrimary"
					>
						{seconds()}
					</Typography>
				</Box>
			</Box>
		);
	};
