import React, { useMemo } from 'react';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { CssBaseline, ThemeProvider, createTheme } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';

import { firebase } from '../utils/firebase';
import { appTheme } from '../styles';
import { CurrentUserProvider, ConfigProvider } from '../contexts';
import { createFirestoreInstance } from 'redux-firestore';
import { oldRemoteSocialTheme } from '../styles/oldRsTheme';
import { ErrorBoundary } from './errorBoundary';
import { AccountsProvider } from '../contexts/Account';

const rffConfig = {};

const BaseProviders = ({
	store,
	appConfig,
	theme /* allow for overriding the base theme temporarily on per-app basis */,
	children,
}) => {
	const appliedTheme = useMemo(
		() =>
			theme ||
			(appConfig &&
				createTheme(appTheme(appConfig)(oldRemoteSocialTheme))),
		[appConfig, theme],
	);
	return (
		<ErrorBoundary>
			<Provider store={store}>
				<ReactReduxFirebaseProvider
					firebase={firebase}
					config={rffConfig}
					dispatch={store.dispatch}
					createFirestoreInstance={createFirestoreInstance}
				>
					<CurrentUserProvider>
						<AccountsProvider>
							<ConfigProvider config={appConfig}>
								<ThemeProvider theme={appliedTheme}>
									<CssBaseline />
									<BrowserRouter>{children}</BrowserRouter>
								</ThemeProvider>
							</ConfigProvider>
						</AccountsProvider>
					</CurrentUserProvider>
				</ReactReduxFirebaseProvider>
			</Provider>
		</ErrorBoundary>
	);
};

export default BaseProviders;
