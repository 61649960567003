import React from 'react';
import { useCurrentUser, useLoadable, useSessionStorage } from '../hooks';
import { useFirebase } from 'react-redux-firebase';
import orderBy from 'lodash/orderBy';
import { registerError } from '../errors';

export const AccountsContext = React.createContext();

const fetchAccountsIfLoggedIn = async ({ firebase, uid }) => {
	if (!uid) {
		return [];
	}
	const { data } = await firebase
		.functions()
		.httpsCallable('platform-getAccounts')({ role: 'member' });

	// TODO: Remove when backend is fixed
	const accounts = data.map((account) => ({
		...account,
		accountID: account.id,
	}));

	return orderBy(
		accounts,
		[(account) => account.owner === uid, 'name'],
		['desc', 'desc'],
	);
};

export const createAccountsApi = ({
	currentAccountId,
	firebase,
	setCurrentAccountId,
	reloadAccounts,
}) => {
	const getAccounts = async () => {
		const { data } = await firebase
			.functions()
			.httpsCallable('platform-getAccounts')({ role: 'member' });
		return data;
	};
	const createAccount = async (accountData) => {
		const { data: account } = await firebase
			.functions()
			.httpsCallable('platform-createAccount')(accountData);
		// the best case would be to just add account to an array locally
		// but that would require us to lift accounts to state variable
		// and some additional complexities - which we wouldn't have if we had redux
		// just reload until we migrate to redux
		reloadAccounts();
		// switch to this new account
		setCurrentAccountId(account.id);
		return account;
	};
	return {
		setCurrentAccount: async (id) => {
			setCurrentAccountId(id);
		},
		createAccount,
		createFirstAccount: async (accountData) => {
			// create an error beforehand to capture original stack trace
			const error = new Error('Duplicate account creation attempt');
			// ensure we do not create duplicate accounts
			const accounts = await getAccounts();
			if (accounts.length === 0) {
				await createAccount(accountData);
			} else {
				// register in Sentry to catch other cases when duplicate accounts are
				// created
				registerError(error);
			}
		},
		deleteAccount: async (id) => {
			await firebase.functions().httpsCallable('platform-deleteAccount')({
				accountID: id,
			});
			reloadAccounts();
		},
		/**
		 * @param accountId {string}
		 */
		leaveAccount: async (accountId) => {
			await firebase.functions().httpsCallable('platform-leaveAccount')({
				accountId,
			});
			reloadAccounts();
		},
		getAccountMembers: async (id = currentAccountId, role) => {
			return await firebase
				.functions()
				.httpsCallable('platform-getAccountMembers')({
				accountID: id,
				role,
			});
		},
		setAccountData: async (data, id = currentAccountId) => {
			return await firebase
				.functions()
				.httpsCallable('platform-editAccount')({
				...data,
				accountID: id,
			});
		},
		addAccountMember: async (id, uid, role = 'member') => {
			await firebase
				.functions()
				.httpsCallable('platform-addAccountMember')({
				accountID: id,
				uid,
				role,
			});
		},
		deleteAccountMember: async (uid) => {
			await firebase
				.functions()
				.httpsCallable('platform-deleteAccountMember')({
				accountID: currentAccountId,
				uid,
			});
		},
		getAccountMembersWithRolesAndInvites: async (accountId) => {
			return await firebase
				.functions()
				.httpsCallable('platform-getAccountMembersWithRolesAndInvites')(
				{
					accountId,
				},
			);
		},

		resendInviteEmail: async (inviteId) => {
			return await firebase
				.functions()
				.httpsCallable('platform-resendInviteEmail')({
				accountId: currentAccountId,
				inviteId,
			});
		},

		cancelInvite: async (inviteId) => {
			return await firebase
				.functions()
				.httpsCallable('platform-cancelInvite')({
				inviteId,
				accountId: currentAccountId,
			});
		},

		updateMemberRole: async (uid, role) => {
			await firebase
				.functions()
				.httpsCallable('platform-updateMemberRole')({
				accountId: currentAccountId,
				uid,
				role,
			});
		},
		createOrUpdateGameSchedule: async (data) => {
			await firebase
				.functions()
				.httpsCallable('platform-createOrUpdateGameSchedule')(data);
		},
		joinAccount: async (data) => {
			const {
				data: { accountId },
			} = await firebase
				.functions()
				.httpsCallable('platform-joinAccount')(data);
			reloadAccounts();
			setCurrentAccountId(accountId);
		},
		createCustomTriviaCategory: async (categoryData) => {
			return await firebase
				.functions()
				.httpsCallable('trivia-createCustomTriviaCategory')({
				...(categoryData || {}),
				accountID: currentAccountId,
			});
		},
		updateCustomTriviaCategory: async (data) => {
			return await firebase
				.functions()
				.httpsCallable('trivia-updateCustomTriviaCategory')({
				...data,
				accountID: currentAccountId,
			});
		},
		fetchCustomTriviaCategories: async () => {
			return await firebase
				.functions()
				.httpsCallable('trivia-getCustomTriviaCategories')({
				accountID: currentAccountId,
			});
		},
		fetchCustomCategory: async (categoryID) => {
			return await firebase
				.functions()
				.httpsCallable('trivia-getCustomTriviaCategory')({
				accountID: currentAccountId,
				categoryID,
			});
		},
		addCustomCategoryQuestion: async (categoryID, data = {}) => {
			return await firebase
				.functions()
				.httpsCallable('trivia-addCategoryQuestion')({
				accountID: currentAccountId,
				categoryID,
				...data,
			});
		},
		deleteCustomCategoryQuestion: async (questionID) => {
			return await firebase
				.functions()
				.httpsCallable('trivia-deleteCategoryQuestion')({
				accountID: currentAccountId,
				questionID,
			});
		},
		deleteCustomTriviaCategory: async (categoryID) => {
			return await firebase
				.functions()
				.httpsCallable('trivia-deleteCustomTriviaCategory')({
				accountID: currentAccountId,
				categoryID,
			});
		},
		publishCustomTriviaCategory: async (categoryID, status) => {
			return await firebase
				.functions()
				.httpsCallable('trivia-publishCustomTriviaCategory')({
				accountID: currentAccountId,
				categoryID,
				status,
			});
		},
	};
};

const chooseDefaultAccountId = ({ owned, accounts }) => {
	if (owned.length > 0) {
		return owned[0].id;
	} else if (accounts.length > 0) {
		return accounts[0].id;
	} else {
		throw new Error('No accounts');
	}
};

let accountsLoadedOnce = false;

export const AccountsProvider = ({ children }) => {
	const user = useCurrentUser();
	const firebase = useFirebase();
	const [currentAccountId, setCurrentAccountId] =
		useSessionStorage('currentCircle');
	const { accounts, loading, error, refresh } = useLoadable(
		() => fetchAccountsIfLoggedIn({ firebase, uid: user.uid }),
		'accounts',
		// refresh accounts after number of accounts change
		[firebase, user.uid, user?.accounts?.length],
	);

	const methods = createAccountsApi({
		firebase,
		currentAccountId,
		setCurrentAccountId,
		reloadAccounts: refresh,
	});

	let owned,
		current,
		accountId = currentAccountId;

	if (!loading && accounts.length > 0) {
		owned = accounts.filter((a) => a.owner === user.uid);
		/*
		  handle scenario when accountId is set to account that doesn't exist anymore
		*/
		current = accountId && accounts.find((a) => a.id === accountId);
		if (!accountId || !current) {
			accountId = chooseDefaultAccountId({ owned, accounts });
			current = accounts.find((a) => a.id === accountId);
		}
	}

	React.useEffect(() => {
		if (!loading && currentAccountId !== accountId) {
			setCurrentAccountId(accountId);
		}
	}, [currentAccountId, setCurrentAccountId, accountId, loading]);

	React.useEffect(() => {
		if (!loading && user.isLoaded && !accountsLoadedOnce) {
			// both user and accounts have finished loading
			window.dataLayer.push({
				event: 'ANALYTICS/ACCOUNTS_LOADED_FIRST_TIME',
			});
			accountsLoadedOnce = true;
		}
	}, [loading, user.isLoaded]);

	React.useEffect(() => {
		const dataLayer = window.dataLayer || [];
		// send user and accountId to Google Analytics for Hotjar reporting.
		if (
			!loading &&
			current &&
			!dataLayer.find((data) => data.accountId === accountId)
		) {
			dataLayer.push({
				event: 'ANALYTICS/CURRENT_ACCOUNT_LOADED',
				accountId,
				accountRole: current.role,
			});
		}
	}, [loading, current, accountId]);

	const value =
		!user.isLoaded || !user.isAuthenticated
			? {
					...methods,
					refresh,
					currentAccount: undefined,
					currentAccountId,
					accounts: [],
					owned: [],
					error: user.error,
					isLoaded: false,
					loadingAccounts: true,
			  }
			: {
					...methods,
					refresh,
					currentAccountId,
					currentAccount: current,
					accounts: accounts || [],
					owned: owned || [],
					error,
					isLoaded: !loading && current,
					/*
						TODO: This is to be split into:
						- useAccounts() - state related to user accounts -> Account[]
						- useCurrentAccount() - state related to current user account -> Account
						- useNeedsAccountCreated() - logic to determine if new account needs to be created
						- useAccountsApi() - API's only, minimum state

						And refactored to use useBackend or useAsyncFunction

						But for now:
					 */
					loadingAccounts: loading,
					...(!loading &&
						!error && {
							needsAccountCreated: accounts.length === 0,
						}),
			  };

	return (
		<AccountsContext.Provider value={value}>
			{children}
		</AccountsContext.Provider>
	);
};
