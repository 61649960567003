import React from 'react';
import { useCurrentUser } from '../../hooks';
import { LoggedInLinks } from './loggedInLinks';
import { LoggedOutLinks } from './loggedOutLinks';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AppBar, Hidden, Toolbar, Button, IconButton } from '@material-ui/core';
import Logo from '../RemoteSocialLogo';
import clsx from 'clsx';
import { CommonThemeProvider } from '../../styles/commonThemeProvider';
import { env } from '../../environment';

export const headerBackgroundColor = (theme: Theme) => {
	return theme.palette.common.white;
};

const useStyles = makeStyles((theme) => {
	return createStyles({
		header: {
			color: theme.palette.text.primary,
			fill: theme.palette.text.primary,
			background: headerBackgroundColor(theme),
		},
		toolbar: {
			display: 'grid',
			gridAutoFlow: 'column',
			gridAutoColumns: 'auto 1fr auto',
			justifyContent: 'space-between',

			[theme.breakpoints.down('xs')]: {
				display: 'grid',
				gridAutoColumns: '1fr auto 1fr',
				justifyContent: 'stretch',
			},
		},
		logo: {
			width: '2.25rem',
			height: '2.25rem',
			fill: 'inherit',
			justifySelf: 'flex-start',

			[theme.breakpoints.up('sm')]: {
				width: 'auto',
			},
		},
		logoCol: {
			// small screens
			justifySelf: 'center',
			gridColumn: '2 / span 1',
			[theme.breakpoints.up('sm')]: {
				// large screens
				justifySelf: 'start',
				gridColumn: '1 / span 1',
			},
		},
		centerCol: {
			position: 'relative',
		},
		linksCol: {
			justifySelf: 'end',
			gridColumnStart: 3,
		},
		fabContainer: {
			position: 'absolute',
			top: theme.spacing(1.5),
			left: 0,
		},
	});
});

type UserStatus = 'loading' | 'logged-in' | 'logged-out';

const determineUserStatus = (
	user: ReturnType<typeof useCurrentUser>,
): UserStatus => {
	if (user.isLoaded) {
		return user.isAuthenticated ? 'logged-in' : 'logged-out';
	} else {
		return 'loading';
	}
};

const Links = ({ status }: { status: UserStatus }) => (
	<>
		{status === 'logged-in' && <LoggedInLinks />}
		{status === 'logged-out' && <LoggedOutLinks />}
	</>
);

type Props = {
	className?: string;
};

const HeaderInternal: React.ComponentType<Props> = ({
	className,
	children,
}) => {
	const classes = useStyles();
	const user = useCurrentUser();
	const status = determineUserStatus(user);

	return (
		<AppBar className={clsx(classes.header, className)} position="static">
			<Toolbar className={classes.toolbar}>
				<div className={classes.logoCol}>
					<Hidden smUp>
						<IconButton href={env().hubUrl} color="inherit">
							<Logo className={classes.logo} variant="mini" />
						</IconButton>
					</Hidden>
					<Hidden xsDown>
						<Button
							href={env().hubUrl}
							color="inherit"
							variant="text"
						>
							<Logo className={classes.logo} variant="text" />
						</Button>
					</Hidden>
				</div>
				<div className={classes.centerCol}>
					<div className={classes.fabContainer}>{children}</div>
				</div>
				<div className={classes.linksCol}>
					<Links status={status} />
				</div>
			</Toolbar>
		</AppBar>
	);
};

export const Header: React.ComponentType<Props> = (props) => (
	<CommonThemeProvider>
		<HeaderInternal {...props} />
	</CommonThemeProvider>
);
