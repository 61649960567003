import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button, ErrorMessage } from '.';
import { useMountedRef, useAuth, GOOGLE, FACEBOOK } from '../hooks';

// material-ui
import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { registerError } from '../errors';

const useStyles = makeStyles((theme) => ({
	socialButton: {
		margin: theme.spacing(1, 0, 2),
		width: '100%',
		color: '#FFFFFF',
		background: '#444444',
	},
}));

export const SocialErrorMessage = ({ error, nextUrl }) => {
	const history = useHistory();
	const auth = useAuth();
	if (!error) {
		return null;
	}
	switch (error.code) {
		case 'auth/popup-closed-by-user':
		case 'auth/user-cancelled':
			return <ErrorMessage error={error}>Login cancelled.</ErrorMessage>;

		case 'auth/credential-already-in-use': // another user is linked to this provider+account
		case 'auth/account-exists-with-different-credential': // the email address for the provider+account is linked to another user
		case 'auth/email-already-in-use': // same as above
			// TODO: actually merge the anonymous user data with the existing user data.
			// But for now, they lose the anonymous data and log in as the existing user.
			// const { email, credential } = error;

			const badCred = error.code === 'auth/credential-already-in-use';
			return (
				<ErrorMessage error={error}>
					{badCred
						? 'That account is already linked to another user.'
						: 'The email address on that account is already linked to another user.'}
					Please try{' '}
					<Link
						component="button"
						onClick={async () => {
							await auth.login(error.credential);
							nextUrl && history.push(nextUrl);
						}}
					>
						logging in
					</Link>
					.
				</ErrorMessage>
			);

		default:
			return <ErrorMessage error={error} />;
	}
};

const BUTTON_MODES = ['login', 'link', 'unlink'];

/**
 * @param {object} obj - function param object
 * @param {import('../../../contracts/src/platform/user').UserMarketing} obj.userAcquisitionData
 * */
export const SocialLoginButton = ({
	setError,
	onComplete,
	mode = 'login',
	provider,
	nextUrl,
	className,
	userAcquisitionData,
	...props
}) => {
	const history = useHistory();
	const auth = useAuth();

	const classes = useStyles();

	const [loading, setLoading] = React.useState(false);

	const mounted = useMountedRef();

	const loginWithSocial = async (provider) => {
		try {
			setLoading(true);
			setError(null);

			switch (mode) {
				case 'link':
					await auth.linkWithPopup(provider);
					break;
				case 'login':
					await auth.loginOrCreateUserWithPopup({
						providerKey: provider,
						userAcquisitionData,
					});
					break;
				case 'unlink':
					await auth.unlink(provider);
					break;
				default:
					throw new Error(
						'Invalid mode ' +
							mode +
							'. Valid modes are: ' +
							BUTTON_MODES,
					);
			}
			// onComplete && onComplete();
			nextUrl && history.push(nextUrl);
		} catch (error) {
			registerError(error);
			mounted.current && setError(error);
		} finally {
			mounted.current && setLoading(false);
		}
	};

	return (
		<Button
			{...props}
			click={() => {
				loginWithSocial(provider);
			}}
			text={
				provider === GOOGLE
					? 'Google'
					: provider === FACEBOOK
					? 'Facebook'
					: 'Unknown provider'
			}
			loading={loading}
			className={clsx(classes.socialButton, className)}
			fullWidth
		/>
	);
};
