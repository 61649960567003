import React from 'react';
import { Button } from '@remote-social/common';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	cta: {
		borderRadius: '4px',
		fontSize: 24,
		lineHeight: '31px',
		textTransform: 'uppercase',
		width: '100%',
		//marginBottom: 0
	},
}));

export const CTAButton = ({
	className,
	children,
	href,
	to,
	onClick,
	disabled,
}) => {
	const classes = useStyles();
	return (
		<Button
			href={href}
			to={to}
			variant="contained"
			fullWidth
			className={clsx(classes.cta, className)}
			disabled={disabled}
			onClick={onClick}
		>
			{children}
		</Button>
	);
};
