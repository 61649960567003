import React from 'react';

import Login from './Login';
import Register from './register';
import RegisterUpgrade from './RegisterUpgrade';
import ResetPassword from './ResetPassword';
import Profile from './Profile';
import VerifyEmail from './VerifyEmail';
import SelectAccount from './SelectAccount';
import { SelectCircle } from './selectCircle';
import HandleLink from './HandleLink';
import Onboard from './Onboard';
import Create from './Create';
import Welcome from './Welcome';
import { HandleInvite } from './HandleInvite';
import { Container, Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { CommonThemeProvider } from '../../styles/commonThemeProvider';

const useStyles = makeStyles((theme) => ({
	container: {},
	paper: {
		padding: '1rem',
		borderRadius: '2rem',
		border: `1px solid ${theme.palette.text.hint}`,
		boxShadow: `0 0 1rem ${theme.palette.text.hint}`,
		width: '100%',
		position: 'relative',
	},
	screenContainer: {
		padding: '1rem',
	},
}));

// this is to be removed later or replaced with ScreenContainer
// after testing for compatibility with older apps
const DefaultScreenContainer = ({ variant, children }) => {
	const styles = useStyles();
	const maxWidth = variant === 'two-column' ? 'md' : 'xs';
	return (
		<Container maxWidth={maxWidth} fixed className={styles.container}>
			<Grid
				container
				spacing={0}
				direction="column"
				alignItems="center"
				justifyContent="center"
				style={{ minHeight: 'calc(100vh - 184px)' }}
			>
				<Paper elevation={0} className={styles.paper}>
					{children}
				</Paper>
			</Grid>
		</Container>
	);
};

export default function Auth({
	variant,
	containerVariant = 'two-column',
	ScreenContainer: ScreenContainerProp,
	...rest
}) {
	const styles = useStyles();
	const renderAuth = (variant) => {
		switch (variant) {
			case 'HandleLink':
				return <HandleLink {...rest} />;
			case 'HandleInvite':
				return <HandleInvite {...rest} />;
			case 'Register':
				return <Register {...rest} />;
			case 'RegisterUpgrade':
				return <RegisterUpgrade {...rest} />;
			case 'ResetPassword':
				return <ResetPassword {...rest} />;
			case 'Profile':
				return <Profile {...rest} />;
			case 'SelectAccount':
				return <SelectAccount {...rest} />;
			case 'SelectCircle':
				return <SelectCircle {...rest} />;
			case 'VerifyEmail':
				return <VerifyEmail {...rest} />;
			case 'Login':
				return <Login {...rest} />;
			case 'Create':
				return <Create {...rest} />;
			case 'Welcome':
				return <Welcome {...rest} />;
			case 'Onboard':
			default:
				return <Onboard {...rest} />;
		}
	};

	return (
		<CommonThemeProvider oldTheme>
			{ScreenContainerProp ? (
				<ScreenContainerProp
					variant={containerVariant}
					className={styles.screenContainer}
				>
					{renderAuth(variant)}
				</ScreenContainerProp>
			) : (
				<DefaultScreenContainer variant={containerVariant}>
					{renderAuth(variant)}
				</DefaultScreenContainer>
			)}
		</CommonThemeProvider>
	);
}
