import React, { useContext, useRef, useState } from 'react';
import {
	Dialog,
	useMediaQuery,
	Theme,
	makeStyles,
	createStyles,
	IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { changeOpacity } from '../utils';

const useStyles = makeStyles((theme) =>
	createStyles({
		closeButtonContainer: {
			position: 'absolute',
			top: theme.spacing(1),
			right: theme.spacing(6),
		},
		closeButton: {
			position: 'fixed',
			padding: theme.spacing(1),
			zIndex: theme.zIndex.modal + 1,
			backgroundColor: changeOpacity(
				theme.palette.background.default,
				0.7,
			),
		},
		paper: {
			padding: theme.spacing(0),
			borderRadius: theme.spacing(3),

			[theme.breakpoints.down('xs')]: {
				margin: theme.spacing(0),
				borderRadius: theme.spacing(0),
			},
		},
	}),
);

type Props = React.ComponentProps<typeof Dialog> & {
	variant: 'single-column' | 'two-column';
	onClose: () => void;
};

const Context = React.createContext<HTMLElement | undefined>(undefined);

export const useCommonDialogueScrollContainer = () => {
	return useContext(Context);
};

function findScrollContainer(
	dialogue?: HTMLElement | null,
	scroll?: Props['scroll'],
) {
	if (!dialogue) {
		return null;
	}
	return scroll === 'body'
		? dialogue.querySelector<HTMLElement>('.MuiDialog-paperScrollBody') ||
				dialogue.querySelector<HTMLElement>('.MuiDialog-container')
		: dialogue.querySelector<HTMLElement>('.MuiDialogContent-root') ||
				dialogue.querySelector<HTMLElement>('.MuiPaper-root');
}

export const CommonDialog: React.ComponentType<Props> = ({
	variant,
	children,
	...rest
}) => {
	const styles = useStyles();
	const isExtraSmall = useMediaQuery<Theme>((theme) =>
		theme.breakpoints.down('xs'),
	);
	const maxWidth = variant === 'two-column' ? 'md' : 'xs';

	// locate the dialogue container which is the scroll locking container
	const dialogueRef = useRef<HTMLElement | null>(null);
	const [scrollContainer, setScrollContainer] = useState(
		findScrollContainer(dialogueRef.current, rest.scroll),
	);

	const dialogue = dialogueRef.current;
	React.useLayoutEffect(() => {
		if (!dialogue) {
			return;
		}
		const container = findScrollContainer(dialogue, rest.scroll);
		setScrollContainer(container);
	}, [dialogue, rest.scroll]);

	return (
		<Dialog
			{...(!isExtraSmall && { maxWidth })}
			{...rest}
			PaperProps={{ className: styles.paper }}
			fullScreen={isExtraSmall}
			ref={dialogueRef}
		>
			<Context.Provider value={scrollContainer ?? undefined}>
				<div className={styles.closeButtonContainer}>
					<IconButton
						onClick={rest.onClose}
						className={styles.closeButton}
					>
						<CloseIcon />
					</IconButton>
				</div>
				{children}
			</Context.Provider>
		</Dialog>
	);
};
