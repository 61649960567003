import React, { useMemo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import {
	List,
	ListItem,
	ListItemText,
	ListItemAvatar,
	Avatar,
	ListItemSecondaryAction,
	Grid,
	Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Layout from '../Layout';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import {
	Loading,
	ErrorMessage,
	Button,
	DocumentHead,
} from '@remote-social/common';
import { GameType } from '@contracts/platform';
import { envHost } from '@remote-social/common/src/utils/envLink';

const useStyles = makeStyles((theme) => ({
	header: {
		fontWeight: 800,
		fontSize: 36,
		margin: theme.spacing(4, 'auto'),
		textAlign: 'center',
	},
	paragraph: {
		marginBottom: theme.spacing(2),
	},
	leaderboard: {
		marginBottom: theme.spacing(6),
	},
	winnerEntry: {
		//color: theme.palette.common.black
	},
	playerEntry: {
		//color: theme.palette.common.black
	},
}));

const useFirestoreInfoFor = (storeAs, prop = 'data') => {
	return {
		loading: useSelector(
			(state) => state.firestore.status.requesting[storeAs],
		),
		error: useSelector((state) => state.firestore.errors[storeAs]),
		[prop]: useSelector((state) => state.firestore.data[storeAs]),
	};
};

const byScoreLookup =
	(scores) =>
	([aID], [bID]) => {
		return (scores[bID] || 0) - (scores[aID] || 0);
	};
function Leaderboard({
	className,
	title,
	players,
	scores,
	renderScore = (a) => a | 0,
}) {
	const classes = useStyles();
	const entries = Object.entries(players);
	return (
		<div className={className}>
			<Typography variant="h2">{title}</Typography>
			{entries.length ? (
				<List>
					{entries
						.sort(byScoreLookup(scores))
						.map(([playerID, player], index) => (
							<ListItem key={playerID}>
								<ListItemAvatar>
									<Avatar
										variant="square"
										src={player.photoURL}
									/>
								</ListItemAvatar>
								<ListItemText primary={player.displayName} />
								<ListItemSecondaryAction>
									<Typography
										className={
											index === 0
												? classes.winnerEntry
												: classes.playerEntry
										}
									>
										{renderScore(scores[playerID])}
									</Typography>
								</ListItemSecondaryAction>
							</ListItem>
						))}
				</List>
			) : (
				<Typography variant="body2">No scores yet!</Typography>
			)}
		</div>
	);
}

const addScores = (runs, reps = 1) =>
	Object.values(runs).reduce((scores, run) => {
		if (!run.reactions) {
			return {};
		}
		for (const [playerID, reactionCount] of Object.entries(run.reactions)) {
			if (!scores[playerID]) {
				scores[playerID] = 0;
			}
			if (reactionCount > 0) {
				scores[playerID] += reps;
			}
		}
		return scores;
	}, {});

const useGame = (accountID, gameID) => {
	const storeAs = accountID + '-' + gameID;
	useFirestoreConnect({
		storeAs,
		collection: 'accounts',
		doc: accountID,
		subcollections: [
			{
				collection: 'games',
				doc: gameID,
			},
		],
	});
	return useFirestoreInfoFor(storeAs, 'game');
};

const useExercises = () => {
	const storeAs = 'physical-exercises';
	useFirestoreConnect({
		storeAs,
		collection: 'games',
		doc: 'physical',
		subcollections: [
			{
				collection: 'exercises',
			},
		],
	});
	return useFirestoreInfoFor(storeAs, 'exercises');
};

const Emoji = ({ char, label, className }) => (
	<span className={className} role="img" aria-label={label}>
		{char}
	</span>
);

const ScoresPage = ({ accountID, gameID }) => {
	const classes = useStyles();
	const [error, setError] = useState(null);
	const { loading, error: gameError, game } = useGame(accountID, gameID);
	const {
		loading: loadingExercises,
		error: exercisesError,
		exercises,
	} = useExercises(game);

	const { playersData, rounds, currentRound, otherRounds, totalScores } =
		useMemo(() => {
			if (
				!game ||
				!exercises ||
				game.gameType !== GameType.physical ||
				!game.roundData
			) {
				return {};
			}
			const playersData = Object.fromEntries(
				Object.entries(game.playersData).filter(
					([k]) => k !== game.host,
				),
			);
			const rounds = Object.entries(game.roundData).map(
				([date, { runs, activity, activityID }]) => {
					const exercise =
						exercises && activityID
							? exercises[activityID]
							: activity;
					return {
						current: date === game.currentRound,
						date,
						runs,
						activity: {
							id: activityID,
							...exercise,
						},
						scores: addScores(runs, exercise.amount || 1),
					};
				},
			);
			const currentRound = rounds.find((r) => r.current);
			const otherRounds = rounds
				.filter((r) => r !== currentRound)
				.sort((a, b) => a.date.localeCompare(b.date));

			const totalScores = rounds.reduce(
				(scores, { activity, runs, scores: roundScores }) => {
					const totalRepsPossible =
						(activity.amount || 1) * Object.keys(runs).length;
					for (const [playerID, score] of Object.entries(
						roundScores,
					)) {
						if (!scores[playerID]) {
							scores[playerID] = 0;
						}
						scores[playerID] +=
							score / totalRepsPossible / rounds.length;
					}
					return scores;
				},
				{},
			);

			return {
				playersData,
				rounds,
				currentRound,
				otherRounds,
				totalScores,
			};
		}, [game, exercises]);

	useEffect(() => {
		if (game && game.gameType !== GameType.physical) {
			setError(new Error("That game isn't a physical challenge."));
		} else if (game && !game.roundData) {
			setError(new Error('That challenge was never configured.'));
		}
	}, [game]);

	if (error || gameError || exercisesError) {
		return <ErrorMessage error={error || gameError || exercisesError} />;
	}
	if (loading || loadingExercises || !game || !exercises) {
		return <Loading />;
	}

	if (rounds.length === 1 && 0 === Object.keys(currentRound.runs).length) {
		return (
			<>
				<DocumentHead title="Your challenge is starting" />
				<Typography variant="h2" className={classes.header}>
					A challenge awaits...
				</Typography>
				<Typography variant="body1" className={classes.paragraph}>
					Your challenge hasn't started yet. When it does, come back
					to see how you fared against the competition.
				</Typography>
				<Typography variant="body1" className={classes.paragraph}>
					...if you can still click your mouse.{' '}
					<Emoji char="😈" label="smiling face with horns" />
				</Typography>
				<Typography variant="body1" className={classes.paragraph}>
					Next up: {currentRound.activity.name}
					{currentRound.activity.amount === 1
						? null
						: `, ${currentRound.activity.amount} ${currentRound.activity.unit}`}
				</Typography>
				<Button href="https://hub.remotesocial.io" fullWidth>
					Return to the Experience Hub
				</Button>
			</>
		);
	}

	return (
		<>
			<DocumentHead title="Your challenge" />
			{currentRound ? (
				<Leaderboard
					className={classes.leaderboard}
					title={"Today's scores - " + currentRound.activity.name}
					players={playersData}
					scores={currentRound.scores}
				/>
			) : null}
			<Leaderboard
				className={classes.leaderboard}
				title="Percent completion"
				players={playersData}
				scores={totalScores}
				renderScore={(s) => ((100 * s) | 0) + '%'}
			/>

			{otherRounds.map((round) => (
				<Leaderboard
					className={classes.leaderboard}
					key={round.date}
					title={`${
						DateTime.fromISO(round.date).weekdayLong
					}'s scores - ${round.activity.name}`}
					players={playersData}
					scores={round.scores}
				/>
			))}
			<Button href={envHost()} fullWidth>
				Return to the Experience Hub
			</Button>
		</>
	);
};

function Game() {
	const { account: accountID, game: gameID } = useParams();

	// game ID is missing
	if (!gameID) {
		return (
			<Layout>
				<Grid item xs={12}>
					<Typography component="h4" variant="h4" align="center">
						Game ID Missing!
					</Typography>
				</Grid>
			</Layout>
		);
	}

	return (
		<Layout>
			<ScoresPage accountID={accountID} gameID={gameID} />
		</Layout>
	);
}

export default Game;
