import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import {
	CircleSwitcher,
	DocumentHead,
	Loading,
	RemoteSocialLogo,
} from '../../components';
import { ErrorMessage } from '../../components/errorMessage';
import { useCreateGame } from '../../hooks';
import { useCurrentAccount } from '../../contexts';

const useStyles = makeStyles((theme) => ({
	brand: {
		height: '3rem',
		fill: theme.palette.text.primary,
	},
	heading: {
		marginTop: theme.spacing(2),
	},
}));

/** @deprecated use SelectCircle instead */
export default function SelectAccount({ createFunction, onSuccess }) {
	const {
		isLoaded,
		accounts,
		error: accountsLoadingError,
	} = useCurrentAccount();
	const { gameType } = useParams();
	const { error: createGameError, createGame } = useCreateGame({
		gameType,
		createFunction,
		onSuccess,
	});
	const classes = useStyles();

	const handleChange = useCallback(
		async ({ account }) => {
			await createGame(account.id);
		},
		[createGame],
	);

	const error = accountsLoadingError || createGameError;

	return (
		<Grid container direction="column" spacing={2}>
			<DocumentHead title="Select Circle" />
			<Grid item xs={12} align="center">
				<RemoteSocialLogo className={classes.brand} />
				<Typography
					component="h5"
					variant="h5"
					align="center"
					className={classes.heading}
				>
					SELECT CIRCLE
				</Typography>
			</Grid>
			<Grid item>
				{!isLoaded ? (
					<Loading />
				) : (
					<CircleSwitcher
						selectedAccountID={''}
						accounts={accounts}
						onChange={handleChange}
						fullWidth
						variant={'outlined'}
					/>
				)}
				{error && <ErrorMessage error={error} />}
			</Grid>
		</Grid>
	);
}
