import * as Sentry from '@sentry/browser';
import { ensureError } from './types';
import { isDevBuild } from '../environment';
import { registerErrorOnce } from './registerErrorOnce';

/**
 * Register an error if you want to track its stats in Sentry
 * but you are going to swallow it. E.g.
 *
 * try {
 *   return await doThings();
 * } catch(err) {
 *   registerError(err); // <- do this unless you want to totally ignore this error
 *   return fallbackValue;
 * }
 *
 * @param error Caught error
 */
export function registerError(error: any) {
	registerErrorOnce(error, () => {
		if (isDevBuild()) {
			console.error(error);
			return;
		}
		Sentry.captureException(error, (scope) => {
			const err = ensureError(error);
			if (err.code) {
				scope.setTag('errorCode', err.code);
			}
			return scope;
		});
	});
}
