import React from 'react';
import { Typography, makeStyles, SvgIcon } from '@material-ui/core';
import { Link } from '@material-ui/core';
import { ErrorMessage, LoadingButton, ScreenContainer } from '../components';
import { AnyRequestState } from '../store-tools/requestState';
import { CommonThemeProvider } from '../styles/commonThemeProvider';
import { UnreachableError } from '../errors';
import { useBackendFunction } from '../hooks/useBackendFunction';
import { isError, isPending, isSuccess } from '../store-tools';

import { ReactComponent as SlackLogo } from '../assets/images/slack-round.svg';
import { ReactComponent as RemoteSocialLogo } from '../assets/images/RS-Logo.svg';
import { env } from '../environment';

const useSlackUrl = (params: { accountId?: string; redirectUrl: string }) => {
	const { accountId, redirectUrl } = params;

	const generateRequest = useBackendFunction(
		'platform-generateSlackOAuthURL',
		{
			callOnChange: () => {
				if (!accountId || !redirectUrl) {
					return;
				}
				return {
					accountID: accountId,
					redirectURI: redirectUrl,
				};
			},
		},
	);

	return generateRequest;
};

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'grid',
		/* portrait layout */
		minHeight: theme.breakpoints.values.sm,

		padding: theme.spacing(4),

		placeItems: 'center',
		alignContent: 'space-between',
		gridTemplateRows: 'auto 1fr auto',

		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(2),

			placeItems: 'start',
			alignContent: 'start',
			gridTemplateRows: 'auto',
		},

		gap: theme.spacing(2),
	},
	botIntroRow: {
		display: 'grid',
		placeItems: 'center',
		justifyContent: 'center',
		gridAutoFlow: 'column',

		gap: theme.spacing(2),
	},
	botIcon: {
		objectFit: 'contain',
	},
}));

const SlackIcon = () => (
	<SvgIcon component={SlackLogo} viewBox="70 70 130 130" />
);

const RemoteSocialIcon: React.ComponentType<
	React.ComponentProps<typeof SvgIcon>
> = (props) => (
	<SvgIcon
		{...props}
		component={RemoteSocialLogo}
		viewBox="0 0 270 359"
		fontSize="large"
	/>
);

const determineBodyContent = ({ error }: AnyRequestState) => {
	if (!error) {
		return 'intro' as const;
	}
	if (
		error.code === 'permission-denied' ||
		// TODO: get rid of the second check as the first one should be enough
		error.details?.code === 'role-required'
	) {
		return 'only-owner-message' as const;
	}
	return 'generic-error' as const;
};

type Props = {
	accountId?: string;
	redirectUrl: string;

	// dependencies
	deps?: {
		useSlackUrl: typeof useSlackUrl;
	};
};

const SlackScreenBody: React.ComponentType<{
	bodyContent: ReturnType<typeof determineBodyContent>;
	request: ReturnType<typeof useSlackUrl>;
}> = ({ bodyContent, request }) => {
	switch (bodyContent) {
		case 'intro':
			return (
				<>
					<RemoteSocialIcon />
					<Typography variant="body1">
						We’ll add our Remote Social bot to your preferred Slack
						channel to manage the announcements for you.
					</Typography>
				</>
			);
		case 'only-owner-message':
			return (
				<>
					<RemoteSocialIcon color="disabled" />
					<Typography variant="body1">
						Only the owner of the account can link to Slack. Please
						ask them to set up the first activity, or use{' '}
						<Link
							href={`${env().hubUrl}#default-account`}
							color="primary"
						>
							your own account
						</Link>
						.
					</Typography>
				</>
			);
		case 'generic-error':
			return (
				<>
					<RemoteSocialIcon color="error" />
					<ErrorMessage error={request.error} />
				</>
			);
		default:
			throw new UnreachableError(bodyContent);
	}
};

export const ConnectToSlackScreen: React.ComponentType<Props> = ({
	accountId,
	redirectUrl,
	deps = { useSlackUrl },
}) => {
	const styles = useStyles();

	const request = deps.useSlackUrl({
		accountId,
		redirectUrl,
	});

	const bodyContent = determineBodyContent(request);

	return (
		<CommonThemeProvider>
			<ScreenContainer className={styles.container}>
				<Typography variant="h2">Connect to Slack</Typography>
				<div className={styles.botIntroRow}>
					<SlackScreenBody
						bodyContent={bodyContent}
						request={request}
					/>
				</div>
				<LoadingButton
					{...(isSuccess(request) && {
						href: request.data,
					})}
					{...(isError(request) && {
						onClick: request.initiate,
					})}
					disabled={bodyContent === 'only-owner-message'}
					loading={isPending(request)}
					variant="contained"
					color="primary"
					startIcon={<SlackIcon />}
				>
					{isError(request) ? 'Try Again' : 'Connect to Slack'}
				</LoadingButton>
			</ScreenContainer>
		</CommonThemeProvider>
	);
};
