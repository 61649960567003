import React from 'react';

import { GOOGLE, FACEBOOK } from '../../hooks';

// material-ui
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
	SocialErrorMessage,
	SocialLoginButton,
} from '../../components/SocialLoginButton';

const useStyles = makeStyles(() => ({
	orText: {
		fontWeight: 'bold',
	},
}));

export default function SocialLogin({
	mode,
	nextUrl,
	className,
	userAcquisitionData,
}) {
	const classes = useStyles();

	const [socialAuthError, setSocialAuthError] = React.useState();

	return (
		<React.Fragment>
			{socialAuthError ? (
				<Grid item xs={12} align="center">
					<SocialErrorMessage
						error={socialAuthError}
						nextUrl={nextUrl}
					/>
				</Grid>
			) : null}
			<Grid container direction="row" spacing={1}>
				<Grid item xs={6} align="center">
					<SocialLoginButton
						mode={mode}
						setError={setSocialAuthError}
						provider={GOOGLE}
						className={className}
						nextUrl={nextUrl}
						userAcquisitionData={userAcquisitionData}
					/>
				</Grid>
				<Grid item xs={6} align="center">
					<SocialLoginButton
						mode={mode}
						setError={setSocialAuthError}
						provider={FACEBOOK}
						className={className}
						nextUrl={nextUrl}
						userAcquisitionData={userAcquisitionData}
					/>
				</Grid>
			</Grid>
			<Grid item xs={12} align="center">
				<Typography
					component="p"
					variant="body1"
					align="center"
					className={classes.orText}
				>
					or
				</Typography>
			</Grid>
		</React.Fragment>
	);
}
