import { ErrorType } from '.';

export function userFriendlyErrorMessage(error: ErrorType): string | undefined {
	// there is no apparent way to do instanceof
	if (error.code && error.message && error.code.startsWith('auth/')) {
		// we trust Firebase Auth Error Messages to be
		// good enough to be shown on UI
		return error.message;
	}
	// if this is an HttpError from firebase
	if (error.code) {
		return 'Unable to complete request, please try again later';
	}
	return undefined;
}
