import React from 'react';
import {
	LoadingButton,
	DocumentHead,
	RemoteSocialLogo,
	LinkButton,
	ErrorMessage,
} from '../../components';
import { useSpring, animated } from 'react-spring';
import { contentAnimation, gifAnimation } from './animation';
import { useHistory } from 'react-router-dom';
import { Grid, Hidden, Typography, makeStyles } from '@material-ui/core';
import { useCurrentAccount } from '../../contexts';
import { useAuth, useQuery } from '../../hooks';
import { useAsyncFunction } from '../../hooks/useAsyncFunction';
import { isError, isPending, isSuccess } from '../../store-tools';
import { UnreachableError } from '../../errors';

const useStyles = makeStyles((theme) => ({
	videoContainer: {
		position: 'relative',
		width: '100%',
		minHeight: '550px',
		background: '#F5F6F8',
		borderRadius: '1.5rem',
		overflow: 'hidden',
	},
	video: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},
	brand: {
		height: '3rem',
		marginTop: theme.spacing(3),
	},
	title: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(3),
	},
	subtitle: {
		marginBottom: theme.spacing(5),
		marginTop: theme.spacing(6),
	},
	mainSection: {
		height: '100%',
		display: 'grid',

		placeItems: 'center',
		alignContent: 'space-between',
		gridTemplateRows: 'auto 1fr repeat(2, auto)',

		[theme.breakpoints.down('xs')]: {
			placeItems: 'start',
			alignContent: 'start',
			gridTemplateRows: 'auto',
		},

		gap: theme.spacing(2),

		padding: theme.spacing(1),
	},
	noWrap: {
		whiteSpace: 'no-wrap',
	},
}));

const NoWrap = ({ children }) => {
	const styles = useStyles();
	return <span className={styles.noWrap}>{children}</span>;
};

const PageLoadingButton = (props) => (
	<LoadingButton variant="contained" disableElevation fullWidth {...props} />
);

const PageLinkButton = (props) => (
	<LinkButton variant="contained" disableElevation fullWidth {...props} />
);

const GenericContent = ({ title, subTitle, children }) => {
	const classes = useStyles();
	return (
		<>
			<div>
				<Typography
					variant="h4"
					align="center"
					className={classes.title}
				>
					{title}
				</Typography>
				{subTitle && (
					<Typography
						variant="h5"
						align="center"
						className={classes.subtitle}
					>
						{subTitle}
					</Typography>
				)}
			</div>
			{children}
		</>
	);
};

const JoinContent = ({ title, inviteId }) => {
	const history = useHistory();
	const { joinAccount } = useCurrentAccount();
	const joinAccountRequest = useAsyncFunction((data) =>
		joinAccount(data).then(() => {
			history.push('/');
		}),
	);

	return (
		<GenericContent title={title}>
			{isError(joinAccountRequest) && (
				<ErrorMessage error={joinAccountRequest.error} />
			)}
			<PageLoadingButton
				loading={isPending(joinAccountRequest)}
				onClick={() => joinAccountRequest.initiate({ inviteId })}
			>
				Join circle
			</PageLoadingButton>
		</GenericContent>
	);
};

const SwitchContent = ({ title, continueUrl, data }) => {
	const history = useHistory();
	const { logout } = useAuth();
	const redirectWithContinueUrl = (path) => {
		history.push(path, {
			from: continueUrl,
		});
	};
	const logoutAndRedirectRequest = useAsyncFunction((path) =>
		logout().then(
			() => redirectWithContinueUrl(path),
			(error) => console.error(error),
		),
	);

	return (
		<GenericContent
			title={title}
			subTitle={`You are not logged in with ${data.inviteEmail}`}
		>
			{isError(logoutAndRedirectRequest) && (
				<ErrorMessage error={logoutAndRedirectRequest.error} />
			)}
			<PageLoadingButton
				loading={isPending(logoutAndRedirectRequest)}
				onClick={() => logoutAndRedirectRequest.initiate('/login')}
			>
				Switch user
			</PageLoadingButton>
			<PageLoadingButton
				loading={isPending(logoutAndRedirectRequest)}
				onClick={() => logoutAndRedirectRequest.initiate('/register')}
			>
				Sign up
			</PageLoadingButton>
		</GenericContent>
	);
};

const LoginContent = ({ title, continueUrl, data }) => {
	return (
		<GenericContent
			title={title}
			subTitle={`Please login or signup with ${data.inviteEmail} to continue`}
		>
			<PageLinkButton
				to={{
					pathname: '/login',
					state: {
						from: continueUrl,
					},
				}}
			>
				Login
			</PageLinkButton>
			<PageLinkButton
				to={{
					pathname: '/register',
					state: {
						from: continueUrl,
					},
				}}
			>
				Sign up
			</PageLinkButton>
		</GenericContent>
	);
};

const HandleInviteContentSwitcher = ({ inviteId, data }) => {
	const continueUrl = `/handle-invite?inviteId=${inviteId}`;
	const title = (
		<>
			<NoWrap>{data.senderName}</NoWrap> has invited you to{' '}
			<NoWrap>{data.accountName}</NoWrap>
		</>
	);

	switch (data.nextAction) {
		case 'join-account':
			return <JoinContent title={title} inviteId={inviteId} />;
		case 'switch-user':
			return (
				<SwitchContent
					title={title}
					continueUrl={continueUrl}
					data={data}
				/>
			);
		case 'login':
			return (
				<LoginContent
					title={title}
					continueUrl={continueUrl}
					data={data}
				/>
			);
		default:
			throw new UnreachableError(data.nextAction);
	}
};

export function HandleInvite() {
	const classes = useStyles();
	const contentSpring = useSpring(contentAnimation);
	const gifSpring = useSpring(gifAnimation);
	const history = useHistory();
	const { handleInvitedPeople } = useAuth();
	const query = useQuery();

	const inviteId = query.get('inviteId') || null;

	React.useEffect(() => {
		if (!inviteId) {
			console.error('Invite details not found!');
			history.push('/login');
		}
	}, [history, inviteId]);

	const pageLoadRequest = useAsyncFunction(handleInvitedPeople, {
		callOnChange: () => {
			if (!inviteId) {
				return;
			}
			return {
				inviteId,
			};
		},
	});

	return (
		<Grid container spacing={2}>
			<DocumentHead title="Invitation" />
			<Hidden smDown>
				<Grid
					className={classes.videoContainer}
					item
					md={5}
					container
					spacing={0}
					direction="column"
					alignItems="center"
					justifyContent="center"
				>
					<animated.div style={gifSpring}>
						<img
							className={classes.video}
							alt="Hello"
							src="https://cdn.remotesocial.io/hub/img/Hello.gif"
						/>
					</animated.div>
				</Grid>
			</Hidden>
			<Grid item xs={12} md={1} />
			<Grid item xs={12} md={5}>
				<animated.div
					style={contentSpring}
					className={classes.mainSection}
				>
					<RemoteSocialLogo className={classes.brand} />
					{isError(pageLoadRequest) &&
						(pageLoadRequest.error.code === 'not-found' ? (
							<>
								<ErrorMessage
									error={
										'This invite has expired or is invalid'
									}
								/>
								<PageLinkButton to={'/'}>
									Return to Hub
								</PageLinkButton>
							</>
						) : (
							<ErrorMessage error={pageLoadRequest.error} />
						))}
					{isSuccess(pageLoadRequest) && (
						<HandleInviteContentSwitcher
							inviteId={inviteId}
							data={pageLoadRequest.data}
						/>
					)}
				</animated.div>
			</Grid>
		</Grid>
	);
}
