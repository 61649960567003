import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../hooks';

export default function Logout() {
	const auth = useAuth();
	const history = useHistory();

	useEffect(() => {
		auth.logout().then(
			() => history.push('/'),
			(error) => console.error('logout', error),
		);
	});

	return <React.Fragment></React.Fragment>;
}
