import * as Sentry from '@sentry/browser';
import { isDevBuild } from '../environment';

export function setUser(user: { uid: string } | null) {
	if (isDevBuild()) {
		return;
	}
	Sentry.setUser(
		user && {
			id: user.uid,
		},
	);
}
