import {
	AnyRequestState,
	ErrorPayload,
	InitialPayload,
	PendingPayload,
	SuccessPayload,
} from './requestState';

type PayloadOf<A> = A extends {
	status: 'success';
	data: infer P;
}
	? P
	: never;

type InitialPayloadOf<A> = A extends {
	status: 'initial';
	data: infer P;
}
	? P
	: never;

export function isInitial<A extends AnyRequestState>(
	state: A,
): state is A & InitialPayload<InitialPayloadOf<A>> {
	return state.status === 'initial';
}

export function isPending<A extends AnyRequestState>(
	state: A,
): state is A & PendingPayload<PayloadOf<A>, InitialPayloadOf<A>> {
	return state.status === 'pending';
}

export function isSuccess<A extends AnyRequestState>(
	state: A,
): state is A & SuccessPayload<PayloadOf<A>> {
	return state.status === 'success';
}

export function isError<A extends AnyRequestState>(
	state: A,
): state is A & ErrorPayload<PayloadOf<A>, InitialPayloadOf<A>> {
	return state.status === 'error';
}
