import type { AppNames, EnvName } from './types';

type SharedConfig = {
	// these props consumed by firebase.initApp(...) see docs
	apiKey: string;
	authDomain: string;
	databaseURL: string;
	projectId: string;
	storageBucket: string;
	messagingSenderId: string;
	// base url to access any function, function name appended after slash
	// e.g. `${functionsBaseUrl}/platform-cancelInvite`
	functionsBaseUrl: string;
};

const sharedConfigByEnv: Record<'staging' | 'prod', SharedConfig> = {
	staging: {
		apiKey: 'AIzaSyCCJlrLJGawmhzvv4OXEkr_B264h3eS7R4',
		authDomain: 'remotesocial-dev.firebaseapp.com',
		databaseURL: 'https://remotesocial-dev.firebaseio.com',
		projectId: 'remotesocial-dev',
		storageBucket: 'remotesocial-dev.appspot.com',
		messagingSenderId: '125966594508',
		functionsBaseUrl:
			'https://us-central1-remotesocial-dev.cloudfunctions.net',
	},
	prod: {
		apiKey: 'AIzaSyC3K7HT9-j90Fr93U72xUUk9FymkoqDLd8',
		authDomain: 'remotesocial-io.firebaseapp.com',
		databaseURL: 'https://remotesocial-io.firebaseio.com',
		projectId: 'remotesocial-io',
		storageBucket: 'remotesocial-io.appspot.com',
		messagingSenderId: '436970492230',
		functionsBaseUrl: 'https://backend.remotesocial.io/functions',
	},
};

type AppSpecificConfig = {
	// these props consumed by firebase.initApp(...)
	appId: string;
	measurementId?: string;
};

const stagingAppConfigByApp: Record<AppNames, AppSpecificConfig> = {
	hub: {
		appId: '1:125966594508:web:c332c6f57506943c2d2d69',
		measurementId: 'G-ZNG42L1QYE',
	},
	trivia: {
		appId: '1:125966594508:web:be3d47b5adb2b0672d2d69',
		measurementId: 'G-5667EX35FS',
	},
	bingo: {
		appId: '1:125966594508:web:12d38c0a94776d4d2d2d69',
		measurementId: 'G-BQYJQSEECX',
	},
	physical: {
		appId: '1:125966594508:web:48023cc276a38f6e2d2d69',
		measurementId: 'G-SHML3Z5LX5',
	},
	connect: {
		appId: '1:125966594508:web:423be4e326f10f332d2d69',
		measurementId: 'G-Q99JRJPRT3',
	},
	poker: {
		appId: '',
		measurementId: '',
	},
	checkin: {
		appId: '1:125966594508:web:48023cc276a38f6e2d2d69',
		measurementId: 'G-SHML3Z5LX5',
	},
};

const prodAppConfigByApp: Record<AppNames, AppSpecificConfig> = {
	hub: {
		appId: '1:436970492230:web:e2c69019dd339402475547',
		measurementId: 'G-LVSXWKYKWT',
	},
	trivia: {
		appId: '1:436970492230:web:c002c5d6468ea2f6475547',
		measurementId: 'G-FH1HZ7KHT6',
	},
	bingo: {
		appId: '1:436970492230:web:16b2e302c17c317b475547',
		measurementId: 'G-D3BXZH5CSL',
	},
	physical: {
		appId: '1:436970492230:web:4a0b220914832192475547',
		measurementId: 'G-NKR35XRWRK',
	},
	connect: {
		appId: '1:436970492230:web:a5b73d007071fcbc475547',
		measurementId: 'G-2Z7FTZ3V3S',
	},
	poker: {
		appId: '',
		measurementId: '',
	},
	checkin: {
		appId: '1:436970492230:web:4a0b220914832192475547',
		measurementId: 'G-NKR35XRWRK',
	},
};

const appConfigs = {
	staging: stagingAppConfigByApp,
	prod: prodAppConfigByApp,
};

export type FirebaseConfig = SharedConfig & AppSpecificConfig;

export function createFirebaseConfig(
	app: AppNames,
	env: EnvName,
): FirebaseConfig {
	// for local environment lookup settings using staging key
	const lookupEnv = env === 'local' ? 'staging' : env;

	const sharedConf = sharedConfigByEnv[lookupEnv];
	const appConf = appConfigs[lookupEnv][app];
	const defaultConfig = {
		...sharedConf,
		...appConf,
	};

	return defaultConfig;
}
