import { useState, useCallback, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useFirebase } from 'react-redux-firebase';
import { useAuth, useCurrentUser } from '../hooks';
import { useCurrentAccount } from '../contexts';
import { registerError } from '../errors';

export default function useCreateGame({
	gameType,
	onSuccess,
	createFunction = 'platform-createGame',
}) {
	const firebase = useFirebase();
	const history = useHistory();
	const [error, setError] = useState(null);
	const [pending, setPending] = useState(null);
	const [accountId, setAccountId] = useState(null);
	const runningRef = useRef(false);
	const location = useLocation();
	const { currentUser: authUser } = useAuth();
	const user = useCurrentUser();
	const { needsAccountCreated } = useCurrentAccount();

	const createGame = useCallback(
		(accountId = undefined) => {
			if (!runningRef.current) {
				setPending(true);
				setAccountId(accountId);
			}
		},
		[setAccountId],
	);

	useEffect(() => {
		if (!user.isLoaded || !pending || runningRef.current) {
			return;
		}
		runningRef.current = true;

		(async () => {
			const apiCall = firebase.functions().httpsCallable(createFunction);
			const isHost = user.email && user.emailVerified;

			// TODO: the redirects below should not happen directly here and instead
			// should be controlled by router hook somewhere close to router namespace
			if (
				typeof needsAccountCreated === 'boolean' &&
				needsAccountCreated
			) {
				history.push('/create-first-account');
				return;
			}

			if (!isHost) {
				if (user.email && !user.emailVerified) {
					history.push('/verify-email');
					return;
				}

				history.push({
					pathname:
						authUser && authUser.isAnonymous
							? '/register/upgrade'
							: '/register',
					state: { from: location },
				});

				return;
			}

			const param = {
				gameType,
				...(accountId && {
					accountId,
				}),
			};

			let data;

			try {
				({ data } = await apiCall(param));
			} catch (e) {
				registerError(e);
				if (e.details && e.details.code === 'account-ambiguous') {
					history.push(
						gameType
							? `/select-account/${gameType}`
							: `/select-account`,
					);
					return;
				}

				if (e.details && e.details.code === 'not-verified') {
					// the auth token can get out of date easily even though both auth and profile say email is verified.
					await authUser.getIdToken(Boolean('force-refresh'));
					({ data } = await apiCall(param));
				} else {
					setError(e);
				}
			}
			runningRef.current = false;
			setPending(false);
			if (onSuccess) {
				onSuccess(data);
			} else if (data && data.accountID) {
				history.push(
					data.url
						? `/create/${data.accountID}/${data.url}`
						: `/create/${data.accountID}`,
				);
			}
		})();
	}, [
		accountId,
		authUser,
		firebase,
		gameType,
		history,
		location,
		pending,
		user.email,
		user.emailVerified,
		user.isLoaded,
	]);

	return { error, pending, createGame };
}
